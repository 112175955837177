import { Component, OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { skServices } from 'src/app/services/sk.services.service';
@Component({
  selector: 'app-modal-crop-imagen',
  templateUrl: './modal-crop-imagen.component.html',
  styleUrls: ['./modal-crop-imagen.component.scss'],
})
export class ModalCropImagenComponent implements OnInit {
  imageChangedEvent: any = '';
  croppedImage: any = '';

  constructor(
    public modalController: ModalController,
    private loadindControl: LoadingController,
    private skservice: skServices,
  ) { }

  ngOnInit() {}

  cargar_img(img:any){
    try {
      
    } catch (error) {
      
    }
  }

 /*  fileChangeEvent(event: any): void {
      this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
      this.croppedImage = event.base64;
  }
  imageLoaded(image: LoadedImage) {
      
  }
  cropperReady() {
      
  }
  loadImageFailed() {
      
  }
  clickFile() {
    document.getElementById('fileImageCrop').click();
  } */
  async dismissModal() {
    await this.modalController.dismiss(this.croppedImage);
  }

}
