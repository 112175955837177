import { OfertaService } from './oferta.service';
import { MensajeOferta, Oferta } from './../../models';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Mensaje, Usuario, Productos } from 'src/models';
import { Router } from '@angular/router';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import firebase from 'firebase/compat/app';
import { AuthGuardServicesService } from './auth-guard-services.service';
import { UiKitController } from './uikitcontroller.service';

@Injectable({
	providedIn: 'root',
})
export class FirechatService {
	chats: Mensaje[] = [];
	usuario: any;
	date: number = new Date().getTime();
	ele = 0
	constructor(
		private afs: AngularFirestore,
		public afAuth: AngularFireAuth,
		private router: Router,
		public storage: AngularFireStorage,
		private autho: AuthGuardServicesService,
		private util: UiKitController,
	) {
		this.usuario = autho.Usuario;
	}

	consultarMensajesChats(idChat: string) {
		return this.afs
			.collection('Chats')
			.doc(idChat)
			.collection('Mensajes', (ref) => ref.orderBy('fecha', 'asc'))
			.valueChanges();
	}
	consultarChatsUsuario() {
		return this.afs
			.collection('Clientes')
			.doc(localStorage.getItem('userId'))
			.collection('userChats', (ref) => ref.orderBy('fecha', 'desc'))
			.valueChanges();
	}
	getChatUsuario(idChat: string) {
		return this.afs.collection('Clientes').doc(localStorage.getItem('userId')).collection('userChats').doc(idChat).valueChanges();
	}
	consultarChat(idCombinado: string) {
		const data = this.afs.collection('Chats').doc(idCombinado).get().toPromise();
		return data.then(res => res.data());
	}
 async sendMessage(
		userData_2: Usuario,
		idChat: string,
		mensaje: string,
		tipoMensaje: string,
		statusUser2: string
	) {
		try {
			this.ele++
			return new Promise<void>((resolve) => {
				console.log(userData_2);
				this.actualizarChat(idChat, mensaje, tipoMensaje);
				this.actualizarChatUsuarios(this.usuario, userData_2, idChat, mensaje, tipoMensaje, statusUser2);
				resolve();
			});
		} catch (error) {
			console.log(error);
		}
	}
	crearChat(userData_1: Usuario, userData_2: Usuario,userData_3: Usuario, idCombinado: string) {
		const mensajeId = this.afs.createId();
		this.afs
			.collection('Chats')
			.doc(idCombinado)
			.set({
				idChat: idCombinado,
				usuario_1: userData_1,
				usuario_2: userData_2,
				usuario_3: userData_3,
			});

		return /* this.afs
			.collection('Chats')
			.doc(idCombinado)
			.collection('Mensajes')
			.doc(mensajeId)
			.set({
				mensajeId,
				userId: this.usuario.userId,
				nombre: this.usuario.displayName,
				tipoMensaje: '',
				visto: false,
				fecha: firebase.firestore.FieldValue.serverTimestamp(),
			}); */
	}
	crearChatAdviser(userData_1: Usuario, userData_2: Usuario,idCombinado: string) {
		const mensajeId = this.afs.createId();
		this.afs
			.collection('Chats')
			.doc(idCombinado)
			.set({
				idChat: idCombinado,
				usuario_1: userData_1,
				usuario_2: userData_2,
			});

		return /* this.afs
			.collection('Chats')
			.doc(idCombinado)
			.collection('Mensajes')
			.doc(mensajeId)
			.set({
				mensajeId,
				userId: this.usuario.userId,
				nombre: this.usuario.displayName,
				tipoMensaje: '',
				visto: false,
				fecha: firebase.firestore.FieldValue.serverTimestamp(),
			}); */
	}
	actualizarChat(idChat: string, mensaje: string, tipoMensaje: string) {
		const mensajeId = this.afs.createId();
		return this.afs
			.collection('Chats')
			.doc(idChat)
			.collection('Mensajes')
			.doc(mensajeId)
			.set({
				mensajeId,
				mensaje,
				tipoMensaje,
				userId: this.usuario.userId,
				nombre: this.usuario.displayName,
				visto: false,
				fecha: firebase.firestore.FieldValue.serverTimestamp(),
			});
	}
	async actualizarChatUsuarios(
		userData_1: Usuario,
		userData_2: Usuario,
		idCombinado: string,
		mensaje: string,
		tipoMensaje: string,
		statusUser2: string
	) {
		try {
			this.afs
			.collection('Clientes')
			.doc(this.usuario.userId)
			.collection('userChats')
			.doc(idCombinado)
			.update({
				fecha: new Date().getTime(),
				ultimoMensaje: mensaje,
				visto: true
			});

		this.afs
			.collection('Clientes')
			.doc(userData_2.userId)
			.collection('userChats')
			.doc(idCombinado)
			.update({
				fecha: new Date().getTime(),
				ultimoMensaje: mensaje,
				visto: statusUser2 === 'online' ? true : false
			});
		} catch (error) {
			console.log(error)
		}
	}
	crearChatUsuarios(userData_1: any, userData_2: any, userData_3: any,idCombinado: any) {
		try {
			console.log(userData_1);
			console.log(userData_2);
			console.log(userData_3);
			console.log(idCombinado);
			const update1 = this.afs
				.collection('Clientes')
				.doc(this.usuario.userId)
				.collection('userChats')
				.doc(idCombinado)
				.set({
					idChat: idCombinado,
					infoUser: {
						userId: userData_3.id_cloud ,
						displayName: userData_2.des_usuario,
						photoURL: userData_2.cod_img,
					},
					fecha: new Date().getTime(),
					
					visto: true
				});
	
			const update2 = this.afs
				.collection('Clientes')
				.doc(userData_2.id_cloud)
				.collection('userChats')
				.doc(idCombinado)
				.set({
					idChat: idCombinado,
					infoUser: {
						userId: '',
						displayName: userData_2.des_usuario,
						photoURL: userData_2.cod_img,
					},
					fecha: new Date().getTime(),
					
					visto: false
				});

				const update3 = this.afs
				.collection('Clientes')
				.doc(userData_3.id_cloud)
				.collection('userChats')
				.doc(idCombinado)
				.set({
					idChat: idCombinado,
					infoUser: {
						userId: userData_1.userId,
						displayName: ' VIP: '+ userData_1.displayName+' || '+' Seller: '+ userData_2.des_usuario,
						photoURL: userData_1.cod_img,
					},
					fecha: new Date().getTime(),
					
					visto: false
				});
	
			const updatesCombine = Promise.all([update1, update3]);
			
			return updatesCombine;
		} catch (error) {
			console.log(error);
		}
		
	}
	crearChatUsuariosAdviser(userData_1: any, userData_2: any, idCombinado: any) {
		try {
			console.log(userData_1);
			console.log(userData_2);
			console.log(idCombinado);
			const update1 = this.afs
				.collection('Clientes')
				.doc(this.usuario.userId)
				.collection('userChats')
				.doc(idCombinado)
				.set({
					idChat: idCombinado,
					infoUser: {
						userId: userData_2.id_cloud ,
						displayName: userData_2.des_usuario,
						photoURL: userData_2.cod_img,
					},
					fecha: new Date().getTime(),
					
					visto: true
				});
	
			const update2 = this.afs
				.collection('Clientes')
				.doc(userData_2.id_cloud)
				.collection('userChats')
				.doc(idCombinado)
				.set({
					idChat: idCombinado,
					infoUser: {
						userId: userData_1.userId,
						displayName: userData_1.displayName,
						photoURL: userData_1.cod_img,
					},
					fecha: new Date().getTime(),
					
					visto: false
				});
	
			const updatesCombine = Promise.all([update1, update2]);
			
			return updatesCombine;
		} catch (error) {
			console.log(error);
		}
		
	}
	crearCha_usuario_oferta(userData_1: any, userData_2: any, idCombinado: any,mensaje: any) {
		console.log(userData_1);
		console.log(userData_2);
		console.log(idCombinado);
		console.log(mensaje);
		const update1 = this.afs
			.collection('Clientes')
			.doc(this.usuario.userId)
			.collection('userChats')
			.doc(idCombinado)
			.set({
				idChat: idCombinado,
				infoUser: {
					userId: userData_2.userId ,
					displayName: userData_2.displayName,
					photoURL: userData_2.photoURL,
				},
				fecha: new Date().getTime(),
				ultimoMensaje: mensaje,
				visto: true
			});

		const update2 = this.afs
			.collection('Clientes')
			.doc(userData_2.id_cloud)
			.collection('userChats')
			.doc(idCombinado)
			.set({
				idChat: idCombinado,
				infoUser: {
					userId: userData_1.userId,
					displayName: userData_1.displayName,
					photoURL: userData_1.photoURL,
				},
				fecha: new Date().getTime(),
				ultimoMensaje: mensaje,
				visto: false
			});

		const updatesCombine = Promise.all([update1, update2]);
		
		return updatesCombine;
	}
	async agregarMensajeDesdePerfil(para: any, para2:any) {
		try {
			const idCombinado = para.id_cloud+localStorage.getItem('userId');
			const validate = await this.consultarChat(idCombinado);
			if (!validate) {
				this.usuario.cod_img = localStorage.getItem('cod_img');
				await this.crearChat(this.usuario, para, para2, idCombinado);
				await this.crearChatUsuarios(this.usuario, para, para2, idCombinado);
				this.router.navigate(['chat', para.id_cloud, idCombinado]);
				return
			}else{
				this.router.navigate(['chat', para.id_cloud, idCombinado]);
			return;
			}
		} catch (error) {
			console.log(error);
		}
	}
	async agregarMensajeDesdePerfilAdviser(para: any) {
		try {
			console.log(para);
			const idCombinado = para.id_cloud+localStorage.getItem('userId');
			const validate = await this.consultarChat(idCombinado);
			if (!validate) {
				this.usuario.cod_img = localStorage.getItem('cod_img');
				await this.crearChatAdviser(this.usuario, para, idCombinado);
				await this.crearChatUsuariosAdviser(this.usuario, para, idCombinado);
				this.router.navigate(['chat', para.id_cloud, idCombinado]);
				return
			}else{
				this.router.navigate(['chat', para.id_cloud, idCombinado]);
			return;
			}
		} catch (error) {
			console.log(error);
		}
	}
	chatVisto(
		idChat: string,
	) {
		this.afs
			.collection('Clientes')
			.doc(this.usuario.userId)
			.collection('userChats')
			.doc(idChat)
			.update({
				visto: true
			});

	}
	async sendMessageOferta(userData_2: Usuario, mensaje: any, statusUser2: any, oferta: Oferta) {
		this.ele++
		console.log(this.ele, 'este');
		try {
				console.log(userData_2);
			const idChat = userData_2.userId+this.usuario.userId;
			const validate = await this.consultarChat(idChat);
			
			if (!validate) {
				/* await this.crearChat(this.usuario, userData_2, idChat);
				await this.crearCha_usuario_oferta(this.usuario, userData_2, idChat, 'Hi!'); */
				return this.util.ToastWarning('Please say hello first');
			}else{
				this.actualizarChatMessageOferta(idChat, mensaje, oferta);
				this.actualizarChatUsuarios(this.usuario, userData_2, idChat, mensaje, '', statusUser2);
				this.router.navigate(['chat', userData_2.userId, idChat]);
			}
			
		} catch (error) {
			console.log(error)
		}
	}
	async actualizarChatMessageOferta(idChat: string, mensaje: string, oferta: Oferta) {
		try {
			const mensajeId = this.afs.createId()

		const mensajeOferta: MensajeOferta = {
			mensajeId,
			mensaje,
			userId: this.usuario.userId,
			nombre: this.usuario.displayName,
			oferta,
			visto: false,
			tipoMensaje: 'oferta',
			fecha: firebase.firestore.FieldValue.serverTimestamp(),
		}

		 await this.afs
			.collection('Chats')
			.doc(idChat)
			.collection('Mensajes')
			.doc(mensajeId)
			.set(mensajeOferta);

		return
		} catch (error) {
			console.log(error)
		}
	}
}
