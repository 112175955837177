import { Component, Input, OnInit } from '@angular/core';
import { CarritoService } from 'src/app/services/carrito.service';

@Component({
  selector: 'app-productos-carrito',
  templateUrl: './productos-carrito.component.html',
  styleUrls: ['./productos-carrito.component.scss'],
})
export class ProductosCarritoComponent implements OnInit {
  @Input() productospedido: any;
  @Input() botones = true;

  precio: any;
  precioTotal: any;
  show: any = false;

  constructor(public carritoService: CarritoService) {}

  ngOnInit() {
    if (this.productospedido.Producto.show) {
      this.show = true;
    }

    this.precio = Number(this.productospedido.Producto.price).toFixed(2);
    this.precioTotal = (
      Number(this.productospedido.Cantidad) *
      Number(this.productospedido.Producto.price)
    ).toFixed(2);
  }

  async AgregarAlCarrito() {
    await this.carritoService.AgregarProducto(this.productospedido.Producto);
  }

  async RemoverProductoCarrito() {
    await this.carritoService.SacarProducto(this.productospedido.Producto);
  }
}
