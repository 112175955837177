import { UiKitController } from './../../../services/uikitcontroller.service';
import { Productos } from './../../../../models';
import { ModalController } from '@ionic/angular';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-ofertar-modal',
  templateUrl: './ofertar-modal.component.html',
  styleUrls: ['./ofertar-modal.component.scss'],
})
export class OfertarModalComponent implements OnInit {
	@Input() producto: any;
	forma:FormGroup;
	data = {
		amount: '',
		mensaje: ''
	}

  constructor(
		private modalController: ModalController,
		private fb:FormBuilder,
		private uiKit: UiKitController,
	) { 
		this.forma = this.fb.group({
			correo: ['', [Validators.required, Validators.email]],
			pass: ['', [Validators.required]]
		  });
	}

  ngOnInit() {
	console.log(this.producto);
  }

	async send(){
		if(!this.data.amount){
			await this.uiKit.ToastWarning('Amount must be more than 0')
			return
		}
		if(Number(this.data.amount.replace(/,/g,'')) < Number(this.producto.price)/2){
			await this.uiKit.ToastWarning('Amount must be more than half product price')
			return
		}
		this.data.amount = this.data.amount.replace(/,/g,'')
		await this.modalController.dismiss(this.data);
	}

	async dismissModal() {
    await this.modalController.dismiss();
  }



}
