import { Component, OnInit, Inject, Input, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { LoadingController, ModalController } from '@ionic/angular';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { skServices } from 'src/app/services/sk.services.service';
import { UiKitController } from 'src/app/services/uikitcontroller.service';
import { FormMsgComponent } from '../form-msg/form-msg.component';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { Auth, createUserWithEmailAndPassword, getAuth, signInWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { StatusController } from 'src/app/services/statuscontroller.service';
import { Router } from '@angular/router';
import firebase from 'firebase/compat/app';
import { Usuario } from 'src/models';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-form-registro-proveedor',
  templateUrl: './form-registro-proveedor.component.html',
  styleUrls: ['./form-registro-proveedor.component.scss'],
})
export class FormRegistroProveedorComponent implements OnInit {

  @ViewChild('passwordEyeRegister, { read: ElementRef }') passwordEye: ElementRef;
  @ViewChild('passwordEyeRegisterR, { read: ElementRef }') passwordEyeR: ElementRef;
  @Input() data: string;
  passwordTypeInput  =  'password';
  passwordTypeInputR  =  'password';
  emailRegex:any = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  telRegex:any = /\d{4}-\d{6}\d/;
  Regex:any = /^[J,V]{1}\d{9}/;
  hide:any = true;
  exist:any = false;
msg:any;
nombre:any;
forma:FormGroup;
forma52:FormGroup;
dialogSpin:any;
accion:any;
login_boton:any = false;
login_:any = false;
lg:any = localStorage.getItem('lg');
db = firebase.firestore();
unidad:any;
listas:any = [];
tipo_cliente = [];
deposito_pt = [];
almacen:any = [];
almacen_cdn:any = [];
almacen_dstrbdr:any = [];
almacen_pvp:any = [];
tipo_um_und = [];
tipo_um_und_consig = [];
asociar:any = 'N/A';
actualizar:any = false;
path = 'Clientes';
valoroff:any = false;
usuarioData: Usuario = JSON.parse(localStorage.getItem('userData')) || {
  userId: '',
  email: '',
  displayName: '',
  nombre: '',
  apellido: '',
  genero: '',
  fecha_nacimiento: '',
  edad: '',
  descripcion: '',
  photoURL: '',
  telefono: '',
  direccion: {
    pais: '',
    dir_str: '',
    location: {
      lat: '',
      long: '',
    },
  },
};
usuarioDataSub$ = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('userData')));
  constructor(
    public fb:FormBuilder,
    private skservice: skServices,
    private loadindControl: LoadingController,
    private util: UiKitController,
    public modalController: ModalController,
    public auth: AngularFireAuth,
    private statusControl: StatusController,
    private router: Router,
    ) {
      
      this.forma = this.fb.group({
        nom_usuario: ['', [Validators.required]],
        correo: ['', [Validators.required, Validators.email]],
        pass: ['', [Validators.required]],
        tipo: [''],
        categoria: [''],
        cpass: ['',[Validators.required,RxwebValidators.compare({ fieldName: 'pass' }),
          ],
        ],
      });
      this.forma52 = this.fb.group({
        correo: ['', [Validators.required, Validators.email]],
        pass: ['', [Validators.required]],
        tipo: this.data,
      });
    }

  ngOnInit(): void {
    console.log(this.data);
  }

  async  togglePasswordMode() {
    //cambiar tipo input
  this.passwordTypeInput = this.passwordTypeInput === 'text' ? 'password' : 'text';
   //obtener el input
   const nativeEl = this.passwordEye.nativeElement.querySelector('input');
   //obtener el indice de la posición del texto actual en el input
   const inputSelection = nativeEl.selectionStart;
   //ejecuto el focus al input
   nativeEl.focus();
  //espero un milisegundo y actualizo la posición del indice del texto
   setTimeout(() => {
       nativeEl.setSelectionRange(inputSelection, inputSelection);
   }, 1);

}

async  togglePasswordModeR() {
  //cambiar tipo input
this.passwordTypeInputR = this.passwordTypeInputR === 'text' ? 'password' : 'text';
 //obtener el input
 const nativeEl = this.passwordEyeR.nativeElement.querySelector('input');
 //obtener el indice de la posición del texto actual en el input
 const inputSelection = nativeEl.selectionStart;
 //ejecuto el focus al input
 nativeEl.focus();
//espero un milisegundo y actualizo la posición del indice del texto
 setTimeout(() => {
     nativeEl.setSelectionRange(inputSelection, inputSelection);
 }, 1);

}

 async modalrespuestaFalso(){
    try {
      await this.util.cargando();
      this.login_boton = 'login';
      await this.util.closeLoading();
    } catch (error) {
      await this.util.closeLoading();
      console.log(error);
    }
  }

  validatepass(){
    const pass = this.forma.value.pass;

    // Expresiones regulares para validar la contraseña
    const longitudMinima = /[\s\S]{8,}/;
    const alMenosUnaMayuscula = /[A-Z]/;
    const alMenosUnaMinuscula = /[a-z]/;
    const alMenosUnNumero = /\d/;

    // Verificar cada criterio usando las expresiones regulares
    return (
        longitudMinima.test(pass) &&
        alMenosUnaMayuscula.test(pass) &&
        alMenosUnaMinuscula.test(pass) &&
        alMenosUnNumero.test(pass)
    );

  }

  async modalrespuesta(){
    console.log ('Entra a Modal Respuesta de registro proveedor');
    try {

      if (!this.validatepass()){
        this.exist = true;
        this.msg = "The password must have a minimum of 8 characters, one uppercase letter, one lowercase letter and one number.";
        return;
      }

      console.log ('paso 1');
      
      const auth: Auth = getAuth();
      console.log ('paso 2');
      this.forma.value.tipo = 'DLR';
      this.forma.value.categoria = 'DLR';
      console.log ('paso 3');
    await this.util.cargando();
    const response = await this.skservice.crearusuariohttp(this.forma.value).toPromise();
    console.log ('response register', response)
    if (response.status){
      console.log ('respuesta',response.status);
      await this.util.closeLoading();
      //alert(response.message);
      this.exist = true;
      this.msg = response.message;
      return;
    }


    if (response.correo) {
         /*  const data_cloud =  await createUserWithEmailAndPassword(auth,response.correo[0].correo, response.correo[0].pass_usuario);
          
          let data_clo = {
            id_cloud:data_cloud.user.uid,
            correo:response.correo[0].correo
          } */
          //console.log(data_clo);
          //await this.skservice.getUpdateIdcloudhttp(data_clo).toPromise();
          
          //this.forma.value.id_cloud = data_cloud.user.uid;
           //await updateProfile(auth.currentUser, { displayName: this.forma.value.nom_usuario });
           //await (await this.auth.currentUser).sendEmailVerification();
           this.statusControl.signOut()
            localStorage.clear();
            await this.util.closeLoading();
              this.router.navigate(['']).then(() => {
                /* location.reload() */
                this.login_boton = 'login_falso';
              })
           
        }else{
          await this.util.closeLoading();
          alert("Your internet connection is failing");
        }
    this.forma.reset();
    } catch (error) {
      await this.util.closeLoading();
      console.log(error);
    }
  }

  async loginsk() {
    try {
      const auth: Auth = getAuth();
    await this.util.cargando();
    
      await this.util.cargando();
      const response = await this.skservice.login_memberhttp(this.forma52.value).toPromise();
      if (response.usuario[0].tipo.toLowerCase() === 'dlr') {
        const userCredential = await signInWithEmailAndPassword(auth,this.forma52.value.correo, response.usuario[0].pass_usuario);   
      const user = userCredential.user as any;
      console.log(userCredential);
      if (response.deposito.length > 0) {
        localStorage.setItem('cod_deposito', response.deposito[0].cod_deposito);
      }
      if (response.img.length > 0) {
        localStorage.setItem('cod_img', response.img[0].cod_img);
      }
              localStorage.setItem('cod_usuario', response.usuario[0].cod_usuario);
              localStorage.setItem('cod_deposito', response.usuario[0].cod_usuario);
              localStorage.setItem('des_usuario', response.usuario[0].nom_usuario);
              localStorage.setItem('cod_definicion', response.usuario[0].cod_definicion);
              localStorage.setItem('cod_concepto', response.usuario[0].cod_concepto);
              localStorage.setItem('cod_sesion', response.sesion[0].cod_sesion);
              localStorage.setItem('dir_ip_client', response.sesion[0].dir_ip_client);
              localStorage.setItem('usuario_tipo', response.usuario[0].tipo);
              localStorage.setItem('tipoUsuario', response.usuario[0].tipo);
              localStorage.setItem('tipo', response.usuario[0].tipo);
              localStorage.setItem('usuario_categoria', response.usuario[0].categoria);
              localStorage.setItem('userId', response.usuario[0].id_cloud);
              localStorage.setItem('token', user.accessToken);
              localStorage.setItem('user', user.email);

      const docSnap = (await this.db.collection(this.path).doc(user.uid).get()).data();

      const objx: Usuario = {
        tipo: 'Email',
        tipoUsuario: localStorage.getItem('usuario_tipo'),
        userId: user.uid,
        email: user.email,
        displayName: user.displayName,
        nombre: '',
        apellido: '',
        genero: '',
        fecha_nacimiento: '',
        edad: '',
        descripcion: '',
        photoURL: user.photoURL,
        telefono: '',
        direccion: {
          pais: '',
          dir_str: '',
          location: {
            lat: '',
            long: '',
          },
        },
        productos: []
      };
      this.statusControl.setPresence('online')
      if (!docSnap) {
        await this.db.collection(this.path).doc(user.uid).set(objx);
        await this.db.collection('userChats').doc(user.uid).set({});
        await this.db.collection('Notificaciones').doc(user.uid).set({ notificacionesCantSinVer: 0 });
        localStorage.setItem('userData', JSON.stringify(objx));
        this.valoroff = true;
        
        await this.util.closeLoading();
        location.reload()

        return;
      }
      if (!docSnap.tipo) {
        await this.db.collection(this.path).doc(user.uid).update({
          tipo: 'Email'
        });
      }
      if (!docSnap.tipoUsuario) {
        await this.db.collection(this.path).doc(user.uid).update({
          tipoUsuario: response.usuario[0].tipo ?? localStorage.getItem('usuario_tipo')
        });
      }
      if (!docSnap.photoURL) {
        await this.db.collection(this.path).doc(user.uid).update({
          photoURL: user.photoURL ?? localStorage.getItem('cod_img')
        });
      }
      this.usuarioData = docSnap as Usuario;
      this.usuarioDataSub$.next(docSnap)
      localStorage.setItem('userData', JSON.stringify(docSnap));
      this.modalController.dismiss();
        await this.util.ToastSuccess(response.message);
        this.valoroff = true;
      
      await this.util.closeLoading();
        location.reload();
          return;
      }
     
    } catch (error) {
      await this.util.closeLoading();
      console.log(error);
      await this.util.ToastError(error.error.message);
    }
  }
  async dismissModal() {
    await this.util.loading();
    this.modalController.dismiss();
    await this.util.closeLoading();
  }
  
}
