import { Injectable } from '@angular/core';
import { reduce } from 'lodash';
import * as pdf from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import * as moment from 'moment';
import { skServices } from './sk.services.service';
(<any>pdf).vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: 'root'
})
export class PdfService {
  private path_0:any = "../../assets/img1/sk_logo.jpg";
  private path_2:any = "../../assets/img1/bar.png";
  constructor(
    private skservice: skServices,
  ) { }
  public async pdfVentas(response:any){
    try {
      console.log(response);
    const response_ = await this.skservice.getProductoPedidohttp(response).toPromise();
    console.log(response_);
     var tablaEnLista = this.generarTablaVentas(response_);
    
                     var doc_ ={
                         pageSize: 'LETTER',
                         /* background: [
                             { image:  await this.getBase64ImageFromURL("../../../assets/img/marca_3.png") , width: 811, margin:[0,0,0,0] ,absolutePosition: {x: 0, y: 0},},
                           ], */
                           info:{
                             title:'',
                             author: '',
                             subject:'DOCUMENTO',
                           },
                         content  :[
                             {   style: 'header',
                                 table:{
                                     headerRows: 1,
                                     widths: [80,400,80],
                                     fontSize: 8,
                                     body:[
                                         [  
                                          {image: await this.getBase64ImageFromURL(this.path_0), width: 80, height:85,alignment:'center'},
                                             {  style:'cod_control',
                                                 table: {
                                                margin: [1,5,1,0],
                                                body: [
                                                    ['Invoice: '+ response.cod_pedido_carrito],
                                                    [{text:'',fontSize:15}]
                                                ]
                                            },fontSize: 8,
                                            layout: {
                                                defaultBorder: false,
                                            }
                                        },
                                         ]
                                     ],  
                                 },layout: {
                                     defaultBorder: false,
                                 },
                             },
                             { 
                                style: 'linea',
                                table: {
                                    headerRows: 1,
                                    widths: ['*'],
                                    fontSize: 8,
                                    body: [
                                        [
                                          {image: await this.getBase64ImageFromURL(this.path_2),width: 570 , height:42 ,alignment:'center',},
                                        ]      
                                    ],
                                },layout: {
                                    defaultBorder: false,
                                },
                            },
                             { 
                                 style: 'codigo',
                                 table: {
                                     headerRows: 1,
                                     widths: ['*'],
                                     fontSize: 8,
                                     body: [
                                         [    
                                             {   
                                                 text: '',
                                                 fontSize: 9,
                                                 with: '*',
                                                 color:'#000',
                                                 bold: true,
                                                 alignment:'right',
                                                 border: [false, false, false, false],
                                             },
                                         ]
                                     ],
                                 }, 
                             },
                             { 
                                 style: 'fila_1',
                                 table: {
                                     headerRows: 1,
                                     widths: ['*'],
                                     fontSize: 7,
                                     body: [
                                         [    
                                             {   
                                                 text: 'Client: '+response.nom_usuario,
                                                 fontSize: 7,
                                                 with: '*',
                                                 color:'#000',
                                                 alignment:'left',
                                                 border: [false, false, false, false],
                                             }
                                         ],
                                         [    
                                          {   
                                            text: 'E-mail: '+response.correo,
                                            fontSize: 7,
                                            with: '*',
                                            color:'#000',
                                            alignment:'left',
                                            border: [false, false, false, false],
                                        }
                                         ]
                                     ],
                                 }, 
                             },
                             { 
                                 style: 'fila_2',
                                 table: {
                                     headerRows: 1,
                                     widths: ['*'],
                                     fontSize: 8,
                                     body: [
                                         [    
                                             {   
                                                 text: 'Date: ' +response.fecha_inicio+' '+response.hora_inicio,
                                                 fontSize: 7,
                                                 with: '*',
                                                 color:'#000',
                                                 alignment:'left',
                                                 border: [false, false, false, false],
                                             }
                                         ]
                                     ],
                                 }, 
                             },
                             { 
                                 style: 'data',
                                 table: {
                                     headerRows: 1,
                                     widths: ['*', '*', '*', '*', '*'],
                                     body: tablaEnLista,
                                     fontSize: 8,
                                 }, 
                             },
                             { 
                                 style: 'footer',
                                 table: {
                                     headerRows: 1,
                                     widths: ['*',80,60],
                                     fontSize: 8,
                                     body: [
                                         [    
                                             {   
                                                 text: 'Notas : ',
                                                 fontSize: 8,
                                                 margin:0,
                                                 padding:0,
                                                 with: '*',
                                                 color:'#000',
                                                 alignment:'left',
                                                 border: [true, true, false, false],
                                             },
                                             {   
                                                 text: 'Subtotal: ',
                                                 fontSize: 8,
                                                 with: '*',
                                                 color:'#000',
                                                 alignment:'right',
                                                 border: [false, true, false, false],
                                             },
                                           
                                             {   
                                                 text: ''+' $'+response.total_precio_pedido,
                                                 fontSize: 8,
                                                 with: '*',
                                                 bold:true,
                                                 color:'#000',
                                                 alignment:'right',
                                                 border: [false, true, true, false],
                                             },
                                         ],
                                         [    
                                             {   
                                                 text: '',
                                                 fontSize: 8,
                                                 with: '*',
                                                 color:'#000',
                                                 alignment:'left',
                                                 border: [true, false, false, false],
                                             },
                                             {   
                                                 text: 'Discount',
                                                 fontSize: 8,
                                                 with: '*',
                                                 color:'#000',
                                                 alignment:'right',
                                                 border: [false, false, false, false],
                                             },
                                             {   
                                                 text:''+' $0',
                                                 fontSize: 8,
                                                 with: '*',
                                                 bold:true,
                                                 color:'#000',
                                                 alignment:'right',
                                                 border: [false, false, true, false],
                                             },
                                         ],
                                         [    
                                             {   
                                                 text: '',
                                                 fontSize: 8,
                                                 with: '*',
                                                 color:'#000',
                                                 alignment:'left',
                                                 border: [true, false, false, false],
                                             },
                                             {   
                                                 text: 'Base amount: ',
                                                 fontSize: 8,
                                                 with: '*',
                                                 color:'#000',
                                                 alignment:'right',
                                                 border: [false, false, false, false],
                                             },
                                             {   
                                                 text: ''+' $'+response.total_precio_pedido,
                                                 fontSize: 8,
                                                 with: '*',
                                                 color:'#000',
                                                 bold:true,
                                                 alignment:'right',
                                                 border: [false, false, true, false],
                                             },
                                         ],
                                         [    
                                             {   
                                                 text: '',
                                                 fontSize: 8,
                                                 with: '*',
                                                 color:'#000',
                                                 alignment:'left',
                                                 border: [true, false, false, true],
                                             },
                                             {   
                                                 text: 'Total: ',
                                                 fontSize: 8,
                                                 with: '*',
                                                 color:'#000',
                                                 alignment:'right',
                                                 border: [false, false, false, true],
                                             },
                                             {   
                                                 text: ''+' $'+response.total_precio_pedido,
                                                 fontSize: 9,
                                                 with: '*',
                                                 color:'blue',
                                                 bold:true,
                                                 alignment:'right',
                                                 border: [false, true, true, true],
                                             },
                                         ]
                                     ]
                                 }
                             },
                             {   style: 'x_footer',
                                 table:{
                                     headerRows: 1,
                                     widths: ['*'],
                                     padding: 0,
                                     fontSize: 8,
                                     body:[
                                         [
                                           
                                        
                                         ],
                                     ],  
                                 },layout: {
                                     defaultBorder: false,
                                 },
                             },
                         ],
                         styles: {
                            cod_control: {
                                margin: [ 0, 10, 0, 5 ]
                            },
                             header: {
                                 margin: [ -20, -20, -20, 5 ]
                             },
                             linea: {
                                margin: [ -20, -22, -20, 0 ],
                                padding:[0],
                                lineHeight:[0.5],
                            },
                             codigo: {
                                 margin: [ -20, -21, -20, 0 ],
                                 padding:[0],
                                 lineHeight:[0.5],
                             },
                             fila_1: {
                                 margin: [ -25, 0, -25, 0],
                                 padding:[0,0,0,1],
                                 lineHeight:[0.6],
                             },
                             fila_2: {
                                 margin: [ -25, 0, -25, 2],
                                 padding:[0],
                                 lineHeight:[0.5],
                             },
                             data: {
                                 margin: [ -20, 0, -20, 0],
                                 lineHeight:[0.5],
                             },
                             footer: {
                                 margin: [ -20, 5, -20, 1 ],
                                 padding:[0],
                                 lineHeight:[0.777],
                             },
                             x_footer: {
                                margin: [ -20, 5, -20, 1 ],
                                padding:[0],
                                lineHeight:[0.777],
                            },
                             
                         },
                     }
                     pdf.createPdf(doc_).print();
                 
                 
     
    } catch (error) {
        console.log(error);
    }
}
private  generarTablaVentas(data:any){
  for (let i = 0; i < 10; i++) {

    if (data.resul[i] === undefined ) {
        let xobjeto = [{text: ' ', alignment: 'center', fontSize: 7,border: [false, false, false, false], }, 
        {text: ' ', alignment: 'center', fontSize: 7,border: [false, false, false, false],}, 
        {text: ' ', alignment: 'center', fontSize: 7,border: [false, false, false, false],},
        {text: ' ',alignment: 'center', fontSize: 7,border: [false, false, false, false], }, 
        {text: ' ', alignment: 'center', fontSize: 7,border: [false, false, false, false],}
       ]
        data.resul[i] =  xobjeto; 
    }   
}

  let arry =[
      [
          {text: 'Code', alignment: 'center',margin:[0,0,0,2], fontSize: 8, border: [true, true, false, true] },
          {text: 'Product', alignment: 'center',margin:[0,0,0,2], fontSize: 8, border: [false, true, false, true] }, 
          {text: 'Quantity', alignment: 'right',margin:[0,0,0,2], fontSize: 8, border: [false, true, false, true]}, 
          {text: 'Price', alignment: 'right',margin:[0,0,0,2], fontSize: 8,border: [false, true, false, true]},
          {text: 'Sub-Total', alignment: 'center', fontSize: 8,border: [false, true, true, true]},
      ],
  ]

 for (const i in data.resul) {
      let articulo = 
                       [
                           {text: data.resul[i].cod_producto, alignment: 'center', fontSize: 9,border: [false, false, false, false], }, 
                           {text: data.resul[i].des_producto, alignment: 'center', fontSize: 9,border: [false, false, false, false],}, 
                           {text: data.resul[i].cant_producto, alignment: 'center', fontSize: 9,border: [false, false, false, false],},
                           {text: data.resul[i].precio_producto, alignment: 'center', fontSize: 9,border: [false, false, false, false], }, 
                           {text: data.resul[i].total_precio_producto, alignment: 'center', fontSize: 9,border: [false, false, false, false],}, 
                           
                           
                       ]
  arry.push(articulo);
  
 }
 console.log(data);
  return arry;
}
private calculos(data:any){

  
        
  }
private getBase64ImageFromURL(url) {
    
  return new Promise((resolve, reject) => {
    var img = new Image();
    img.setAttribute("crossOrigin", "anonymous");

    img.onload = () => {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);

      var dataURL = canvas.toDataURL("image/png");

      resolve(dataURL);
    };

    img.onerror = error => {
      reject(error);
    };

    img.src = url;
  });
}
}
