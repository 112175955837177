import { LoadingController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import iziToast from 'izitoast';

@Injectable({
	providedIn: 'root',
})
export class UiKitController {
	private result: any;
	lg:any = localStorage.getItem('lg');
	carga:any;
	constructor(
		private router: Router,
		private loadindControl: LoadingController
	) { 
		if (this.lg) {
			if (this.lg.toUpperCase() === 'EN') {
				this.carga = 'Loading...';
			}
			if (this.lg.toUpperCase() === 'AL') {
				this.carga = 'Wird geladen...';
			}
		}else{
			this.carga = 'Loading...';
		}
	}
	carga_load(){
		var lang = localStorage.getItem('lg');
		if (lang) {
			if (lang.toUpperCase() === 'EN') {
				this.carga = 'Loading...';
			}
			if (lang.toUpperCase() === 'AL') {
				this.carga = 'Wird geladen...';
			}
		}else{
			this.carga = 'Loading...';
		}
	}

	ShowSplash() {
		const splash = document.querySelector('#Splash');
		const header = document.querySelector('ion-header');

		splash.classList.remove('animate__fadeOut');
		splash.classList.add('animate__fadeIn');
		splash.classList.remove('d-none');
		header.classList.remove('d-md-block');

	}

	HideSplash() {
		const splash = document.querySelector('#Splash');
		const header = document.querySelector('ion-header');

		splash.classList.add('animate__fadeOut');
		setTimeout(() => {
			splash.classList.add('d-none');
			header.classList.add('d-md-block');
		}, 1000);
	}

	async openLoader() {
		await (await this.loadindControl.create({
			message: 'Wait a minute...',
		})).present()
	}
	async closeLoader() {
		await this.loadindControl.dismiss();
	}

	ToastSuccess(message: string): Promise<void>{
		return new Promise(resolve => {
			iziToast.success({theme:'dark',color:'rgb(142, 99, 221)',image:'../../assets/img/socks_.webp',title:'Good!!',message,position:'topRight'})
			resolve()
		})
	}
	ToastUnverified(message: string): Promise<void>{
		return new Promise(resolve => {
			iziToast.success({theme:'dark',color:'rgb(142, 99, 221)',image:'../../assets/img/socks_.webp',title:'Hi!',message,position:'topRight'})
			resolve()
		})
	}
	ToastError(message: string): Promise<void>{
		return new Promise(resolve => {
			iziToast.error({icon:'fas fa-times',title:'Error',message,position:'topRight'})
			resolve()
		})
	}

	ToastWarning(message: string): Promise<void>{
		return new Promise(resolve => {
			iziToast.warning({theme:'dark',color:'rgb(116, 18, 83)',image:'../../assets/img/costumes.jpg',title:'Oops!!',message,position:'topRight'})
			resolve()
		})
	}
	setTimeOut(){
		return new Promise(resolve => { setTimeout(() => {
			resolve(null);
		}, 1000);});
	  }
	 async loading(){
		await this.carga_load();
		return new Promise(async resolve => { 
			const loading = await this.loadindControl.create({
				message: this.carga,
				duration: 500,
				spinner: 'circles',
				cssClass: 'spinner',
			  });
			  loading.present();
			  await this.setTimeOut();
			resolve(null);
		});
	  }
	 async cargando(){
		await this.carga_load();
			const loading = await this.loadindControl.create({
				message: this.carga,
				duration: 10000,
				spinner: 'circles',
				cssClass: 'spinner',
			  });
			  loading.present();
	  }
	 async closeLoading(){
		await this.loadindControl.dismiss();
	  }
}
