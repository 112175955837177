import { UiKitController } from './../../../services/uikitcontroller.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { AuthGuardServicesService } from 'src/app/services/auth-guard-services.service';

@Component({
  selector: 'app-username-modal',
  templateUrl: './username-modal.component.html',
  styleUrls: ['./username-modal.component.scss'],
})
export class UsernameModalComponent implements OnInit {
  form: FormGroup;

  constructor(
    public userControl: AuthGuardServicesService,
    public modalController: ModalController,
    private fb: FormBuilder,
    private uiKit: UiKitController
  ) {
    this.form = this.fb.group({
      displayName: ['', [Validators.required]],
    });
  }

  ngOnInit() {}

  async guardarData() {

    try {

			await this.uiKit.openLoader();

      await this.userControl.actualizarUsuarioAnonimo(this.form.value);

      await this.uiKit.closeLoader();

      await this.dismissModal()

			await this.uiKit.ToastSuccess('Thanks')

    } catch (error) {

      await this.uiKit.closeLoader();

      console.log(error);

			await this.uiKit.ToastError('Ups.. Error')
    }
  }

  async dismissModal() {
    this.modalController.dismiss();
  }

}
