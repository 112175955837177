import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Usuario } from 'src/models';

@Injectable({
  providedIn: 'root',
})
export class UsuariosService {
  private Usuarios: Usuario[] = [];

  constructor(
    private afs: AngularFirestore
  ) {}

  getUsuarios() {
    return this.Usuarios;
  }
  getUsuariosFirebase() {
    return new Promise<Usuario[]>(resolve => {
      resolve (this.afs
     .collection('Clientes', ref => ref.where('tipoUsuario','==','proveedor'))
     .get()
     .toPromise()
     .then((res) => res.docs.map(ele => ele.data() as Usuario) as Usuario[]))
     })
  }

  insertUsuario(element: any) {
    this.Usuarios.push(element);

    return this.Usuarios;
  }

  deleteUsuario(id: any) {
    this.Usuarios.splice(id, 1);

    return this.Usuarios;
  }

  updateUsuario(id: any, element: any) {
    this.Usuarios.forEach((usuario) => {
      if (usuario.userId === id) {
        usuario = element;
      }
    });

    return this.Usuarios;
  }
  buscarUsuarioByID(id: any){
    return new Promise<Usuario>(resolve => {
     resolve (this.afs
    .collection('Clientes')
    .doc(id)
    .get()
    .toPromise()
    .then((res) => res.data() as Usuario))
    })

  }
  buscarUsuarioByID2(id: any){
    return this.Usuarios.find(ele => ele.userId === id)

  }
}
