import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProductoComponent } from '../componentes/producto/producto.component';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { ProductosCarritoComponent } from '../componentes/productos-carrito/productos-carrito.component';
import { NavbarComponent } from '../componentes/navbar/navbar.component';
import { ModalFormUserComponent } from '../componentes/modal-form-user/modal-form-user.component';
import { ModalProductosComponent } from '../componentes/modal-productos/modal-productos.component';
import { MarketCardComponent } from '../componentes/market-card/market-card.component';
import { NotificacionCardComponent } from '../componentes/notificaciones/notificacion-card/notificacion-card.component';
import { CarritoComponent } from '../componentes/carrito/carrito.component';
import { UsernameModalComponent } from '../componentes/modalslayout/username-modal/username-modal.component';
import { ModalCropImagenComponent } from '../componentes/modal-crop-imagen/modal-crop-imagen.component';
import { ImageCropperModule } from 'ngx-image-cropper';

@NgModule({
  declarations: [
    ProductoComponent,
    ProductosCarritoComponent,
    NavbarComponent,
    ModalFormUserComponent,
    MarketCardComponent,
    NotificacionCardComponent,
    CarritoComponent,
    UsernameModalComponent,
    ModalCropImagenComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    ImageCropperModule
  ],
  exports: [
    ProductoComponent,
    ProductosCarritoComponent,
    NavbarComponent,
    ModalFormUserComponent,
    MarketCardComponent,
    NotificacionCardComponent,
    CarritoComponent,
    UsernameModalComponent,
    ModalCropImagenComponent,
  ],
})
export class SharedModule {}
