import { Component, OnInit, Input } from '@angular/core';
import { Usuario } from 'src/models';

@Component({
  selector: 'app-market-card',
  templateUrl: './market-card.component.html',
  styleUrls: ['./market-card.component.scss'],
})
export class MarketCardComponent implements OnInit {

  @Input()Data: Usuario

  constructor() { }

  ngOnInit() {
    console.log(this.Data)
  }

}
