import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertController, LoadingController, MenuController, ModalController } from '@ionic/angular';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { AuthGuardServicesService } from 'src/app/services/auth-guard-services.service';
import { CarritoService } from 'src/app/services/carrito.service';
import { skServices } from 'src/app/services/sk.services.service';
import { StoreService } from 'src/app/services/store.service';
import { UiKitController } from 'src/app/services/uikitcontroller.service';
import { environment } from 'src/environments/environment';
import iziToast from 'izitoast';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { StatusController } from 'src/app/services/statuscontroller.service';

@Component({
  selector: 'app-form-nivel',
  templateUrl: './form-nivel.component.html',
  styleUrls: ['./form-nivel.component.scss'],
})
export class FormNivelComponent implements OnInit {

  @Input() data: any;
  data_usuario:any;
  categoria:any;
  definiciones:any = [];
  precio_categoria:any = '0';
  usuario_email:any;
  cod_img:any;
  card:any;
  stripe: Stripe;
  cod_tdc: any;
	cod_fecha: any;
	cod_cvc: any;
  select:any;
  card_idioma:any;
  lg:any = localStorage.getItem('lg');
  forma: any = this.fb.group({
		numero: [''],
		categoria: ['',[Validators.required]],
		cardCvc: [false, [Validators.required, Validators.requiredTrue]],
		cardExp: [false, [Validators.required, Validators.requiredTrue]],
		cardNumber: [false, [Validators.required, Validators.requiredTrue]],
	});

  constructor(
    public modalController: ModalController,
    private loadindControl: LoadingController,
    private fb: FormBuilder,
    private util: UiKitController,
    public userControl: AuthGuardServicesService,
    private store: StoreService,
    private skservice: skServices,
    public menucontroler: MenuController,
		public firestoreService: StoreService,
		public carritoService: CarritoService,
		public firebaseauthService: AuthGuardServicesService,
		public loadingController: LoadingController,
		public router: Router,
    private statusControl: StatusController,
    private alertController: AlertController,
  ) { 
    this.data_usuario = localStorage.getItem('des_usuario');
    this.categoria = localStorage.getItem('usuario_categoria');
    this.usuario_email = localStorage.getItem('user');
    this.cod_img = localStorage.getItem('cod_img');
  }
  
  async ngOnInit() {
  /* await  this.tdcActiva(); */
  this.card = true;
  
  this.cargarDefi(this.categoria);
  console.log(this.data);
  }

  ionViewDidEnter(){
    this.cargarElementStripe();
    var lang = localStorage.getItem('lg');
    if (lang) {
      if (lang.toUpperCase() === 'EN') {
          this.select = 'Select category';
          this.card_idioma = 'Card';
      }
      if (lang.toUpperCase() === 'AL') {
        this.card_idioma = 'Karte';
        this.select = 'Kategorie wählen';
    }
  }
  }

  async tdcActiva(){
    try {
     await this.util.loading();
      var xvalor = {
        cod_usuario:this.data_usuario
      }
      const response = await this.skservice.getTdcUsuarioActivahttp(xvalor).toPromise();
      if (parseInt(response.tdc[0].resul) > 0 ) {
        this.card = false;
      }else{
        this.card = true;
      }
      
      
    } catch (error) {
      console.log(error);
      await this.util.ToastWarning(error.error.message);
    }
  }
  async cargarElementStripe(){
    try {
      const card1 = document.querySelector('#card-2') as HTMLDivElement;
      console.log(card1);
		if (card1) {
			this.stripe = await loadStripe(environment.stripeKey);
			const elements = this.stripe.elements();

			const style = {
				base: {
					iconColor: '#0ccde4',
					fontSize: '20px',
					color:'#212529',
				},
				invalid: {
					color: '#dc3545',
				},
			};

			const cardNumber = elements.create('cardNumber', {
				style,
				placeholder: 'Card',
				classes: {
					base: 'input-excep',
				},
			});
			const cardExpiry = elements.create('cardExpiry', {
				style,
				placeholder: 'Exp',
				classes: {
					base: 'input-excep',
				},
			});
			const cardCvc = elements.create('cardCvc', {
				style,
				placeholder: 'CVC',
				classes: {
					base: 'input-excep',
				},
			});

			cardNumber.mount('#card-2');
			cardExpiry.mount('#exp-1');
			cardCvc.mount('#cvc-1');

			this.cod_tdc = cardNumber;
			this.cod_fecha = cardExpiry;
			this.cod_cvc = cardCvc;

			this.cod_tdc.on('change', this.onChangeCard.bind(this));
			this.cod_fecha.addEventListener('change', this.onChangeCvc.bind(this));
			this.cod_cvc.on('change', this.onChangeExp.bind(this));
		}
    } catch (error) {
      console.log(error);
      await this.util.ToastWarning(error.error.message);
    }
  }

  async enviarStripe() {
		try {
      await this.util.cargando();
      const { token } = await this.stripe.createToken(this.cod_tdc);
      if (token) {
        let xvalor = {
          amount: parseFloat(this.forma.value.categoria.val_definicion),
          currency:'usd',
          token:token.id
        }
        this.skservice.getPagosPedidohttp(xvalor).subscribe(
          response=>{
            console.log(response.pago.status,'stripe_payment');
            var xpedido = {
              cod_usuario:localStorage.getItem('cod_usuario'),
              cod_definicion: localStorage.getItem('cod_definicion'),
              categoria_usuario_actual:localStorage.getItem('usuario_categoria'),
              cod_concepto_actual:localStorage.getItem('cod_concepto'),
              val_definicion_actual:localStorage.getItem('val_definicion'),
              categoria_usuario_cambio:this.forma.value.categoria.concepto_definicion,
              cod_concepto_cambio:this.forma.value.categoria.cod_concepto,
              val_definicion_cambio:this.forma.value.categoria.val_definicion
            }
            console.log(xpedido);
            this.skservice.getActualizarMembresiahttp(xpedido).subscribe(
            async  response=>{
                
                await this.util.ToastSuccess(response.message);
                const alert = await this.alertController.create({
                  header: 'Logout to apply changes',
                  mode: 'ios',
                  backdropDismiss: false,
                  buttons: [
                    {
                      text: 'OK',
                      role: 'confirm',
                      handler: () => {
                        this.statusControl.signOut()
                        localStorage.clear();
                        this.router.navigate(['']).then(() => {
                          location.reload()
                        })
                          ;
                      },
                    },
                  ],
                });
                await alert.present();
              },
              error =>{
                
                console.log(error);
            this.util.ToastError(error.error.message);
              }
            );
          },
          error=>{
            console.log(error);
            this.util.ToastError(error.error.message);
          }
        ); 
        await this.util.closeLoading();
      }else{
        await this.util.closeLoading();
        await this.util.ToastError('There was a problem making the payment');
      }
      
    } catch (error) {
      await this.util.closeLoading();
      console.log(error);
      await this.util.ToastWarning(error.error.message);
    }
	}
  async dismissModal() {
    await this.util.loading();
    this.modalController.dismiss();
    
  }

  async cargarDefi(xval:any){
    try {
      var xvalor = {
        des_definicion:'categorias',
        concepto_definicion:xval.toLowerCase()
      }
      const response = await this.skservice.getDefinicionSitemathttp(xvalor).toPromise();
      console.log(response);
        if (this.categoria.toLowerCase() === 'basic') {
          for (const i in response.def) {
            if (response.def[i].concepto_definicion.toLowerCase() === 'silver' ||
                response.def[i].concepto_definicion.toLowerCase() === 'gold' ||
                response.def[i].concepto_definicion.toLowerCase() === 'platinum') {
              this.definiciones.push(response.def[i]);
            }
          }
        }
        if (this.categoria.toLowerCase() === 'silver') {
          for (const i in response.def) {
            if (response.def[i].concepto_definicion.toLowerCase() === 'gold' ||
                response.def[i].concepto_definicion.toLowerCase() === 'platinum') {
              this.definiciones.push(response.def[i]);
            }
          }
        }
        if (this.categoria.toLowerCase() === 'gold') {
          for (const i in response.def) {
            if (response.def[i].concepto_definicion.toLowerCase() === 'platinum') {
              this.definiciones.push(response.def[i]);
            }
          }
        }
        if (this.categoria.toLowerCase() === 'platinum') {
          for (const i in response.def) {
            if (response.def[i].concepto_definicion.toLowerCase() === 'platinum') {
              this.definiciones.push(response.def[i]);
            }
          }
        }
    } catch (error) {
      console.log(error);
      await this.util.ToastWarning(error.error.message);
    }
  }
  async cargarPrecioCategoria(xval:any){
    try {
      await this.util.cargando();
      this.precio_categoria = '0';
      this.forma.patchValue({ categoria: ''});
        for (const i in this.definiciones) {
          if (this.definiciones[i].concepto_definicion.toLowerCase() === xval.toLowerCase()) {
            this.forma.patchValue({ categoria: this.definiciones[i] });
          }
        }
      console.log(this.forma.value.categoria);
      if (this.forma.value.categoria === '') {
        if (this.lg.toLowerCase() === 'en') {
          await this.util.ToastWarning('You already have this category or a higher one');
        }
        if (this.lg.toLowerCase() === 'al') {
          await this.util.ToastWarning('Sie verfügen bereits über diese oder eine höhere Kategorie');
        }
        await this.util.closeLoading();
        return;
      }else{
        this.precio_categoria = this.forma.value.categoria.val_definicion;
      }
     await this.util.closeLoading();
    } catch (error) {
      console.log(error);
      
      await this.util.ToastWarning(error.error.message);
    }
  }

  onChangeCard({ error }: any) {
		console.log(error, this.forma);
		this.forma.patchValue({ cardNumber: !error });
	}
	onChangeCvc({ error }: any) {
		console.log(error, this.forma);
		this.forma.patchValue({ cardCvc: !error });
	}
	onChangeExp({ error }: any) {
		console.log(error, this.forma);
		this.forma.patchValue({ cardExp: !error });
	}


}
