import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { CarritoService } from 'src/app/services/carrito.service';
import { NotificacionesSevices } from 'src/app/services/notificaciones-sevices.service';
import { RouterService } from 'src/app/services/router.service';
import { skServices } from 'src/app/services/sk.services.service';
import { UiKitController } from 'src/app/services/uikitcontroller.service';
import { AuthGuardServicesService } from '../../services/auth-guard-services.service';
import { FormNivelComponent } from '../shared/modal/form-nivel/form-nivel.component';
import { FormPedidosComponent } from '../shared/modal/form-pedidos/form-pedidos.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';


@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
	@Input() NotificacionesSinVer: number;
	@Input() ChatSinVer: number;
	@Input() usuario_tip: any;
	message: any;
	sesion: any = false;
	img: any;
	img_user: any;
	data: any;
	categoria: any;
	usuario_tipo: any;
	uid: any;
	deseos: any = [];
	chats: any = [];
	notificationOpen = false;
	notificacionesList = [];
	userData:any;
	forma:FormGroup;
  	forma2:FormGroup
	lg:any = localStorage.getItem('lg');
	constructor(
		private router: RouterService,
		private servicio: AuthGuardServicesService,
		private notificaciones: NotificacionesSevices,
		private util: UiKitController,
		private carritoService: CarritoService,
		public firebaseauthService: AuthGuardServicesService,
		public modalController: ModalController,
		private skservice: skServices,
		private fb:FormBuilder
	) {
		/* if (localStorage.getItem('cod_img')) {
			localStorage.getItem('cod_img') !== 'null'
				? this.img = localStorage.getItem('cod_img')
				: null;
		} */
		this.forma = this.fb.group({
			lg: [localStorage.getItem('lg')]
			});
		  this.forma2 = this.fb.group({
			lg: [localStorage.getItem('lg')]
			});
	}

	ngOnInit() {
		this.data = {
			des_usuario:localStorage.getItem('des_usuario'),
			correo_usuario:localStorage.getItem('user'),
			categoria:localStorage.getItem('usuario_categoria'),
			tipo:localStorage.getItem('tipo'),
			cod_img:localStorage.getItem('cod_img')
	  
		  };
		  if (localStorage.getItem('usuario_tipo').toLowerCase() === 'prdr') {
			this.categoria = 'ADVISER';
		  }
		  if (localStorage.getItem('usuario_tipo').toLowerCase() === 'dlr') {
			this.categoria = 'SELLER';
		  }
		  if (localStorage.getItem('usuario_tipo').toLowerCase() === 'mmbr') {
			this.categoria = this.data.categoria;
		  }
		console.log(this.usuario_tip);
		this.uid = localStorage.getItem('userId') || null;
		this.img_user = environment.path_prod + localStorage.getItem('cod_img');
		this.img = true;
		this.usuario_tipo = localStorage.getItem('usuario_tipo');
		this.notificaciones.getNotificacionesList(this.uid).subscribe((res) => {
			this.notificacionesList = res;
		});
		this.notificaciones.getNotideseos(localStorage.getItem('cod_usuario')).subscribe((res) => {
			this.deseos = [];
			for (const k in res) {
				if (res[k].estatus === 'A') {
					this.deseos.push(res[k]);
				}
			}
			console.log(this.deseos);
		});
		this.notificaciones.getchatfin(localStorage.getItem('userId')).subscribe((res) => {
			this.chats = res;
			console.log(this.chats);
		});
		
		this.servicio.UsuarioSubsCribe().subscribe(res => {
			this.userData = res
			/* this.img = res.photoURL */
			this.img = true;
		})
	}

	isDiffToAnonimo() {
		const tipo = localStorage.getItem('usuario_tipo');
		return tipo !== 'VSTNT';
	}

	/* async setOpen() {
		this.servicio.login();
	} */

	async tienda_app(url: any) {
		try {
			this.router.rutas_app(url);
		} catch (error) {
			console.log(error);
		}
	}
	async tienda_app_param(url: any, param:any) {
		try {
			this.router.rutas_app_param(url,param);
		} catch (error) {
			console.log(error);
		}
	}
	chat(url: any) {
		if (this.ChatSinVer) {
			this.notificaciones.limpiarNotificacionesChat(this.uid);
		}
		this.router.rutas_app(url);
	}
	notification() {
		this.notificaciones.limpiarNotificaciones(this.uid);
		const clases = document.querySelector('#notification-dropdown').classList;
		if (this.notificationOpen) {
			this.notificationOpen = false;
			clases.remove('d-block');
			return;
		}
		this.notificationOpen = true;
		clases.add('d-block');
	}

	cerrar_sesion() {
		this.servicio.logout();
	}
	menuOpen() {
		setTimeout(() => {
			this.carritoService.isOpen.next(true);
		}, 0);
	}
	async modalPedidosCarrito(xvalor:any){
		try {
		  await this.util.loading();
		  const modal = await this.modalController.create({
			component: FormPedidosComponent,
			cssClass: 'form-pedidos',
			componentProps: { data:xvalor},
			backdropDismiss: false
		  });
		  await this.util.closeLoading();
		  await modal.present();
		  const { data } = await modal.onWillDismiss();
		  console.log(data);
		  if (data !== undefined && data !== null && data !== '') {
			const response = await this.skservice.imgProdhttp(data).toPromise();
			  await this.util.ToastSuccess(response.message);
		  }
		  return modal;
		} catch (error) {
		  console.log(error);
			await this.util.ToastWarning(error.error.message);
		}
		
	  }
	async modalSubirNivel(){
		try {
		  await this.util.cargando();
		  const modal = await this.modalController.create({
			component: FormNivelComponent,
			cssClass: 'form-pedidos',
			componentProps: { data:''},
			backdropDismiss: false
		  });
		  await this.util.closeLoading();
		  await modal.present();
		  const { data } = await modal.onWillDismiss();
		  if (data !== undefined && data !== null && data !== '') {
			await this.util.cargando();
			const response = await this.skservice.imgProdhttp(data).toPromise();
			await this.util.closeLoading();
			  await this.util.ToastSuccess(response.message);
		  }
		  return modal;
		} catch (error) {
			await this.util.closeLoading();
		  console.log(error);
			await this.util.ToastWarning(error.error.message);
		}
		
	  }
	  async idioma(xvalor:any){
		try {
		  await this.util.loading();
		  console.log(xvalor);
		  localStorage.setItem('lg',xvalor);
		  this.lg = localStorage.getItem('lg');
		  this.router.rutas_app('auth');
		  /* location.reload(); */
		} catch (error) {
		  console.log(error);
		}
	  }
}
