import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertController, LoadingController, MenuController, ModalController } from '@ionic/angular';
import { AuthGuardServicesService } from 'src/app/services/auth-guard-services.service';
import { CarritoService } from 'src/app/services/carrito.service';
import { skServices } from 'src/app/services/sk.services.service';
import { StatusController } from 'src/app/services/statuscontroller.service';
import { StoreService } from 'src/app/services/store.service';
import { UiKitController } from 'src/app/services/uikitcontroller.service';
import { BuyerProfileComponent } from 'src/app/componentes/buyer-profile/buyer-profile.component';


@Component({
  selector: 'app-buyers',
  templateUrl: './buyers.component.html',
  styleUrls: ['./buyers.component.scss'],
})
export class BuyersComponent implements OnInit {
  @Input() data: any;
  lg:any = localStorage.getItem('lg');

  constructor(
    public modalController: ModalController,
    private util: UiKitController,
    public router: Router,
  ) { }

  ngOnInit() {

  }

  async dismissModal() {
    await this.util.cargando();
      this.modalController.dismiss();
    await this.util.closeLoading();
  }

  async perfilSeller(xvalor:any){
    try {
      await this.util.loading();
      this.router.navigate(['perfil/profile',xvalor]);
      
    } catch (error) {
      console.log(error);
      await this.util.ToastWarning(error.error.message);
    }
    
    }

    async showBuyerProfile(buyer: any){
      console.log ('Buyer',buyer);
      try {
        await this.util.cargando();
        const modal = await this.modalController.create({
          component: BuyerProfileComponent,
          cssClass: 'form-pedidos',
          componentProps: { data:buyer},
          backdropDismiss: false
          });
        await this.util.closeLoading();
        await modal.present();
        const { data } = await modal.onWillDismiss();
        return modal;
  
      }catch (error) {
        console.log(error);
        await this.util.closeLoading();
        await this.util.ToastWarning(error.error.message);
      }



    }  

    
  
  
}
