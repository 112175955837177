import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { UiKitController } from './uikitcontroller.service';

@Injectable({
  providedIn: 'root',
})
export class CanactivateServicesService implements CanActivate {
  result: any;
  constructor(
    public router: Router,
    private util: UiKitController,) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const user = localStorage.getItem('userId');
    const sesion = localStorage.getItem('cod_sesion');
    if (!user || !sesion) {
      this.router.navigate(['']);
      this.util.ToastWarning('You must start a valid session');
      return
    }
    return true;
  }
}
