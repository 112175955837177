import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-pago-membresia',
  templateUrl: './form-pago-membresia.component.html',
  styleUrls: ['./form-pago-membresia.component.scss'],
})
export class FormPagoMembresiaComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
