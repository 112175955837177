import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { Auth, createUserWithEmailAndPassword, getAuth, signInWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import { skServices } from 'src/app/services/sk.services.service';
import { UiKitController } from 'src/app/services/uikitcontroller.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-form-recuperacion',
  templateUrl: './form-recuperacion.component.html',
  styleUrls: ['./form-recuperacion.component.scss'],
})
export class FormRecuperacionComponent implements OnInit {
  @ViewChild('passwordEyeRegister, { read: ElementRef }') passwordEye: ElementRef;
  @Input() data: any;
  passwordTypeInput  =  'password';
  lg:any = localStorage.getItem('lg');
  forma:FormGroup;
  forma2:FormGroup;
  codigo_valido:any = false;
  constructor(
    private router: Router,
    private util: UiKitController,
    private modalController: ModalController,
    private skservice: skServices,
    private fb:FormBuilder,
    public auth: AngularFireAuth,
  ) { 
    this.forma = this.fb.group({
      code_env: ['', [Validators.required]],
    });
    this.forma2 = this.fb.group({
      correo: [''],
      pass: ['', [Validators.required, Validators.minLength(8)]],
      cpass: ['',[Validators.required,RxwebValidators.compare({ fieldName: 'pass' }),
        ],
      ],
    });
  }

  ngOnInit() {}
  async dismissModal() {
    await this.util.cargando();
    this.modalController.dismiss();
    await this.util.closeLoading(); 
  }

  async  togglePasswordMode() {
    //cambiar tipo input
  this.passwordTypeInput = this.passwordTypeInput === 'text' ? 'password' : 'text';
   //obtener el input
   const nativeEl = this.passwordEye.nativeElement.querySelector('input');
   //obtener el indice de la posición del texto actual en el input
   const inputSelection = nativeEl.selectionStart;
   //ejecuto el focus al input
   nativeEl.focus();
  //espero un milisegundo y actualizo la posición del indice del texto
   setTimeout(() => {
       nativeEl.setSelectionRange(inputSelection, inputSelection);
   }, 1);

}

 async getCodEnv(){
    try {
      await this.util.cargando();
      this.codigo_valido = false;
      let codigo = '';
      for (let i = 0; i < 6; i++) {
        codigo += Math.floor(Math.random() * 10).toString();
      }
      var xvalor = {
        cod_env : codigo,
        correo : this.data.correo
      };
      const response =  await this.skservice.getCodEnvhttp(xvalor).toPromise();
      await this.util.closeLoading(); 
      this.util.ToastSuccess('Code send successfully');
    } catch (error) {
      console.log(error);
    }
  }
 async getCodEnvPass(){
    try {
      await this.util.cargando();
      var xvalor = {
        cod_env : this.forma.value.code_env,
        correo : this.data.correo
      };
      const response =  await this.skservice.getCodEnvPasshttp(xvalor).toPromise();

      this.codigo_valido = true;
      this.util.ToastSuccess('Code Valid');
      await this.util.closeLoading(); 
      this.forma.reset();
    } catch (error) {
      await this.util.closeLoading();
      this.util.ToastWarning('Code Invalid');
      console.log(error);
    }
  }
  async getCodEnvPassUpdate(){
    try {
      await this.util.cargando();
      this.codigo_valido = false;
      var xvalor = {
        correo:this.data.correo,
        pass_usuario_p:this.forma2.value.pass
      };
      const response = await this.skservice.getCodEnvPassUpdatehttp(xvalor).toPromise();
      this.util.ToastSuccess('Password changed successfully');
      await this.util.closeLoading(); 
    this.forma2.reset();
    this.modalController.dismiss();

    } catch (error) {
      await this.util.closeLoading(); 
      this.util.ToastWarning('Problems trying to update the password');
      console.log(error);
    }
  }
}
