import { Oferta } from './../../models';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Productos } from 'src/models';
import { UiKitController } from './uikitcontroller.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, Subscription, BehaviorSubject } from 'rxjs';
import { ToastController } from '@ionic/angular';
import { AuthGuardServicesService } from './auth-guard-services.service';
import { ProductosService } from './productos.service';
import iziToast from 'izitoast';

@Injectable({
	providedIn: 'root',
})
export class CarritoService {
	Pedido: any;
	Pedido$ = new Subject<any>();
	carritoSuscriber: Subscription;
	miCarrito: any[] = [];
	miTarjeta = new BehaviorSubject<any[]>([]);
	miTarjeta$ = this.miTarjeta.asObservable();
	cliente: any;
	Path = 'Carrito/';
	Uid = '';
	isOpen = new Subject<boolean>()

	constructor(
		private firebaseauthService: AuthGuardServicesService,
		private firestoreService: ProductosService,
		private UiKit: UiKitController,
		private afs: AngularFirestore,
	) {
		this.InicializarCarrito();
		this.firebaseauthService.EstadoAutenticacion().subscribe((result) => {
			if (result !== null) {
				console.log('hola', result.uid);
				this.Uid = result.uid;
				this.CargarCliente();
			}
		});
		this.isOpen.next(false)
	}

	//trae de la base de datos
	CargarCarrito() {
		const path = 'Clientes/' + this.Uid + '/' + this.Path;
		this.firestoreService
			.ConsultarProducto(path, this.Uid)
			.subscribe((result) => {
				if (result) {
					this.Pedido = result;
					this.Pedido$.next(this.Pedido);
				} else {
					this.InicializarCarrito();
				}
			});
	}

	InicializarCarrito() {
		this.Pedido = {
			Id: this.Uid,
			Cliente: this.cliente,
			Productos: [],
			PrecioTotal: null,
			Direccion: null,
			Telefono: null,
			Estado: 'Enviado',
			fecha: new Date(),
			Valoracion: null,
		};
		this.Pedido$.next(this.Pedido);
	}

	//lista en vista
	ListarCarrito(): Observable<any> {
		setTimeout(() => {
			console.log(this.Pedido, 'aqui estoy');
			this.Pedido$.next(this.Pedido);
		}, 100);
		return this.Pedido$.asObservable();
	}

	//agrega al carrito
	async AgregarProducto(Producto) {
		/*  let elCirculito = document.querySelector('.elCirculito') as HTMLDivElement
		let carritoN = parseInt(elCirculito.textContent)
 */
		try {
			if (this.Uid.length) {
				await this.UiKit.openLoader()
				const item = this.Pedido.Productos.find(
					(ProductosPedido) => ProductosPedido.Producto.Id === Producto.Id
				);
				if (item !== undefined) {
					item.Cantidad++;

					await this.presentToast();

					/*    elCirculito.textContent = this.Pedido.Productos.length.toString() */
				} else {
					const add = {
						Producto,
						Cantidad: 1,
					};

					await this.presentToast();

					/* carritoN++ */
					/* elCirculito.textContent = carritoN.toString() */
					this.Pedido.Productos.push(add);
					/* this.miCarrito.push(add) */
					this.miTarjeta.next(this.miCarrito);
				}
				const path = 'Clientes/' + this.Uid + '/' + this.Path;
				await this.firestoreService.CrearProductos(
					this.Pedido,
					path,
					this.Pedido.Id
				);
				await this.UiKit.closeLoader()
			} else {
				return this.firebaseauthService.login();
			}
		} catch (error) {
			await this.UiKit.closeLoader()
			console.log(error);
		}
	}

	//saca producto del carrito
	async SacarProducto(Producto) {
		try {
			await this.UiKit.openLoader()
			let position = 0;
			let position2 = 0;
			const item = this.Pedido.Productos.find((ProductosPedido, index) => {
				position = index;
				return ProductosPedido.Producto.Id === Producto.Id;
			});
			this.miCarrito.find((ProductosPedido, index) => {
				position2 = index;
				return ProductosPedido.Id === Producto.Id;
			});

			if (item !== undefined) {
				item.Cantidad--;
				if (!item.Cantidad) {
					this.Pedido.Productos.splice(position, 1);
					this.miCarrito.splice(position2, 1);
					this.miTarjeta.next(this.miCarrito);
					/* let elCirculito = document.querySelector('.elCirculito') as HTMLDivElement;
					let carritoN = parseInt(elCirculito.textContent);
							carritoN--;
					elCirculito.textContent = carritoN.toString(); */
				}
			}
			console.log('en remover pedido menor ', this.Pedido);

			const path = 'Clientes/' + this.Uid + '/' + this.Path;
			await this.firestoreService
				.CrearProductos(this.Pedido, path, this.Pedido.Id)
				await this.UiKit.closeLoader()

		} catch (error) {
			await this.UiKit.closeLoader()
			console.log(error)
		}

	}

	//envia a base de datos el pedido y al administrador
	RealizarPedido() { }

	async presentToast() {
		iziToast.success({
			/*         title: 'Error', */
			message: 'Add to Cart',
			position: 'topRight',
			timeout: 1500,
		});
	}

	//elimina todo los productos ananidos
	ElimminarCarrito() {
		const path = 'Clientes/' + this.Uid + '/' + 'Carrito';
		this.firestoreService.EliminarProductos(path, this.Uid).then(() => {
			this.InicializarCarrito();
		});
	}

	CargarCliente() {
		const path = 'Clientes';
		this.firestoreService
			.ConsultarProducto(path, this.Uid)
			.subscribe((result) => {
				this.cliente = result;
				this.CargarCarrito();
			});
	}

	cargarPedidoCliente(pedido: any) {
		this.miCarrito = pedido;
		this.miTarjeta.next(this.miCarrito);
	}
	//agrega al carrito
	async AgregarProductoOferta(Producto: Productos, userId: string, oferta: Oferta) {
		try {
			Producto.price = Number(oferta.precio).toString()
			await this.UiKit.openLoader()
			const path =  + userId + '/' + this.Path;
			let Pedido: any = await this.afs
										.collection('Clientes')
										.doc(userId)
										.collection('Carrito')
										.doc(userId)
										.get()
										.toPromise()
			Pedido = Pedido.data()
			console.log(Pedido)
			if(!Pedido){
				Pedido = {
					Id: userId,
					Cliente: {},
					Productos: [],
					PrecioTotal: null,
					Direccion: null,
					Telefono: null,
					Estado: 'Enviado',
					fecha: new Date(),
					Valoracion: null,
				};
			}
			const item = Pedido.Productos.find(
				(ProductosPedido) => ProductosPedido.Producto.Id === Producto.Id
			);
			if (item !== undefined) {
				item.Cantidad++;
			} else {
				const add = {
					Producto,
					Cantidad: 1,
				};

				Pedido.Productos.push(add);
			}
			await this.afs
			.collection('Clientes')
			.doc(userId)
			.collection('Carrito')
			.doc(userId)
			.update(Pedido)

			await this.UiKit.closeLoader()
			this.UiKit.ToastSuccess('Offer accepted successfully')
		} catch (error) {
			await this.UiKit.closeLoader()
			console.log(error);
		}
	}
}
