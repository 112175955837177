import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertController, LoadingController, MenuController, ModalController } from '@ionic/angular';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { AuthGuardServicesService } from 'src/app/services/auth-guard-services.service';
import { CarritoService } from 'src/app/services/carrito.service';
import { skServices } from 'src/app/services/sk.services.service';
import { StoreService } from 'src/app/services/store.service';
import { UiKitController } from 'src/app/services/uikitcontroller.service';
import { environment } from 'src/environments/environment';
import iziToast from 'izitoast';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { StatusController } from 'src/app/services/statuscontroller.service';

@Component({
  selector: 'app-form-comentarios',
  templateUrl: './form-comentarios.component.html',
  styleUrls: ['./form-comentarios.component.scss'],
})
export class FormComentariosComponent implements OnInit {
  @Input() data: any;
  forma:FormGroup;
  lg:any = localStorage.getItem('lg');
  constructor(
    private fb:FormBuilder,
    private modalController: ModalController,
    private util: UiKitController
  ) {
    this.forma = this.fb.group({
			comentario: ['', [Validators.required, Validators.maxLength(50), Validators.minLength(10)]]
		  });
   }

  ngOnInit() {}

  async modalrespuesta(){
		try {
      await this.util.cargando();
           this.modalController.dismiss(this.forma.value);
      await this.util.closeLoading();
    } catch (error) {
      console.log(error);
    }
	}

	async dismissModal() {
		await this.util.cargando();
		  this.modalController.dismiss(null);
		await this.util.closeLoading();
	  }

}
