import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { environment } from 'src/environments/environment';
import { AlertController, ModalController } from '@ionic/angular';
import { ModalLoginComponent } from '../componentes/modal-login/modal-login.component';
import { Router } from '@angular/router';
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updateProfile,
  IdTokenResult,
  User,
} from 'firebase/auth';
import iziToast from 'iziToast';
import { Auth } from '@angular/fire/auth';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Usuario } from 'src/models';
import { ModalFormUserComponent } from '../componentes/modal-form-user/modal-form-user.component';
import { StatusController } from './statuscontroller.service';
import { UsernameModalComponent } from '../componentes/modalslayout/username-modal/username-modal.component';
import { skServices } from './sk.services.service';
import { UiKitController } from './uikitcontroller.service';
import { FormNivelComponent } from '../componentes/shared/modal/form-nivel/form-nivel.component';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardServicesService {
  url: any ='https://https://appionic-3f8fd-default-rtdb.firebaseio.com/usuarios.json';
  ruta: any;
  db = firebase.firestore();
  path = 'Clientes';
  Uid: string;
  items: Observable<any[]>;
  usuarioDataSub$ = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('userData')));
  usuario: any = {};
  usuarioData: any = JSON.parse(localStorage.getItem('userData')) || {
    userId: '',
    email: '',
    displayName: '',
    nombre: '',
    apellido: '',
    genero: '',
    fecha_nacimiento: '',
    edad: '',
    descripcion: '',
    photoURL: '',
    telefono: '',
    direccion: {
      pais: '',
      dir_str: '',
      location: {
        lat: '',
        long: '',
      },
    },
  };
  lg:any = localStorage.getItem('lg');
  message_1:any;
  message_2:any;
  message_3: string;
  constructor(
    public auth: AngularFireAuth,
    public modalController: ModalController,
    private http: HttpClient,
    public router: Router,
    private alertController: AlertController,
    private statusControl: StatusController,
    private skservices: skServices,
    private util: UiKitController,
  ) {
    this.usuario = this.getUid();
    firebase.initializeApp(environment.firebase);
    if (localStorage.getItem('userId') && localStorage.getItem('userId') !== 'null') {
      /* this.getUserData() */
    }
  }
  get isLoggedIn(): boolean {
    const user = localStorage.getItem('userId');
    return user ? true : false;
  }

  get Usuario(): Usuario {
    return this.usuarioData;
  }
  UsuarioSubsCribe(): Observable<any> {
    console.log('aqui')
    return this.usuarioDataSub$.asObservable();
  }
  async getUid() {
    let id: string;
    this.auth.authState.subscribe((user) => {
      if (!user) {
        return;
      }

      this.Uid = localStorage.getItem('userId') || user.uid;

      return (id = user.uid);
    });

    return id;
  }
  async getUserData() {
    const docSnap = (
      await this.db.collection(this.path).doc(localStorage.getItem('userId')).get()
    ).data() as Usuario;
    this.usuarioData = docSnap
    console.log(docSnap);
  }

  EstadoAutenticacion() {
    return this.auth.authState;
  }
 
  createsesionhttp(fechas: any): Observable<any> {
    const body = fechas;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const ruta = this.http.post(this.url, body, { headers });

    return ruta;
  }

  async login(usuario_tipo?: any) {
    const modal = await this.modalController.create({
      component: ModalLoginComponent,
      cssClass: 'my-custom-class',
      componentProps: { usuario_tipo },
    });

    await modal.present();
    return modal
  }
  async loginSub(plan: any, usuario_tipo: string) {
    const modal = await this.modalController.create({
      component: ModalLoginComponent,
      cssClass: 'my-custom-class',
      backdropDismiss: false,
      componentProps: { sub: true, plan, usuario_tipo },
    });

    await modal.present();
    return modal
  }

  /* async LoginWithGoogle() {
    try {
      const userCredential = await this.auth.signInWithPopup(
        new firebase.auth.GoogleAuthProvider()
      );
      const user = userCredential.user as any;

      localStorage.setItem('userId', user.uid);
      localStorage.setItem('token', user.accessToken);
      localStorage.setItem('user', user.email);
      localStorage.setItem('img', user.photoURL);
      localStorage.setItem('verify_email', user.emailVerified);
      localStorage.setItem('tipo', 'Google');

      const docSnap = (
        await this.db.collection(this.path).doc(user.uid).get()
      ).data();

      const objx: Usuario = {
        tipo:'Google',
        userId: user.uid,
        displayName: user.displayName,
        email: user.email,
        nombre: '',
        apellido: '',
        genero: '',
        fecha_nacimiento: '',
        edad: '',
        descripcion: '',
        photoURL: user.photoURL,
        telefono: '',
        direccion: {
          pais: '',
          dir_str: '',
          location: {
            lat: '',
            long: '',
          },
        },
        productos:[]
      };
      this.statusControl.setPresence('online')

      if (!docSnap) {
        this.usuarioData = objx;

        await this.db.collection(this.path).doc(user.uid).set(objx);
        await this.db.collection('userChats').doc(user.uid).set({});
        await this.db.collection('Notificaciones').doc(user.uid).set({notificacionesCantSinVer:0});
        localStorage.setItem('userData', JSON.stringify(objx));

        console.log(this.usuarioData, 'aqui esta el problema');

        location.reload();

        this.modalController.dismiss();

        return;
      }
      if(!docSnap.tipo){
        await this.db.collection(this.path).doc(user.uid).update({
          tipo:'Google'
        });
      }

      this.usuarioData = docSnap as Usuario;

      localStorage.setItem('userData', JSON.stringify(docSnap));

      this.modalController.dismiss();

      this.router.navigate(['home']).then(()=> {
        location.reload()
      })

      return;
    } catch (error) {
      console.log(error);

      iziToast.error({
        message: 'Email o Contraseña invalido',
      });
    }
  } */

  async loginEmail(dataUser: any) {
    try {
      var data = {
        nom_usuario:dataUser.email,
        pass_usuario:dataUser.password
      }
      const response =  await this.skservices.loginhttp(data).toPromise();
      const auth: Auth = getAuth();
      const userCredential = await signInWithEmailAndPassword(auth,dataUser.email, dataUser.password);

      const user = userCredential.user as any;
       
      localStorage.setItem('cod_usuario', response.usuario[0].id);
      localStorage.setItem('cod_sesion', response.sesiones[0].cod_sesion);
      localStorage.setItem('dir_ip_client', response.sesiones[0].dir_ip_client);
      localStorage.setItem('cod_img', response.usuario[0].id);
      localStorage.setItem('usuario_tipo', response.usuario[0].tipo);
     
      localStorage.setItem('userId', user.uid);
      localStorage.setItem('token', user.accessToken);
      localStorage.setItem('user', user.email);
      localStorage.setItem('img', user.photoURL ? user.photoURL: 'https://w7.pngwing.com/pngs/178/595/png-transparent-user-profile-computer-icons-login-user-avatars-thumbnail.png');
      localStorage.setItem('tipo', 'Email');

      const docSnap = (
await this.db.collection(this.path).doc(user.uid).get()).data();

      const objx: Usuario = {
        tipo: 'Email',
        tipoUsuario: localStorage.getItem('usuario_tipo'),
        userId: user.uid,
        email: user.email,
        displayName: user.displayName,
        nombre: '',
        apellido: '',
        genero: '',
        fecha_nacimiento: '',
        edad: '',
        descripcion: '',
        photoURL: user.photoURL,
        telefono: '',
        direccion: {
          pais: '',
          dir_str: '',
          location: {
            lat: '',
            long: '',
          },
        },
        productos: []
      };
      this.statusControl.setPresence('online')
      if (!docSnap) {
        await this.db.collection(this.path).doc(user.uid).set(objx);
        await this.db.collection('userChats').doc(user.uid).set({});
        await this.db.collection('Notificaciones').doc(user.uid).set({ notificacionesCantSinVer: 0 });
        localStorage.setItem('userData', JSON.stringify(objx));

        this.router.navigate(['/home']).then(() => {
          setTimeout(() => {
            location.reload()
          }, 500);
        })

        this.modalController.dismiss();

        return;
      }
      if (!docSnap.tipo) {
        await this.db.collection(this.path).doc(user.uid).update({
          tipo: 'Email'
        });
      }
      if (!docSnap.tipoUsuario) {
        await this.db.collection(this.path).doc(user.uid).update({
          tipoUsuario: dataUser.tipoUsuario ?? localStorage.getItem('usuario_tipo')
        });
      }
      if (!docSnap.photoURL) {
        await this.db.collection(this.path).doc(user.uid).update({
          photoURL: user.photoURL ?? localStorage.getItem('img')
        });
      }

      this.usuarioData = docSnap as Usuario;
      this.usuarioDataSub$.next(docSnap)

      localStorage.setItem('userData', JSON.stringify(docSnap));

      this.modalController.dismiss();

      this.router.navigate(['/home']).then(() => {
        setTimeout(() => {
          location.reload()
        }, 500);
      })

      return;
    } catch (error) {
      console.log(error);

      iziToast.error({
        message: 'Email o Password wrong',
      });
    }
  }
  async iniciarApp() {
    await this.auth.signInAnonymously();
  }
  async usuarioVisitante() {
    try {
      const userCredential = await this.auth.signInAnonymously();
      const user = userCredential.user as any;

      console.log(user);

      localStorage.setItem('userId', user.uid);
      localStorage.setItem('token', user.accessToken);
      localStorage.setItem('user', user.email);
      localStorage.setItem('img', user.photoURL);
      localStorage.setItem('verify_email', user.emailVerified);
      localStorage.setItem('tipo', 'Anonimo');

      const docSnap = (
        await this.db.collection(this.path).doc(user.uid).get()
      ).data();

      const objx: Usuario = {
        tipo: 'Anonimo',
        tipoUsuario: 'cliente',
        userId: user.uid,
        displayName: '',
        email: '',
        nombre: '',
        apellido: '',
        genero: '',
        fecha_nacimiento: '',
        edad: '',
        descripcion: '',
        photoURL: user.photoURL,
        telefono: '',
        direccion: {
          pais: '',
          dir_str: '',
          location: {
            lat: '',
            long: '',
          },
        },
        productos: []
      };
      this.statusControl.setPresence('online')

      if (!docSnap) {
        this.usuarioData = objx;

        await this.db.collection(this.path).doc(user.uid).set(objx);
        await this.db.collection('userChats').doc(user.uid).set({});
        await this.db.collection('Notificaciones').doc(user.uid).set({ notificacionesCantSinVer: 0 });
        localStorage.setItem('userData', JSON.stringify(objx));

        console.log(this.usuarioData, 'aqui esta el problema');

        this.modalController.dismiss();

        this.router.navigate(['home']).then(() => {
          location.reload()
        })

        return;
      }
      if (!docSnap.tipo) {
        await this.db.collection(this.path).doc(user.uid).update({
          tipo: 'Anonimo'
        });
      }

      this.usuarioData = docSnap as Usuario;

      localStorage.setItem('userData', JSON.stringify(docSnap));

      location.reload();

      this.modalController.dismiss();

      return;
    } catch (error) {
      console.log(error);

      iziToast.error({
        message: 'Email o Password wrong',
      });
    }
  }
  async verificarCorreo() {
    return (await this.auth.currentUser).sendEmailVerification();
  }
  async crearUsuario(dataUser: any) {
    try {
      const auth: Auth = getAuth();

      await createUserWithEmailAndPassword(
        auth,
        dataUser.email,
        dataUser.password
      );
      await updateProfile(auth.currentUser, { displayName: dataUser.name });

      //await (await this.auth.currentUser).sendEmailVerification()

      iziToast.success({
        message: `Check your email`,
      });
    } catch (error) {
      console.log(error);

      iziToast.error({
        message: 'Account hasn\'t been possible to create',
      });
    }
  }
  async logout() {
    await this.util.cargando();
    if (this.lg.toLowerCase() === 'en') {
      this.message_1 = 'Sure Logout?';
      this.message_2 = 'Cancel';
      this.message_3 = 'Ok';
    }
    if (this.lg.toLowerCase() === 'al') {
      this.message_1 = 'Sicher Abmelden?';
      this.message_2 = 'Zuruck';
      this.message_3 = 'Ok';
    }
    const alert = await this.alertController.create({
      header: this.message_1,
      mode: 'ios',
      backdropDismiss: false,
      buttons: [
        {
          text: this.message_2,
          role: 'cancel',
          handler: () => null,
        },
        {
          text: this.message_3,
          role: 'confirm',
        handler: async () => {
            await this.util.cargando();
            this.statusControl.signOut()
            localStorage.clear();
            this.router.navigate(['']).then(async() => {
              location.reload();
              await this.util.closeLoading();
            })
              ;
          },
        },
      ],
    });
    await this.util.closeLoading();
    await alert.present();
  }
  validarDatosUsuario() {
    for (const key in this.usuarioData) {
      if (key !== 'telefono' && key !== 'direccion') {
        if (this.usuarioData[key] === '' || 
        this.usuarioData[key] === 'https://w7.pngwing.com/pngs/178/595/png-transparent-user-profile-\ computer-icons-login-user-avatars-thumbnail.png') {
          return this.configurarUsuario(true);
        }
      }
    }
  }
  async actualizarUsuario(data: any) {
    try {
      const user = firebase.auth().currentUser;

      const docSnap = (
        await this.db.collection(this.path).doc(this.Uid).get()
      ).data() as Usuario;

      const {
        nombre,
        apellido,
        genero,
        pais,
        descripcion,
        fecha_nacimiento,
        edad,
        photoURL
      } = data;

      user.updateProfile({
        displayName: `${nombre} ${apellido}` ,
        photoURL
      })

      docSnap.nombre = nombre;
      docSnap.apellido = apellido;
      docSnap.genero = genero;
      docSnap.descripcion = descripcion;
      docSnap.fecha_nacimiento = fecha_nacimiento;
      docSnap.direccion.pais = pais;
      docSnap.edad = edad;
      docSnap.photoURL = photoURL;
      docSnap.displayName = `${nombre} ${apellido}`

      this.usuarioData = docSnap;
      this.usuarioDataSub$.next(docSnap)
      localStorage.setItem('userData',JSON.stringify(docSnap))
      return await this.db.collection(this.path).doc(this.Uid).update(docSnap);
    } catch (error) {
      return console.log(error);
    }
  }
  async actualizarUsuarioAnonimo(data: any) {
    try {
      const docSnap = (
        await this.db.collection(this.path).doc(this.Uid).get()
      ).data() as Usuario;

      const {
        displayName
      } = data;

      docSnap.displayName = displayName;

      this.usuarioData = docSnap;

      return await this.db.collection(this.path).doc(this.Uid).update(docSnap);
    } catch (error) {
      return console.log(error);
    }
  }
  async configurarUsuario(actualizar?: boolean) {
    console.log(actualizar)
    const modal = await this.modalController.create({
      component: ModalFormUserComponent,
      cssClass: 'my-custom-class',
      backdropDismiss: !actualizar ? true : false,
      componentProps: {
        Usuario: this.Usuario,
        Actualizar: actualizar ?? false,
      },
    });

    await modal.present();
  }
  async dataUsuarioAnonimo() {
    console.log(this.usuarioData.displayName);
    if (!this.usuarioData.displayName) {
      const modal = await this.modalController.create({
        component: UsernameModalComponent,
        cssClass: 'modal-UserName',
        componentProps: {},
        backdropDismiss: false
      });
      await modal.present();
    }
  }

  async validar_limitacion(data:any){
    try {
      console.log(data);
      const response_ = await this.skservices.getCategoriaUsuariohttp(data).toPromise();
      const response = await this.skservices.getMsmUsuariohttp(data).toPromise();
      
      if ( parseInt(data.msm_usuario) > 0) {
        if (response.msm.length <  parseInt(response_.categoria[0].val_msm) ) {
          const response_resul = await this.skservices.getMsmUsuarioConthttp(data).toPromise();
          return true;
        }else{
          await this.util.closeLoading();
          this.util.ToastWarning('You must purchase a membership to continue enjoying the service');
          const modal = await this.modalController.create({
            component: FormNivelComponent,
            cssClass: 'form-usuario-producto',
            componentProps: { data:localStorage.getItem('usuario_categoria')},
            backdropDismiss: false
          });
          await modal.present();
          const { data } = await modal.onWillDismiss();
          if (data !== undefined && data !== null && data !== '') {
            console.log(data);
            
            await this.router.navigate(['proveedor',data.cod_usuario]);
          }
         
            return false;
        }
      }
      if ( parseInt(data.chat_usuario) > 0) {
        if (response.msm.length <  parseInt(response_.categoria[0].val_msm) ) {
          const response_resul = await this.skservices.getMsmUsuarioConthttp(data).toPromise();
          return true;
        }else{
          this.util.ToastWarning('You must purchase a membership to continue enjoying the service');
          const modal = await this.modalController.create({
            component: FormNivelComponent,
            cssClass: 'form-usuario-producto',
            componentProps: { data:localStorage.getItem('usuario_categoria')},
            backdropDismiss: false
          });
          await modal.present();
          const { data } = await modal.onWillDismiss();
          if (data !== undefined && data !== null && data !== '') {
            console.log(data);
            
            await this.router.navigate(['proveedor',data.cod_usuario]);
          }
          
            return false;
        }
      }
      if ( parseInt(data.deseo_usuario) > 0) {
        if (response.msm.length <  parseInt(response_.categoria[0].val_msm) ) {
          const response_resul = await this.skservices.getMsmUsuarioConthttp(data).toPromise();
          return true;
        }else{
          this.util.ToastWarning('You must purchase a membership to continue enjoying the service');
          const modal = await this.modalController.create({
            component: FormNivelComponent,
            cssClass: 'form-usuario-producto',
            componentProps: { data:localStorage.getItem('usuario_categoria')},
            backdropDismiss: false
          });
          await modal.present();
          const { data } = await modal.onWillDismiss();
          if (data !== undefined && data !== null && data !== '') {
            console.log(data);
            
            await this.router.navigate(['proveedor',data.cod_usuario]);
          }
          
            return false;
        }
      }
      if ( parseInt(data.swipe_usuario) > 0) {
        
        if (response.msm.length <  parseInt(response_.categoria[0].val_swipe) ) {
          const response_resul = await this.skservices.getMsmUsuarioConthttp(data).toPromise();
          return true;
        }else{
          this.util.ToastWarning('You must purchase a membership to continue enjoying the service');
          const modal = await this.modalController.create({
            component: FormNivelComponent,
            cssClass: 'form-usuario-producto',
            componentProps: { data:localStorage.getItem('usuario_categoria')},
            backdropDismiss: false
          });
          await modal.present();
          const { data } = await modal.onWillDismiss();
          if (data !== undefined && data !== null && data !== '') {
            console.log(data);
            
            await this.router.navigate(['proveedor',data.cod_usuario]);
          }
          
            return false;
        }
      }
      
    } catch (error) {
      console.log(error);
    }
  }
}
