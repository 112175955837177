import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { LoadingController, ModalController } from '@ionic/angular';
import { AuthGuardServicesService } from 'src/app/services/auth-guard-services.service';

@Component({
  selector: 'app-modal-login',
  templateUrl: './modal-login.component.html',
  styleUrls: ['./modal-login.component.scss'],
})
export class ModalLoginComponent implements OnInit {

  @Input() sub: boolean
  @Input() tipoUsuario: string

  action: string;
  form: FormGroup;
  formRegister: FormGroup;

  constructor(
    public userControl: AuthGuardServicesService,
    public modalController: ModalController,
    private fb: FormBuilder,
    private loadindControl: LoadingController
  ) {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });

    this.formRegister = this.fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      tipoUsuario: [this.tipoUsuario],
      confirm_password: ['',
        [
          Validators.required,
          RxwebValidators.compare({ fieldName: 'password' }),
        ],
      ],
    });

    this.action = 'login';
  }

  ngOnInit() {
    if(this.sub){
      this.action = 'registerSub'
      window.addEventListener('beforeunload', this.beforeUnloadListener, {capture: true})
    }

  }
  beforeUnloadListener = (event) => {
    event.preventDefault();
    return event.returnValue = 'Are you sure you want to exit?';
  };

  changeAction(action) {
    this.action = action;
  }

  VisitanteLogin(event: Event) {
    event.preventDefault();

    this.userControl.usuarioVisitante();
  }

  /* GoogleLogin(event: Event) {
    event.preventDefault();

    this.userControl.LoginWithGoogle();
  }
 */
  async EmailLogin(event: Event) {
    event.preventDefault();

    const loading = await this.loadindControl.create({
      message: 'Wait a moment...',
    });

    loading.present();

    await this.userControl.loginEmail(this.form.value);

    loading.dismiss();
  }

  async registrarUsuario(event: Event) {
    event.preventDefault();

    const loading = await this.loadindControl.create({
      message: 'Wait a moment...',
    });

    loading.present();

    console.log('hola');

    await this.userControl.crearUsuario(this.formRegister.value);

    loading.dismiss();

    this.formRegister.reset();

    this.changeAction('login');
  }

  async dismissModal() {
    this.modalController.dismiss();
  }
  async dismissModalSub() {
    this.modalController.dismiss('register');
  }
}
