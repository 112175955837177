import { UiKitController } from './uikitcontroller.service';
import { Oferta } from './../../models';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Usuario, Productos } from 'src/models';
import firebase from 'firebase/compat/app';
import { AuthGuardServicesService } from './auth-guard-services.service';

@Injectable({
	providedIn: 'root',
})
export class OfertaService {
	usuario: Usuario;
	constructor(
		private afs: AngularFirestore,
		private autho: AuthGuardServicesService,
		private UiKit: UiKitController,
	) {
		this.usuario = this.autho.Usuario;
	}
	async crearOferta(producto: any, precio: any) {
		try {
			const ofertaId = this.afs.createId()
			const oferta: Oferta = {
				ofertaId,
				userId: this.usuario.userId,
				precio,
				producto,
				isAceptado: false,
				isPendiente: true,
				fecha: firebase.firestore.FieldValue.serverTimestamp(),
			}

			this.afs
				.collection('Oferta')
				.doc(ofertaId)
				.set(oferta);

			return oferta
		} catch (error) {
			console.log(error)
		}
	}
	async actualizarOferta(isAceptado: boolean, ofertaId: string, mensajeId: string, idChat: string) {
		try {

			this.afs
				.collection('Oferta')
				.doc(ofertaId)
				.update({
					isAceptado,
					isPendiente: false,
				})
			/* 	this.afs
				.collection('Chats')
				.doc(idChat)
				.collection('Mensajes')
				.doc(mensajeId)
				.update({
					'oferta.isAceptado': isAceptado,
					'oferta.isPendiente':false,
					}); */

		} catch (error) {
			console.log(error)
		}

	}
	consultarOferta(ofertaId: string) {

		return this.afs
			.collection('Oferta')
			.doc(ofertaId)
			.valueChanges()

	}
	consultarOfertaXUser(userId: string) {

		return this.afs
			.collection('Oferta', (ref) => ref.where('userId', '==', userId).where('isPendiente', '==', true))
			.valueChanges()

	}
}
