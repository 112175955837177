
import * as _ from 'lodash';
//NG-OFFLINE
import axios from 'axios';

import { Component, OnDestroy, OnInit } from '@angular/core';

import { Injectable, Inject, PLATFORM_ID } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, fromEvent, merge, empty, Subscription } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { mapTo } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { ref, Storage, uploadBytes } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { $ } from 'protractor';

type Usuario = {cod_usuario: string, des_usuario: string}

@Injectable({
    providedIn: 'root'
})
export class skServices {
    ruta: any;

    CARPETA_IMAGENES = 'img';
    url0001: any = environment.path_prod+'/api/auth/getPerfilAccesoVisitante';
    url0002: any = environment.path_prod+'/api/auth/usuario_miembro';
    url0003: any = environment.path_prod+'/api/auth/getPerfilAccesoMiembros';
    url0004: any = environment.path_prod+'/api/auth/getImgPerfil';
    url0005: any = environment.path_prod+'/api/auth/getImgProd';
    url0006: any = environment.path_prod+'/api/auth/getDepositoProd';
    url0007: any = environment.path_prod+'/api/auth/getProd';
    url0008: any = environment.path_prod+'/api/auth/getImgDLR';
    url0009: any = environment.path_prod+'/api/auth/getUsuarioParametro';
    url0010: any = environment.path_prod+'/api/auth/getDepositoProdDlr';
    url0011: any = environment.path_prod+'/api/auth/getUsuarioParametroIdCloud';
    url0012: any = environment.path_prod+'/api/auth/getUsuarioSwiper';
    url0013: any = environment.path_prod+'/api/auth/getEventosLikeDislike';
    url0014: any = environment.path_prod+'/api/auth/getFavoritos';
    url0015: any = environment.path_prod+'/api/auth/getUsuarioChat';
    url0016: any = environment.path_prod+'/api/auth/getUpdateUsuario';
    url0017: any = environment.path_prod+'/api/auth/getPedidoCarrito';
    url0018: any = environment.path_prod+'/api/auth/getPedidoCarritoActivo';
    url0019: any = environment.path_prod+'/api/auth/getUpdateUsuarioOper';
    url0020: any = environment.path_prod+'/api/auth/getPedidoCliente';
    url0021: any = environment.path_prod+'/api/auth/getTdcUsuarioActiva';
    url0022: any = environment.path_prod+'/api/auth/getFacturarPedido';
    url0023: any = environment.path_prod+'/api/auth/getProductoPedido';
    url0024: any = environment.path_prod+'/api/auth/getUpdateUsuarioVisit';
    url0025: any = environment.path_prod+'/api/auth/getDefinicionSitema';
    url0026: any = environment.path_prod+'/api/auth/getActualizarMembresia';
    url0027: any = environment.path_prod+'/api/auth/getQuitarElementosPedido';
    url0028: any = environment.path_prod+'/api/auth/getPagosPedido';
    url0029: any = environment.path_prod+'/api/auth/getDepDlr';
    url0030: any = environment.path_prod+'/api/auth/getTomarDeposito';
    url0031: any = environment.path_prod+'/api/auth/getDepDlrTomado';
    url0032: any = environment.path_prod+'/api/auth/getProductoOperador';
    url0033: any = environment.path_prod+'/api/auth/getProductoPrecioOperador';
    url0034: any = environment.path_prod+'/api/auth/getImgProducto';
    url0035: any = environment.path_prod+'/api/auth/getDepositoProducto';
    url0036: any = environment.path_prod+'/api/auth/getproductosSeller';
    url0037: any = environment.path_prod+'/api/auth/getOperadorVendedor';
    url0038: any = environment.path_prod+'/api/auth/getDepositoProdDlrBasic';
    url0039: any = environment.path_prod+'/api/auth/getOfertaProducto';
    url0040: any = environment.path_prod+'/api/auth/getProcessarOferta';
    url0041: any = environment.path_prod+'/api/auth/getProductoActivoHome';
    url0042: any = environment.path_prod+'/api/auth/getPedidoClienteOperador';
    url0043: any = environment.path_prod+'/api/auth/getCheckoutProducto';
    url0044: any = environment.path_prod+'/api/auth/getOrdenesProducto';
    url0045: any = environment.path_prod+'/api/auth/getdeliveryProducto';
    url0046: any = environment.path_prod+'/api/auth/getDelivery';
    url0047: any = environment.path_prod+'/api/auth/getDeliveryOperador';
    url0048: any = environment.path_prod+'/api/auth/getReceivedProducto';
    url0049: any = environment.path_prod+'/api/auth/getBilleteraVendedor';
    url0050: any = environment.path_prod+'/api/auth/getDeliveryCliente';
    url0051: any = environment.path_prod+'/api/auth/getProductosOrdenActiva';
    url0052: any = environment.path_prod+'/api/auth/getDataUsuario';
    url0053: any = environment.path_prod+'/api/auth/getUsuarioSwiperSeller';
    url0054: any = environment.path_prod+'/api/auth/getPrdrIdcloud';
    url0055: any = environment.path_prod+'/api/auth/getDeseosUsuario';
    url0056: any = environment.path_prod+'/api/auth/getQuitarDeseo';
    url0057: any = environment.path_prod+'/api/auth/getDeseosActivos';
    url0058: any = environment.path_prod+'/api/auth/getUpdateIdcloud';
    url0059: any = environment.path_prod+'/api/auth/getQuitarFavoritos';
    url0060: any = environment.path_prod+'/api/auth/getUsuarioContactoVs';
    url0061: any = environment.path_prod+'/api/auth/getMsmUsuario';
    url0062: any = environment.path_prod+'/api/auth/getCategoriaUsuario';
    url0063: any = environment.path_prod+'/api/auth/getMsmUsuarioCont';
    url0064: any = environment.path_prod+'/api/auth/getImgProductoGaleria';
    url0065: any = environment.path_prod+'/api/auth/getGaleria';
    url0066: any = environment.path_prod+'/api/auth/getQuitarGaleria';
    url0067: any = environment.path_prod+'/api/auth/getQuitarProducto';
    url0068: any = environment.path_prod+'/api/auth/getImgPerfilGaleria';
    url0069: any = environment.path_prod+'/api/auth/getGaleriaPerfil';
    url0070: any = environment.path_prod+'/api/auth/getQuitarGaleriaPerfil';
    url0071: any = environment.path_prod+'/api/auth/getImgProductoVidGaleria';
    url0072: any = environment.path_prod+'/api/auth/getVidGaleria';
    url0073: any = environment.path_prod+'/api/auth/getCodStorage';
    url0074: any = environment.path_prod+'/api/auth/getQuitarGaleriaVid';
    url0075: any = environment.path_prod+'/api/auth/getVidGaleriaPerfil';
    url0076: any = environment.path_prod+'/api/auth/getImgProductoVidGaleriaPerfil';
    url0077: any = environment.path_prod+'/api/auth/getQuitarGaleriaVidPerfil';
    url0078: any = environment.path_prod+'/api/auth/getVisitasUsuarios';
    url0079: any = environment.path_prod+'/api/auth/getCorreoValido';
    url0080: any = environment.path_prod+'/api/auth/getCodEnv';
    url0081: any = environment.path_prod+'/api/auth/getCodEnvPass';
    url0082: any = environment.path_prod+'/api/auth/getCodEnvPassUpdate';
    url0083: any = environment.path_prod+'/api/auth/getBloqueoVendedor';
    url0084: any = environment.path_prod+'/api/auth/getListaNegraUsuarioVendedor';
    url0085: any = environment.path_prod+'/api/auth/getReportarVendedor';
    url0086: any = environment.path_prod+'/api/auth/getUsuarioVendedorBloqueado';
    url0087: any = environment.path_prod+'/api/auth/getRechazarProductoModerador';
    url0088: any = environment.path_prod+'/api/auth/getQuitarDislike';
    url0089: any = environment.path_prod+'/api/auth/getProdBuscar';
    url0090: any = environment.path_prod+'/api/auth/updateUserPass';
    url0091: any = environment.path_prod+'/api/auth/sendContactForm';
    url0092: any = environment.path_prod+'/api/auth/getNewSellers';
    url0093: any = environment.path_prod+'/api/auth/getProductsxSeller';
    url0094: any = environment.path_prod+'/api/auth/getBuyers';
    url0095: any = environment.path_prod+'/api/auth/login';
      constructor(
        private postrest:HttpClient,
        private db:AngularFireStorage,
        private st:Storage,
        ) {}

      loginhttp(xvalor:any):Observable<any>{
        const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
            this.ruta = this.postrest.post<any>(this.url0001, xvalor,{headers});
            return this.ruta;
        }
      crearusuariohttp(xvalor:any):Observable<any>{
        const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          });
          this.ruta = this.postrest.post<any>(this.url0002, xvalor,{headers});
          return this.ruta;
      }
      login_memberhttp(xvalor:any):Observable<any>{
        const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
        this.ruta = this.postrest.post<any>(this.url0003, xvalor,{headers});
        return this.ruta;
         }



      imgPerfilhttp(xvalor:any):Observable<any>{
        const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
          this.ruta = this.postrest.post<any>(this.url0004, xvalor,{headers});
          return this.ruta;
        }

      imgProdhttp(xvalor:any):Observable<any>{
          const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
          this.ruta = this.postrest.post<any>(this.url0005, xvalor,{headers});
          return this.ruta;
        }

        getDepositoProdhttp(xvalor:any):Observable<any>{
          const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
          this.ruta = this.postrest.post<any>(this.url0006, xvalor,{headers});
          return this.ruta;
        }

        getProdhttp(xvalor:any):Observable<any>{
          const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
          this.ruta = this.postrest.post<any>(this.url0007,xvalor,{headers});
          return this.ruta;
        }

        getImgDLRttp(xvalor:any):Observable<any>{
          const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
          this.ruta = this.postrest.post<any>(this.url0008,xvalor,{headers});
          return this.ruta;
        }

        getUsuarioParametrottp(xvalor:any):Observable<any>{
          const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
          this.ruta = this.postrest.post<any>(this.url0009,xvalor,{headers});
          return this.ruta;
        }

        getDepositoProdDlrhttp(xvalor:any):Observable<any>{
          const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
          this.ruta = this.postrest.post<any>(this.url0010,xvalor,{headers});
          return this.ruta;
        }

        getUsuarioParametroIdCloudhttp(xvalor:any):Observable<any>{
          const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
          this.ruta = this.postrest.post<any>(this.url0011,xvalor,{headers});
          return this.ruta;
        }

        getUsuarioSwiperhttp():Observable<any>{
          const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
          this.ruta = this.postrest.post<any>(this.url0012,'',{headers});
          return this.ruta;
        }
        getEventosLikeDislikehttp(xvalor:any):Observable<any>{
          const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
          this.ruta = this.postrest.post<any>(this.url0013, xvalor, {headers});
          return this.ruta;
        }
        getFavoritoshttp(xvalor:any):Observable<any>{
          const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
          this.ruta = this.postrest.post<any>(this.url0014, xvalor,{headers});
          return this.ruta;
        }

        getUsuarioChathttp(xvalor:any):Observable<any>{
          const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
          this.ruta = this.postrest.post<any>(this.url0015, xvalor,{headers});
          return this.ruta;
        }

        async UpdateUsuariohttp(xvalor:any){
          const access_token = localStorage.getItem('acccess_token');

          var resp;

          await axios({
            method: "post",
            url: this.url0016,
            data: xvalor,
            headers: { 
            'X-Requested-With' : 'XMLHttpRequest', 
            'Acept' : 'multipart/form-data',
            'Authorization' : access_token },
          })
            .then(function (response) {
              //handle success
              console.log ('Respuesta de Axios',response);
              resp = response;
            })
            .catch(function (response) {
              //handle error
              console.log(response);
            });

            return resp;

    
        }

        getUpdateUsuariohttp(xvalor:any):Observable<any>{
          const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          /* 'Content-Type' : 'false', */ 
          'Content-Type' : 'multipart/form-data; boundary=' + Math.random().toString(),
          'X-Requested-With' : 'XMLHttpRequest', 
          'Acept' : 'multipart/form-data',
          'Authorization' : access_token });

          this.ruta = this.postrest.post<any>(this.url0016, xvalor, {headers});
          return this.ruta;
        }

        getPedidoCarritohttp(xvalor:any):Observable<any>{
          const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
          this.ruta = this.postrest.post<any>(this.url0017, xvalor,{headers});
          return this.ruta;
        }
        getPedidoCarritoActivohttp(xvalor:any):Observable<any>{
          const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });;
          this.ruta = this.postrest.post<any>(this.url0018, xvalor,{headers});
          return this.ruta;
        }
        getUpdateUsuarioOperhttp(xvalor:any):Observable<any>{
          const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
          this.ruta = this.postrest.post<any>(this.url0019, xvalor,{headers});
          return this.ruta;
      }
      getPedidoClientehttp(xvalor:any):Observable<any>{
        const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
        this.ruta = this.postrest.post<any>(this.url0020, xvalor,{headers});
        return this.ruta;
      }
      getTdcUsuarioActivahttp(xvalor:any):Observable<any>{
        const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
        this.ruta = this.postrest.post<any>(this.url0021, xvalor,{headers});
        return this.ruta;
      }
      getFacturarPedidohttp(xvalor:any):Observable<any>{
          const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
          this.ruta = this.postrest.post<any>(this.url0022, xvalor,{headers});
          return this.ruta;
      }
      getProductoPedidohttp(xvalor:any):Observable<any>{
          const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
          this.ruta = this.postrest.post<any>(this.url0023, xvalor,{headers});
          return this.ruta;
      }
      getUpdateUsuarioVisithttp(xvalor:any):Observable<any>{
          const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
          this.ruta = this.postrest.post<any>(this.url0024, xvalor,{headers});
          return this.ruta;
      }
      getDefinicionSitemathttp(xvalor:any):Observable<any>{
        const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
        this.ruta = this.postrest.post<any>(this.url0025, xvalor,{headers});
        return this.ruta;
      }
      getActualizarMembresiahttp(xvalor:any):Observable<any>{
        const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
        this.ruta = this.postrest.post<any>(this.url0026, xvalor,{headers});
        return this.ruta;
      }
      getQuitarElementosPedidohttp(xvalor:any):Observable<any>{
        const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
        this.ruta = this.postrest.post<any>(this.url0027, xvalor,{headers});
        return this.ruta;
      }
      getPagosPedidohttp(xvalor:any):Observable<any>{
        const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
        this.ruta = this.postrest.post<any>(this.url0028, xvalor,{headers});
        return this.ruta;
      }
      getDepDlrhttp():Observable<any>{
        const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
        this.ruta = this.postrest.post<any>(this.url0029,'',{headers});
        return this.ruta;
      }
      getNewSellershttp():Observable<any>{
        const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
        this.ruta = this.postrest.post<any>(this.url0092,'',{headers});
        return this.ruta;
      }
      getTomarDepositohttp(xvalor:any):Observable<any>{
        const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
        this.ruta = this.postrest.post<any>(this.url0030, xvalor,{headers});
        return this.ruta;
      }

      getDepDlrTomadohttp(xvalor:any):Observable<any>{
        const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
        this.ruta = this.postrest.post<any>(this.url0031, xvalor,{headers});
        return this.ruta;
      }
      getProductoOperadorhttp(xvalor:any):Observable<any>{
        const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
        this.ruta = this.postrest.post<any>(this.url0032, xvalor,{headers});
        return this.ruta;
      }
      getProductoPrecioOperadorhttp(xvalor:any):Observable<any>{
        const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
        this.ruta = this.postrest.post<any>(this.url0033, xvalor,{headers});
        return this.ruta;
      }

      async ImgProudctohttp(xvalor:any){
        const access_token = localStorage.getItem('acccess_token');

        var resp;

        await axios({
          method: "post",
          url: this.url0034,
          data: xvalor,
          headers: { 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Acept' : 'multipart/form-data',
          'Authorization' : access_token },
        })
          .then(function (response) {
            //handle success
            console.log ('Respuesta de Axios',response);
            resp = response;
          })
          .catch(function (response) {
            //handle error
            console.log(response);
          });

          return resp;


      }

      async UpdateImgProductohttp(xvalor:any){
        const access_token = localStorage.getItem('acccess_token');
        var resp;

        await axios({
          method: "post",
          url: this.url0034,
          data: xvalor,
          headers: { 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Acept' : 'multipart/form-data',
          'Authorization' : access_token },
        })
          .then(function (response) {
            //handle success
            console.log ('Respuesta de Axios',response);
            resp = response;
          })
          .catch(function (response) {
            //handle error
            console.log(response);
          });

          return resp;
      }

      getImgProductohttp(xvalor:any):Observable<any>{
        const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
        this.ruta = this.postrest.post<any>(this.url0034, xvalor,{headers});
        return this.ruta;
      }
      
      getDepositoProductohttp(xvalor:any):Observable<any>{
        const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
        this.ruta = this.postrest.post<any>(this.url0035, xvalor,{headers});
        return this.ruta;
      }
      getproductosSellerhttp(xvalor:any):Observable<any>{
        const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
        this.ruta = this.postrest.post<any>(this.url0036, xvalor,{headers});
        return this.ruta;
      }
      getOperadorVendedorhttp(xvalor:any):Observable<any>{
        const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
        this.ruta = this.postrest.post<any>(this.url0037,xvalor,{headers});
        return this.ruta;
      }
      getDepositoProdDlrBasichttp(xvalor:any):Observable<any>{
        const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
        this.ruta = this.postrest.post<any>(this.url0038,xvalor,{headers});
        return this.ruta;
      }
      getOfertaProductohttp(xvalor:any):Observable<any>{
        const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
        this.ruta = this.postrest.post<any>(this.url0039,xvalor,{headers});
        return this.ruta;
      }
      getProcessarOfertahttp(xvalor:any):Observable<any>{
        const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
        this.ruta = this.postrest.post<any>(this.url0040,xvalor,{headers});
        return this.ruta;
      }
      
      getProductoActivoHomehttp(xvalor:any):Observable<any>{
        const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
        this.ruta = this.postrest.post<any>(this.url0041,xvalor,{headers});
        return this.ruta;
      }
      getPedidoClienteOperadorhttp(xvalor:any):Observable<any>{
        const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
        this.ruta = this.postrest.post<any>(this.url0042, xvalor,{headers});
        return this.ruta;
      }
      getCheckoutProductohttp(xvalor:any):Observable<any>{
        const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
        this.ruta = this.postrest.post<any>(this.url0043, xvalor,{headers});
        return this.ruta;
      }
      getOrdenesProductohttp(xvalor:any):Observable<any>{
        const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
        this.ruta = this.postrest.post<any>(this.url0044, xvalor,{headers});
        return this.ruta;
      }
      getdeliveryProductohttp(xvalor:any):Observable<any>{
        const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
        this.ruta = this.postrest.post<any>(this.url0045, xvalor,{headers});
        return this.ruta;
      }
      getDeliveryhttp(xvalor:any):Observable<any>{
        const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
        this.ruta = this.postrest.post<any>(this.url0046, xvalor,{headers});
        return this.ruta;
      }
      getDeliveryOperadorhttp(xvalor:any):Observable<any>{
        const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
        this.ruta = this.postrest.post<any>(this.url0047, xvalor,{headers});
        return this.ruta;
      }
      getReceivedProductohttp(xvalor:any):Observable<any>{
        const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
        this.ruta = this.postrest.post<any>(this.url0048, xvalor,{headers});
        return this.ruta;
      }
      getBilleteraVendedorhttp(xvalor:any):Observable<any>{
        const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
        this.ruta = this.postrest.post<any>(this.url0049, xvalor,{headers});
        return this.ruta;
      }
      getDeliveryClientehttp(xvalor:any):Observable<any>{
        const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
        this.ruta = this.postrest.post<any>(this.url0050, xvalor,{headers});
        return this.ruta;
      }
      getProductosOrdenActivahttp(xvalor:any):Observable<any>{
        const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
        this.ruta = this.postrest.post<any>(this.url0051, xvalor,{headers});
        return this.ruta;
      }
      getDataUsuariohttp(xvalor:any):Observable<any>{
        const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
        this.ruta = this.postrest.post<any>(this.url0052, xvalor,{headers});
        return this.ruta;
      }
      getUsuarioSwiperSellerhttp():Observable<any>{
        const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
        this.ruta = this.postrest.post<any>(this.url0053, '',{headers});
        return this.ruta;
      }
      getPrdrIdcloudhttp(xvalor:any):Observable<any>{
        const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
        this.ruta = this.postrest.post<any>(this.url0054,xvalor,  {headers});
        return this.ruta;
      }

      getDeseosUsuariohttp(xvalor:any):Observable<any>{
        const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
        this.ruta = this.postrest.post<any>(this.url0055,xvalor,  {headers});
        return this.ruta;
      }

      getQuitarDeseohttp(xvalor:any):Observable<any>{
        const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
        this.ruta = this.postrest.post<any>(this.url0056,xvalor,  {headers});
        return this.ruta;
      }

      getDeseosActivoshttp(xvalor:any):Observable<any>{
        const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
        this.ruta = this.postrest.post<any>(this.url0057,xvalor,  {headers});
        return this.ruta;
      }
      getUpdateIdcloudhttp(xvalor:any):Observable<any>{
        const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
        this.ruta = this.postrest.post<any>(this.url0058, xvalor,{headers});
        return this.ruta;
     }
      getQuitarFavoritoshttp(xvalor:any):Observable<any>{
        const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
        this.ruta = this.postrest.post<any>(this.url0059, xvalor,{headers});
        return this.ruta;
     }
        getUsuarioContactoVshttp(xvalor:any):Observable<any>{
        const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
        this.ruta = this.postrest.post<any>(this.url0060, xvalor,{headers});
        return this.ruta;
     }
     getMsmUsuariohttp(xvalor:any):Observable<any>{
        const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
        this.ruta = this.postrest.post<any>(this.url0061, xvalor,{headers});
        return this.ruta;
     }
     getCategoriaUsuariohttp(xvalor:any):Observable<any>{
        const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
        this.ruta = this.postrest.post<any>(this.url0062, xvalor,{headers});
        return this.ruta;
     }
     getMsmUsuarioConthttp(xvalor:any):Observable<any>{
       const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
        this.ruta = this.postrest.post<any>(this.url0063, xvalor,{headers});
        return this.ruta;
     }
     
     async ImgProductoGaleriahttp(xvalor:any){
      const access_token = localStorage.getItem('acccess_token');
      var resp;

        await axios({
          method: "post",
          url: this.url0064,
          data: xvalor,
          headers: { 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Acept' : 'multipart/form-data',
          'Authorization' : access_token },
        })
          .then(function (response) {
            //handle success
            console.log ('Respuesta de Axios',response);
            resp = response;
          })
          .catch(function (response) {
            //handle error
            console.log(response);
          });

          return resp;
/*       this.ruta = this.postrest.post<any>(this.url0064, xvalor,{headers});
      return this.ruta; */
    }

     getImgProductoGaleriahttp(xvalor:any):Observable<any>{
      const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
      this.ruta = this.postrest.post<any>(this.url0064, xvalor,{headers});
      return this.ruta;
    }

    getGaleriahttp(xvalor:any):Observable<any>{
      const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
      this.ruta = this.postrest.post<any>(this.url0065, xvalor,{headers});
      return this.ruta;
    }
    getQuitarGaleriahttp(xvalor:any):Observable<any>{
      const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
      this.ruta = this.postrest.post<any>(this.url0066, xvalor,{headers});
      return this.ruta;
    }
    getQuitarProductohttp(xvalor:any):Observable<any>{
      const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
      this.ruta = this.postrest.post<any>(this.url0067, xvalor,{headers});
      return this.ruta;
    }

    async UploadPerfilGaleriahttp(xvalor:any){
      const access_token = localStorage.getItem('acccess_token');

      var resp;

      await axios({
        method: "post",
        url: this.url0068,
        data: xvalor,
        headers: { 
        'X-Requested-With' : 'XMLHttpRequest', 
        'Acept' : 'multipart/form-data',
        'Authorization' : access_token },
      })
        .then(function (response) {
          //handle success
          console.log ('Respuesta de Axios',response);
          resp = response;
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });

        return resp;


        
        

    /*   this.ruta = this.postrest.post<any>(this.url0068, xvalor,{headers});
      return this.ruta; */
    }

    getImgPerfilGaleriahttp(xvalor:any):Observable<any>{
      const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });;
      this.ruta = this.postrest.post<any>(this.url0068, xvalor,{headers});
      return this.ruta;
    }


    getGaleriaPerfilhttp(xvalor:any):Observable<any>{
      const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
      this.ruta = this.postrest.post<any>(this.url0069, xvalor,{headers});
      return this.ruta;
    }
    getQuitarGaleriaPerfilhttp(xvalor:any):Observable<any>{
      const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
      this.ruta = this.postrest.post<any>(this.url0070, xvalor,{headers});
      return this.ruta;
    }
    getImgProductoVidGaleriahttp(xvalor:any):Observable<any>{
      const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
      this.ruta = this.postrest.post<any>(this.url0071, xvalor,{headers});
      return this.ruta;
    }
    getVidGaleriahttp(xvalor:any):Observable<any>{
      const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
      this.ruta = this.postrest.post<any>(this.url0072, xvalor,{headers});
      return this.ruta;
    }
    getCodStoragehttp(xvalor:any):Observable<any>{
      const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
      this.ruta = this.postrest.post<any>(this.url0073, xvalor,{headers});
      return this.ruta;
    }
    getQuitarGaleriaVidhttp(xvalor:any):Observable<any>{
      const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
      this.ruta = this.postrest.post<any>(this.url0074, xvalor,{headers});
      return this.ruta;
    }
    getVidGaleriaPerfilhttp(xvalor:any):Observable<any>{
      const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
      this.ruta = this.postrest.post<any>(this.url0075, xvalor,{headers});
      return this.ruta;
    }
    getImgProductoVidGaleriaPerfilhttp(xvalor:any):Observable<any>{
      const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
      this.ruta = this.postrest.post<any>(this.url0076, xvalor,{headers});
      return this.ruta;
    }
    getQuitarGaleriaVidPerfilhttp(xvalor:any):Observable<any>{
      const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
      this.ruta = this.postrest.post<any>(this.url0077, xvalor,{headers});
      return this.ruta;
    }
    getVisitasUsuarioshttp():Observable<any>{
      const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
      this.ruta = this.postrest.post<any>(this.url0078,'',{headers});
      return this.ruta;
    }
    getCorreoValidohttp(xvalor:any):Observable<any>{
      const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          });
      this.ruta = this.postrest.post<any>(this.url0079, xvalor,{headers});
      return this.ruta;
    }
    getCodEnvhttp(xvalor:any):Observable<any>{
      const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
      this.ruta = this.postrest.post<any>(this.url0080, xvalor,{headers});
      return this.ruta;
    }
    getCodEnvPasshttp(xvalor:any):Observable<any>{
      const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
      });
      this.ruta = this.postrest.post<any>(this.url0081, xvalor,{headers});
      return this.ruta;
    }
    getCodEnvPassUpdatehttp(xvalor:any):Observable<any>{
      const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
      });
      this.ruta = this.postrest.post<any>(this.url0082, xvalor,{headers});
      return this.ruta;
    }
    getBloqueoVendedorhttp(xvalor:any):Observable<any>{
      const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
      this.ruta = this.postrest.post<any>(this.url0083, xvalor,{headers});
      return this.ruta;
    }
    getListaNegraUsuarioVendedorhttp(xvalor:any):Observable<any>{
      const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
      this.ruta = this.postrest.post<any>(this.url0084, xvalor,{headers});
      return this.ruta;
    }
    getReportarVendedorhttp(xvalor:any):Observable<any>{
      const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
      this.ruta = this.postrest.post<any>(this.url0085, xvalor,{headers});
      return this.ruta;
    }
    getUsuarioVendedorBloqueadohttp(xvalor:any):Observable<any>{
      const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
      this.ruta = this.postrest.post<any>(this.url0086, xvalor,{headers});
      return this.ruta;
    }
    getRechazarProductoModeradorhttp(xvalor:any):Observable<any>{
      const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
      this.ruta = this.postrest.post<any>(this.url0087, xvalor,{headers});
      return this.ruta;
    }
    getQuitarDislikehttp(xvalor:any):Observable<any>{
      const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
      this.ruta = this.postrest.post<any>(this.url0088, xvalor, {headers});
      return this.ruta;
    }
    getProdBuscarttp(xvalor:any):Observable<any>{
      const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
      this.ruta = this.postrest.post<any>(this.url0089, xvalor, {headers});
      return this.ruta;
    }
    updateUserPass(xvalor:any):Observable<any>{
      const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
      this.ruta = this.postrest.post<any>(this.url0090, xvalor, {headers});
      return this.ruta;
    }
    sendContactForm(xvalor:any):Observable<any>{
      const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
      this.ruta = this.postrest.post<any>(this.url0091, xvalor, {headers});
      return this.ruta;
    }
    getProductsxSellerhttp(xvalor:any):Observable<any>{
      const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
      this.ruta = this.postrest.post<any>(this.url0093, xvalor,{headers});
      return this.ruta;
    }
    getBuyershttp():Observable<any>{
      const access_token = localStorage.getItem('acccess_token');
        let headers = new HttpHeaders({
          'Content-Type' : 'application/json', 
          'X-Requested-With' : 'XMLHttpRequest', 
          'Authorization' : access_token });
      this.ruta = this.postrest.post<any>(this.url0094,'',{headers});
      return this.ruta;
    }

    loginapihttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url0095, xvalor,{headers});
      return this.ruta;
       }
}
