import { Component, OnInit } from '@angular/core';
import { MenuController, ModalController } from '@ionic/angular';
import { AuthGuardServicesService } from 'src/app/services/auth-guard-services.service';
import { StoreService } from 'src/app/services/store.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UiKitController } from 'src/app/services/uikitcontroller.service';
import { FormPedidosComponent } from 'src/app/componentes/shared/modal/form-pedidos/form-pedidos.component';
import { skServices } from 'src/app/services/sk.services.service';
import { PdfService } from 'src/app/services/pdf.service';
import { FormTdcComponent } from 'src/app/componentes/shared/modal/form-tdc/form-tdc.component';
import { RouterService } from 'src/app/services/router.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { FormMsgComponent } from '../shared/modal/form-msg/form-msg.component';
import { OfertaService } from 'src/app/services/oferta.service';
import { Productos, Usuario } from 'src/models';
import { FirechatService } from 'src/app/services/firechat.service';

@Component({
  selector: 'app-deseos-usuario',
  templateUrl: './deseos-usuario.component.html',
  styleUrls: ['./deseos-usuario.component.scss'],
})
export class DeseosUsuarioComponent implements OnInit {

  PedidoEnviado: Subscription;
  PedidoEntrgado: Subscription;
  pedido: any= [];
  Pedidos2: any[] = [];
  id: any;
  orden: any;
  total_precio_pedido: any;
  fecha:any = new Date().getFullYear();
  xfecha = this.fecha;
  category: any = localStorage.getItem('usuario_categoria');

 
  currentPage = 1;
  pageOfItems: Array<any>;

  cliente: Usuario = {
    tipo:'',
    tipoUsuario:'',
    userId: '',
    email: '',
    displayName: '',
    nombre: '',
    apellido: '',
    genero: '',
    fecha_nacimiento: '',
    edad: '',
    descripcion: '',
    photoURL: '',
    telefono: '',
    direccion: {
      pais: '',
      dir_str: '',
      location: {
        lat: '',
        long: '',
      },
    },
    productos:[]

  };
  producto: Productos = {
    src: '',
    alt: '',
    Id: '',
    user_id: '',
    user_name: '',
    title: '',
    cant_producto: 0,
    price: '',
    descripcion: '',
  }
  productoRight: Productos = {
    src: '',
    alt: '',
    Id: '',
    user_id: '',
    user_name: '',
    title: '',
    cant_producto: 0,
    price: '',
    descripcion: '',
  };
  lg:any = localStorage.getItem('lg');
  data_filtro: any;
  constructor(
    private menucontroler: MenuController,
    private firestoreService: StoreService,
    private firebaseauthService: AuthGuardServicesService,
    private route: ActivatedRoute,
    private util: UiKitController,
    public modalController: ModalController,
    private skservice: skServices,
    private router: Router,
    private pdf: PdfService,
    private route_app: RouterService,
    private afs: AngularFirestore,
    private ofertaSV: OfertaService,
    private ChatSv: FirechatService,
  ) {}

  ngOnInit() {
   
  }
  ionViewDidEnter() {
    this.getDeseosActivos();
   }
   onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

async getDeseosActivos(){
  try {
    this.pedido = [];
    await this.util.loading();
    var xvalor = {
      cod_usuario:localStorage.getItem('cod_usuario')
    }
    var bandera = false;
    const response = await this.skservice.getDeseosActivoshttp(xvalor).toPromise();
    this.pedido = [...response.deseos];
    this.data_filtro = [...response.deseos];
    console.log(this.pedido);
      await this.util.ToastSuccess(response.message);
    
  } catch (error) {
    this.total_precio_pedido = 0;
    this.orden = '';
    console.log(error);
   await this.util.ToastWarning(error.error.message);
  }
}

  async modalPedidosCarrito(){
		try {
      var xvalor = {
        total_precio_pedido: this.total_precio_pedido,
        cod_pedido_carrito: this.orden
      }
		  await this.util.loading();
		  const modal = await this.modalController.create({
			component: FormTdcComponent,
			cssClass: 'form-pedidos',
			componentProps: { data:xvalor},
			backdropDismiss: false
		  });
		  
		  await modal.present();
		  const { data } = await modal.onWillDismiss();
		  this.getDeseosActivos();
		  return modal;
		} catch (error) {
		  console.log(error);
			await this.util.ToastWarning(error.error.message);
		}
		
	  }
    async tienda_app(url: any) {
      try {
        await this.util.loading();
        this.router.navigate([url]);
        
      } catch (error) {
        console.log(error);
      }
    }
    async invoiced(xvalor: any) {
      try {
        await this.util.loading();
        await this.pdf.pdfVentas(xvalor);
        
      } catch (error) {
        console.log(error);
      }
    }

    async getQuitarDeseo(xval:any){
      try {
        console.log(xval);
        await this.util.loading();
        const response = await this.skservice.getQuitarDeseohttp(xval).toPromise();
        this.afs
                  .collection('deseos_usuarios')
                  .doc(localStorage.getItem('cod_usuario'))
                  .collection('deseo')
                  .doc(xval.cod_producto)
                  .set({
                    estatus:'I',
                    fecha: firebase.firestore.FieldValue.serverTimestamp(),
                    
                  });
        console.log(response);
          await this.util.ToastSuccess(response.message);
          await  this.getDeseosActivos();
      } catch (error) {
        console.log(error);
        await this.util.ToastWarning(error.error.message);
      }
    }

    async AgregarAlCarrito(xval:any) {
      /* this.carritoService.AgregarProducto(this.producto); */
      try {
        await this.util.loading();
        console.log(xval);
            var xvalor = {
              cant_producto: '1',
              cod_producto: xval.cod_producto,
              des_producto: xval.des_producto,
              precio_producto: xval.precio_producto,
              um_producto: xval.um_producto,
              cod_deposito: xval.cod_deposito,
              cod_img: xval.cod_img,
              cod_cliente: localStorage.getItem('cod_usuario'),
              cod_vendedor: xval.cod_vendedor,
              cat_producto: xval.cat_producto,
              tipo: xval.tipo_producto_cat,
              estatus: xval.estatus,
              total_precio_pedido:  parseFloat(xval.precio_producto),
              total_precio_producto: parseFloat(xval.precio_producto),
            }
            
            const response =  await this.skservice.getPedidoCarritohttp(xvalor).toPromise();
            console.log(response);
            this.afs
                    .collection('pedidos_usuarios')
                    .doc(localStorage.getItem('cod_usuario'))
                    .collection('pedido')
                    .doc(response.pedido[0].cod_pedido_carrito)
                    .set({
                      estatus:'A',
                      fecha: firebase.firestore.FieldValue.serverTimestamp(),
                      
                    });
          for (const i in response.pedido) {
            this.afs
                    .collection('pedidos_usuarios')
                    .doc(localStorage.getItem('cod_usuario'))
                    .collection('pedido')
                    .doc(response.pedido[i].cod_pedido_carrito)
                    .collection('productos')
                    .doc(response.pedido[i].cod_producto)
                    .set({
                      des_producto: response.pedido[i].des_producto,
                      fecha: firebase.firestore.FieldValue.serverTimestamp(),
                      
                    });
          }
            
            this.util.ToastSuccess(response.message);
            this.route_app.rutas_app('carrito');
            
          this.getDeseosActivos();
     
      } catch (error) {
        
        console.log(error);
        this.util.ToastWarning(error.error.message);
      }
    }
  
    async openOfertar(){
      try {
        await this.util.loading();
          
            await this.util.loading();
        const modal = await this.modalController.create({
          component: FormMsgComponent,
          cssClass: 'form-precio',
          backdropDismiss: false,
          componentProps: {
            data_prod:this.pedido
          },
        });
        
        await modal.present();
        const { data } = await modal.onDidDismiss()
        console.log(data)
        if(data !== '' && data !== null && data !== undefined){
          console.log(data);
          await this.util.loading();
          const oferta = await this.ofertaSV.crearOferta(this.pedido, data.monto);
          var xobjeto = {
            cod_oferta:oferta.ofertaId,
            cod_usuario:localStorage.getItem('cod_usuario'),
            cod_prdr:this.pedido.cod_usuario,
            cod_producto:this.pedido.cod_producto,
            des_producto:this.pedido.des_producto,
            um_producto_oferta:oferta.precio,
            cod_deposito:this.pedido.cod_deposito,
            cod_img:this.pedido.cod_img,
            cant_producto:this.pedido.cant_producto,
            um_producto_operador:this.pedido.um_producto_operador,
            cat_producto:this.pedido.cat_producto,
            tipo_producto_cat:this.pedido.tipo_producto_cat,
  
          };
          const response = await this.skservice.getOfertaProductohttp(xobjeto).toPromise();
          console.log(oferta);
          this.cliente.userId = this.pedido.id_cloud;
          this.cliente.displayName = this.pedido.nom_usuario;
          this.cliente.photoURL = this.pedido.cod_img;
          this.cliente.apellido = this.pedido.ape_usuario;
          this.cliente.descripcion = this.pedido.descrip_usuario;
          this.cliente.tipo = this.pedido.tipo;
          this.cliente.tipoUsuario = this.pedido.tipo;
          await this.ChatSv.sendMessageOferta(this.cliente, data.mensaje,'offline', oferta)
          
          this.modalController.dismiss();
          this.util.ToastSuccess(response.message);
          this.router.navigate(['/chat']);
        }
        
      } catch (error) {
        
        this.util.ToastError(error.error.message);
        console.log(error)
      }
    }

    async handleChange(event, termino:any) {
      await this.util.cargando();
      console.log(termino);
      if (termino === 'productos') {
        const query = event.target.value.toLowerCase();
        console.log(query);
        this.data_filtro = this.pedido.filter(d => 
          d.um_producto.toLowerCase().indexOf(query) > -1  ||
          d.des_producto.toLowerCase().indexOf(query) > -1);
        
        console.log(this.data_filtro.length);
        await this.util.closeLoading();
        if (this.data_filtro.length <= 0) {
          await this.util.ToastWarning('Without results');
        }else{
          await this.util.ToastSuccess(this.data_filtro.length+' Resultados encontrados');
        }
      }

      if (termino === '' || termino === undefined || termino === null) {
      await this.util.closeLoading();
      if (this.data_filtro.length <= 0) {
        await this.util.ToastWarning('Without results');
      }else{
        await this.util.ToastSuccess(this.data_filtro.length+' Results found');
      }
      }
    }
}
