import { Component, OnInit, Inject, Input, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertController, LoadingController, MenuController, ModalController } from '@ionic/angular';
import { RxwebValidators, async } from '@rxweb/reactive-form-validators';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { AuthGuardServicesService } from 'src/app/services/auth-guard-services.service';
import { CarritoService } from 'src/app/services/carrito.service';
import { skServices } from 'src/app/services/sk.services.service';
import { StatusController } from 'src/app/services/statuscontroller.service';
import { StoreService } from 'src/app/services/store.service';
import { UiKitController } from 'src/app/services/uikitcontroller.service';
import { environment } from 'src/environments/environment';
import { FormMsgComponent } from '../form-msg/form-msg.component';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { Auth, createUserWithEmailAndPassword, getAuth, signInWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import { Usuario } from 'src/models';
import { BehaviorSubject } from 'rxjs';


@Component({
  selector: 'app-form-registro-miembro',
  templateUrl: './form-registro-miembro.component.html',
  styleUrls: ['./form-registro-miembro.component.scss'],
})
export class FormRegistroMiembroComponent implements OnInit {
  @ViewChild('passwordEyeRegister, { read: ElementRef }') passwordEye: ElementRef;
  @ViewChild('passwordEyeRegisterR, { read: ElementRef }') passwordEyeR: ElementRef;
  @Input() data: string;
  passwordTypeInput  =  'password';
  passwordTypeInputR  =  'password';
  emailRegex:any = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  telRegex:any = /\d{4}-\d{6}\d/;
  Regex:any = /^[J,V]{1}\d{9}/;
  hide:any = true;
nombre:any;
select:any;
exist:any = false;
msg:any;
forma:FormGroup;
forma2:FormGroup;
forma3:FormGroup;
forma52:FormGroup;
dialogSpin:any;
accion:any;
unidad:any;
listas:any = [];
tipo_cliente = [];
deposito_pt = [];
almacen:any = [];
almacen_cdn:any = [];
almacen_dstrbdr:any = [];
almacen_pvp:any = [];
tipo_um_und = [];
tipo_um_und_consig = [];
asociar:any = 'N/A';
actualizar:any = false;
lg:any = localStorage.getItem('lg');
login_boton:any = false;
login_:any = false;
db = firebase.firestore();
path = 'Clientes';
valoroff:any = false;
usuarioData: Usuario = JSON.parse(localStorage.getItem('userData')) || {
  userId: '',
  email: '',
  displayName: '',
  nombre: '',
  apellido: '',
  genero: '',
  fecha_nacimiento: '',
  edad: '',
  descripcion: '',
  photoURL: '',
  telefono: '',
  direccion: {
    pais: '',
    dir_str: '',
    location: {
      lat: '',
      long: '',
    },
  },
};
data_usuario:any;
  categoria:any;
  definiciones:any = [];
  precio_categoria:any = '0';
  precio_categoriasm:any = '0';
  usuario_email:any;
  cod_img:any;
  card:any;
  stripe: Stripe;
  stripesm: Stripe;

  cod_tdc: any;
	cod_fecha: any;
	cod_cvc: any;

  cod_tdcsm: any;
	cod_fechasm: any;
	cod_cvcsm: any;
  card_idioma:any;
  usuarioDataSub$ = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('userData')));
  constructor(
    public fb:FormBuilder,
    private skservice: skServices,
    private loadindControl: LoadingController,
    private util: UiKitController,
    public modalController: ModalController,
    public userControl: AuthGuardServicesService,
    private store: StoreService,
    public menucontroler: MenuController,
		public firestoreService: StoreService,
		public carritoService: CarritoService,
		public firebaseauthService: AuthGuardServicesService,
		public loadingController: LoadingController,
		public router: Router,
    private statusControl: StatusController,
    private alertController: AlertController,
    public auth: AngularFireAuth,
    ) {
      this.data_usuario = localStorage.getItem('des_usuario');
    
    this.usuario_email = localStorage.getItem('user');
    this.cod_img = localStorage.getItem('cod_img');
      
    this.forma = this.fb.group({
      numero: [''],
      categoria: ['',[Validators.required]],
      cardCvc: [false, [Validators.required, Validators.requiredTrue]],
      cardExp: [false, [Validators.required, Validators.requiredTrue]],
      cardNumber: [false, [Validators.required, Validators.requiredTrue]],
      nom_usuario: ['', [Validators.required]],
      correo: ['', [Validators.required, Validators.email]],
      pass: ['', [Validators.required]],
      tipo: [''],
      cpass: ['',[Validators.required,RxwebValidators.compare({ fieldName: 'pass' }),
        ],
      ],
    });

    this.forma2 = this.fb.group({
      numero: [''],
      categoria: ['',[Validators.required]],
      cardCvcsm: [false, [Validators.required, Validators.requiredTrue]],
      cardExpsm: [false, [Validators.required, Validators.requiredTrue]],
      cardNumbersm: [false, [Validators.required, Validators.requiredTrue]],
      nom_usuario: ['', [Validators.required]],
      correo: ['', [Validators.required, Validators.email]],
      pass: ['', [Validators.required]],
      tipo: [''],
      cpass: ['',[Validators.required,RxwebValidators.compare({ fieldName: 'pass' }),
        ],
      ],
    });
    this.forma3 = this.fb.group({
      numero: [''],
      nom_usuario: ['', [Validators.required]],
      correo: ['', [Validators.required, Validators.email]],
      pass: ['', [Validators.required]],
      tipo: [''],
      cpass: ['',[Validators.required,RxwebValidators.compare({ fieldName: 'pass' }),
        ],
      ],
    });
    this.forma52 = this.fb.group({
      correo: ['', [Validators.required, Validators.email]],
      pass: ['', [Validators.required]],
      tipo: this.data,
    });
    }

  ngOnInit(): void {
    this.categoria = this.data;
    console.log(this.data,'this.data');
    this.card = true;
    this.cargarDefi(this.categoria);
  }
  ionViewDidEnter(){
    this.cargarElementStripe();
    this.cargarElementStripesm();
    var lang = localStorage.getItem('lg');
    if (lang) {
        if (lang.toUpperCase() === 'EN') {
            this.select = 'Select category';
            this.card_idioma = 'Card';
        }
        if (lang.toUpperCase() === 'AL') {
          this.card_idioma = 'Karte';
          this.select = 'Kategorie wählen';
      }
    }
  }

  async  togglePasswordMode() {
    //cambiar tipo input
  this.passwordTypeInput = this.passwordTypeInput === 'text' ? 'password' : 'text';
   //obtener el input
   const nativeEl = this.passwordEye.nativeElement.querySelector('input');
   //obtener el indice de la posición del texto actual en el input
   const inputSelection = nativeEl.selectionStart;
   //ejecuto el focus al input
   nativeEl.focus();
  //espero un milisegundo y actualizo la posición del indice del texto
   setTimeout(() => {
       nativeEl.setSelectionRange(inputSelection, inputSelection);
   }, 1);

}
  async  togglePasswordModeR() {
    //cambiar tipo input
  this.passwordTypeInputR = this.passwordTypeInputR === 'text' ? 'password' : 'text';
   //obtener el input
   const nativeEl = this.passwordEyeR.nativeElement.querySelector('input');
   //obtener el indice de la posición del texto actual en el input
   const inputSelection = nativeEl.selectionStart;
   //ejecuto el focus al input
   nativeEl.focus();
  //espero un milisegundo y actualizo la posición del indice del texto
   setTimeout(() => {
       nativeEl.setSelectionRange(inputSelection, inputSelection);
   }, 1);

}
  async tdcActiva(){
    try {
     await this.util.loading();
      var xvalor = {
        cod_usuario:this.data_usuario
      }
      const response = await this.skservice.getTdcUsuarioActivahttp(xvalor).toPromise();
      if (parseInt(response.tdc[0].resul) > 0 ) {
        this.card = false;
      }else{
        this.card = true;
      }
      
      
    } catch (error) {
      console.log(error);
      await this.util.ToastWarning(error.error.message);
    }
  }
  async cargarElementStripe(){
    try {
      const card1 = document.querySelector('#card-2') as HTMLDivElement;
      console.log(card1);
		if (card1) {
			this.stripe = await loadStripe(environment.stripeKey);
			const elements = this.stripe.elements();

			const style = {
				base: {
					iconColor: '#0ccde4',
					fontSize: '20px',
					color:'#212529',
				},
				invalid: {
					color: '#dc3545',
				},
			};

			const cardNumber = elements.create('cardNumber', {
				style,
				placeholder: this.card_idioma,
				classes: {
					base: 'input-excep',
				},
			});
			const cardExpiry = elements.create('cardExpiry', {
				style,
				placeholder: 'Exp',
				classes: {
					base: 'input-excep',
				},
			});
			const cardCvc = elements.create('cardCvc', {
				style,
				placeholder: 'CVC',
				classes: {
					base: 'input-excep',
				},
			});

			cardNumber.mount('#card-2');
			cardExpiry.mount('#exp-1');
			cardCvc.mount('#cvc-1');

			this.cod_tdc = cardNumber;
			this.cod_fecha = cardExpiry;
			this.cod_cvc = cardCvc;

			this.cod_tdc.on('change', this.onChangeCard.bind(this));
			this.cod_fecha.addEventListener('change', this.onChangeCvc.bind(this));
			this.cod_cvc.on('change', this.onChangeExp.bind(this));
		}
    } catch (error) {
      console.log(error);
      await this.util.ToastWarning(error.error.message);
    }
  }
  async cargarElementStripesm(){
    try {
      const card1 = document.querySelector('#card-2-sm') as HTMLDivElement;
      console.log(card1);
		if (card1) {
			this.stripesm = await loadStripe(environment.stripeKey);
			const elements = this.stripesm.elements();

			const style = {
				base: {
					iconColor: '#0ccde4',
					fontSize: '20px',
					color:'#212529',
				},
				invalid: {
					color: '#dc3545',
				},
			};

			const cardNumbersm = elements.create('cardNumber', {
				style,
				placeholder: this.card_idioma,
				classes: {
					base: 'input-excep',
				},
			});
			const cardExpirysm = elements.create('cardExpiry', {
				style,
				placeholder: 'Exp',
				classes: {
					base: 'input-excep',
				},
			});
			const cardCvcsm = elements.create('cardCvc', {
				style,
				placeholder: 'CVC',
				classes: {
					base: 'input-excep',
				},
			});

			cardNumbersm.mount('#card-2-sm');
			cardExpirysm.mount('#exp-1-sm');
			cardCvcsm.mount('#cvc-1-sm');

			this.cod_tdcsm = cardNumbersm;
			this.cod_fechasm = cardExpirysm;
			this.cod_cvcsm = cardCvcsm;

			this.cod_tdcsm.on('change', this.onChangeCardsm.bind(this));
			this.cod_fechasm.addEventListener('change', this.onChangeCvcsm.bind(this));
			this.cod_cvcsm.on('change', this.onChangeExpsm.bind(this));
		}
    } catch (error) {
      console.log(error);
      await this.util.ToastWarning(error.error.message);
    }
  }

  async enviarStripe() {
		try {
      await this.util.cargando();
      const { token } = await this.stripe.createToken(this.cod_tdc);
      if (token) {
        let xvalor = {
          amount: parseFloat(this.forma.value.categoria.val_definicion),
          currency:'usd',
          token:token.id
        }
        this.skservice.getPagosPedidohttp(xvalor).subscribe(
          async  response=>{
            console.log(response.pago.status,'stripe_payment');
            await this.util.closeLoading();
            this.modalrespuesta();
          },
          async error=>{
            await this.util.closeLoading();
            console.log(error);
            this.util.ToastError(error.error.message);
          }
        ); 
      }else{
        await this.util.closeLoading();
        await this.util.ToastError('There was a problem making the payment');
      }
      
    } catch (error) {
      await this.util.closeLoading();
      console.log(error);
      await this.util.ToastWarning(error.error.message);
    }
	}
  async enviarStripesm() {
		try {
      await this.util.cargando();
      const { token } = await this.stripesm.createToken(this.cod_tdcsm);
      if (token) {
        let xvalor = {
          amount: parseFloat(this.forma2.value.categoria.val_definicion),
          currency:'usd',
          token:token.id
        }
        this.skservice.getPagosPedidohttp(xvalor).subscribe(
       async   response=>{
        await this.util.closeLoading();
            console.log(response.pago.status,'stripe_payment');
            this.modalrespuestasm();
          },
        async  error=>{
            console.log(error);
            await this.util.closeLoading();
            this.util.ToastError(error.error.message);
          }
        ); 
      }else{
        await this.util.closeLoading();
        await this.util.ToastError('There was a problem making the payment');
      }
      
    } catch (error) {
      await this.util.closeLoading();
      console.log(error);
      await this.util.ToastWarning(error.error.message);
    }
	}
  async cargarDefi(xval:any){
    try {
      var xvalor = {
        des_definicion:'categorias',
        concepto_definicion:xval.toLowerCase()
      }
      console.log(xvalor);
      const response = await this.skservice.getDefinicionSitemathttp(xvalor).toPromise();
      console.log(response);
        if (this.categoria.toLowerCase() === 'bronce') {
          for (const i in response.def) {
            if (response.def[i].concepto_definicion.toLowerCase() === 'basic') {
             response.def[i].concepto_definicion = 'bronce';
              this.definiciones.push(response.def[i]);
            }
          }
        }
        if (this.categoria.toLowerCase() === 'silver') {
          for (const i in response.def) {
            console.log(response.def[i].concepto_definicion.toLowerCase());
            if (response.def[i].concepto_definicion.toLowerCase() === 'silver') {
              this.definiciones.push(response.def[i]);
            }
          }
        }
        if (this.categoria.toLowerCase() === 'gold') {
          for (const i in response.def) {
            if (response.def[i].concepto_definicion.toLowerCase() === 'gold') {
              this.definiciones.push(response.def[i]);
            }
          }
        }
        if (this.categoria.toLowerCase() === 'platinum') {
          for (const i in response.def) {
            if (response.def[i].concepto_definicion.toLowerCase() === 'platinum') {
              this.definiciones.push(response.def[i]);
            }
          }
        }
        console.log(this.definiciones);
    } catch (error) {
      console.log(error);
      await this.util.ToastWarning(error.error.message);
    }
  }
  async cargarPrecioCategoria(xval:any){
    try {
      console.log(this.forma.value.categoria);
      await this.util.loading();
     this.precio_categoria = this.forma.value.categoria.val_definicion;
     
    } catch (error) {
      console.log(error);
      
      await this.util.ToastWarning(error.error.message);
    }
  }
  async cargarPrecioCategoriasm(xval:any){
    try {
      console.log(this.forma.value.categoria);
      await this.util.loading();
     this.precio_categoriasm = this.forma2.value.categoria.val_definicion;
     
    } catch (error) {
      console.log(error);
      
      await this.util.ToastWarning(error.error.message);
    }
  }

  onChangeCard({ error }: any) {
		console.log(error, this.forma);
		this.forma.patchValue({ cardNumber: !error });
	}
	onChangeCvc({ error }: any) {
		console.log(error, this.forma);
		this.forma.patchValue({ cardCvc: !error });
	}
	onChangeExp({ error }: any) {
		console.log(error, this.forma);
		this.forma.patchValue({ cardExp: !error });
	}
  onChangeCardsm({ error }: any) {
		console.log(error, this.forma2);
		this.forma2.patchValue({ cardNumbersm: !error });
	}
	onChangeCvcsm({ error }: any) {
		console.log(error, this.forma2);
		this.forma2.patchValue({ cardCvcsm: !error });
	}
	onChangeExpsm({ error }: any) {
		console.log(error, this.forma2);
		this.forma2.patchValue({ cardExpsm: !error });
	}
  async modalrespuestaFalso(){
    try {
      await this.util.cargando();
      this.login_boton = 'login';
      await this.util.closeLoading();
    } catch (error) {
      await this.util.closeLoading();
      console.log(error);
    }
  }
 async modalrespuesta(){
    try {
      const auth: Auth = getAuth();
      this.forma.value.categoria = this.data.toLowerCase();
      this.forma.value.tipo = 'MMBR';
    await this.util.cargando();
    const response = await this.skservice.crearusuariohttp(this.forma.value).toPromise();
        if (response.correo) {
          const data_cloud =  await createUserWithEmailAndPassword(auth,response.correo[0].correo, response.correo[0].pass_usuario);
          
          let data_clo = {
            id_cloud:data_cloud.user.uid,
            correo:response.correo[0].correo
          }
          console.log(data_clo);
          await this.skservice.getUpdateIdcloudhttp(data_clo).toPromise();
          
          this.forma.value.id_cloud = data_cloud.user.uid;
           await updateProfile(auth.currentUser, { displayName: this.forma.value.nom_usuario });
           //await (await this.auth.currentUser).sendEmailVerification();
           this.statusControl.signOut()
            localStorage.clear();
            await this.util.closeLoading();
              this.router.navigate(['']).then(() => {
               /*  location.reload(); */
               this.login_boton = 'login_falso';
               this.data = '';
              })
           
        }else{
          await this.util.closeLoading();
          alert("Your internet connection is failing");
        }
    this.forma.reset();
    } catch (error) {
      console.log(error);
    }
  }
  async modalrespuestasm(){
    try {
      const auth: Auth = getAuth();
      this.forma2.value.categoria = this.data.toLowerCase();
      this.forma2.value.tipo = 'MMBR';
      await this.util.cargando();
      const response = await this.skservice.crearusuariohttp(this.forma2.value).toPromise();
          if (response.correo) {
            const data_cloud =  await createUserWithEmailAndPassword(auth,response.correo[0].correo, response.correo[0].pass_usuario);
            
            let data_clo = {
              id_cloud:data_cloud.user.uid,
              correo:response.correo[0].correo
            }
            console.log(data_clo);
            await this.skservice.getUpdateIdcloudhttp(data_clo).toPromise();
            
            this.forma2.value.id_cloud = data_cloud.user.uid;
             await updateProfile(auth.currentUser, { displayName: this.forma2.value.nom_usuario });
             //await (await this.auth.currentUser).sendEmailVerification();
             this.statusControl.signOut()
              localStorage.clear();
              await this.util.closeLoading();
                this.router.navigate(['']).then(() => {
                 /*  location.reload(); */
                 this.login_boton = 'login_falso';
                 this.data = '';
                })
             
          }else{
            await this.util.closeLoading();
            alert("Your internet connection is failing");
          }
    this.forma2.reset();
    } catch (error) {
      console.log(error);
    }
  }

  validatepass(){
    const pass = this.forma3.value.pass;

    // Expresiones regulares para validar la contraseña
    const longitudMinima = /[\s\S]{8,}/;
    const alMenosUnaMayuscula = /[A-Z]/;
    const alMenosUnaMinuscula = /[a-z]/;
    const alMenosUnNumero = /\d/;

    // Verificar cada criterio usando las expresiones regulares
    return (
        longitudMinima.test(pass) &&
        alMenosUnaMayuscula.test(pass) &&
        alMenosUnaMinuscula.test(pass) &&
        alMenosUnNumero.test(pass)
    );

  }
  
  async modalrespuestaBasic(){

    try {
      if (!this.validatepass()){
        this.exist = true;
        this.msg = "The password must have a minimum of 8 characters, one uppercase letter, one lowercase letter and one number.";
        return;
      }
      const auth: Auth = getAuth();
      this.forma3.value.categoria = 'basic';
      this.forma3.value.tipo = 'MMBR';
      await this.util.cargando();
      const response = await this.skservice.crearusuariohttp(this.forma3.value).toPromise();

      console.log ('respuesta 1',response)
          if (response.status){
            console.log (response.status);
            await this.util.closeLoading();
            //alert(response.message);
            this.exist = true;
            this.msg = response.message;
            return;
          }
          if (response.correo) {
            //const data_cloud =  await createUserWithEmailAndPassword(auth,response.correo[0].correo, response.correo[0].pass_usuario);
            
            /* let data_clo = {
              id_cloud:data_cloud.user.uid,
              correo:response.correo[0].correo
            } */
            //console.log(data_clo);
            //await this.skservice.getUpdateIdcloudhttp(data_clo).toPromise();
            
            //this.forma3.value.id_cloud = data_cloud.user.uid;
             //await updateProfile(auth.currentUser, { displayName: this.forma3.value.nom_usuario });
             //await (await this.auth.currentUser).sendEmailVerification();
             this.statusControl.signOut()
              localStorage.clear();
              await this.util.closeLoading();
                this.router.navigate(['']).then(() => {
                 /*  location.reload(); */
                 this.login_boton = 'login_falso';
                 this.data = '';
                })
             
          }else{
            await this.util.closeLoading();
            alert("Your internet connection is failing");
          }
    this.forma3.reset();
    } catch (error) {
      console.log(error);
    }
  }

  /* Login desde registro miembro */
  async loginsk() {
    try {
      const auth: Auth = getAuth();
      await this.util.cargando();

      const datap = {
        'email' : this.forma52.value.correo,
        'password' : this.forma52.value.pass,
        'remember me ' : true
      };

      var resp = await this.skservice.loginapihttp(datap).toPromise();

      if (!resp.access_token){
        await this.util.closeLoading();
        await this.util.ToastError(resp.message);
        return;
      }

      localStorage.setItem('acccess_token', 'Bearer '+resp.access_token);



      const response = await this.skservice.login_memberhttp(datap).toPromise();

      if(response.status == 'Unverified')
      {
        await this.util.closeLoading();
  
        await this.util.ToastUnverified(response.message);
        return;
      }

      
      
      if (response.usuario[0].tipo.toLowerCase() === 'mmbr') {
        //const userCredential = await signInWithEmailAndPassword(auth,this.forma52.value.correo, response.usuario[0].pass_usuario);
                  
      //const user = userCredential.user as any;
      
      if (response.deposito.length > 0) {
        localStorage.setItem('cod_deposito', response.deposito[0].cod_deposito);
      }
      if (response.img.length > 0) {
        localStorage.setItem('cod_img', response.img[0].cod_img);
      }
              localStorage.setItem('cod_usuario', response.usuario[0].cod_usuario);
              localStorage.setItem('des_usuario', response.usuario[0].nom_usuario);
              localStorage.setItem('cod_definicion', response.usuario[0].cod_definicion);
              localStorage.setItem('cod_concepto', response.usuario[0].cod_concepto);
              localStorage.setItem('val_definicion', response.usuario[0].val_definicion);
              localStorage.setItem('cod_sesion', response.sesion[0].cod_sesion);
              localStorage.setItem('dir_ip_client', response.sesion[0].dir_ip_client);
              localStorage.setItem('usuario_tipo', response.usuario[0].tipo);
              localStorage.setItem('tipoUsuario', response.usuario[0].tipo);
              localStorage.setItem('tipo', response.usuario[0].tipo);
              localStorage.setItem('usuario_categoria', response.usuario[0].categoria);
              localStorage.setItem('userId', response.usuario[0].id_cloud);
              //localStorage.setItem('token', user.accessToken);
              localStorage.setItem('user', response.usuario[0].id_cloud.email);

              //const docSnap = (await this.db.collection(this.path).doc(user.uid).get()).data();

      const objx: Usuario = {
                              tipo: 'Email',
                              tipoUsuario: localStorage.getItem('usuario_tipo'),
                              userId: response.usuario[0].id_cloud,
                              email: response.usuario[0].email,
                              displayName: response.usuario[0].nom_usurio,
                              nombre: '',
                              apellido: '',
                              genero: '',
                              fecha_nacimiento: '',
                              edad: '',
                              descripcion: '',
                              photoURL: response.usuario[0].email,
                              telefono: '',
                              direccion: {
                                pais: '',
                                dir_str: '',
                                location: {
                                  lat: '',
                                  long: '',
                                },
                              },
                              productos: []
                            };
      this.statusControl.setPresence('online')
      /* if (!docSnap) {
        await this.db.collection(this.path).doc(user.uid).set(objx);
        await this.db.collection('userChats').doc(user.uid).set({});
        await this.db.collection('Notificaciones').doc(user.uid).set({ notificacionesCantSinVer: 0 });
        localStorage.setItem('userData', JSON.stringify(objx));
        
        this.valoroff = true;
        /* this.qwidle();
        await this.util.closeLoading();
        location.reload();

        return;
      }
      if (!docSnap.tipo) {
        await this.db.collection(this.path).doc(user.uid).update({
          tipo: 'Email'
        });
      }
      if (!docSnap.tipoUsuario) {
        await this.db.collection(this.path).doc(user.uid).update({
          tipoUsuario: response.usuario[0].tipo ?? localStorage.getItem('usuario_tipo')
        });
      }
      if (!docSnap.photoURL) {
        await this.db.collection(this.path).doc(user.uid).update({
          photoURL: user.photoURL ?? localStorage.getItem('cod_img')
        });
      }
      this.usuarioData = docSnap as Usuario;
      this.usuarioDataSub$.next(docSnap)
      localStorage.setItem('userData', JSON.stringify(docSnap)); */

      this.modalController.dismiss();
        await this.util.ToastSuccess(response.message);
        
        this.valoroff = true;
        /* this.qwidle(); */
        await this.util.closeLoading();
        location.reload();
       return;
      }
     
    } catch (error) {
      await this.util.closeLoading();
      console.log(error);
      await this.util.ToastError(error.error.message);
    }
  }
  async dismissModal() {
    await this.util.loading();
    this.modalController.dismiss();
  }
}
