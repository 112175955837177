import { FirechatService } from 'src/app/services/firechat.service';
import { ModalController } from '@ionic/angular';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthGuardServicesService } from 'src/app/services/auth-guard-services.service';
import { CarritoService } from 'src/app/services/carrito.service';
import { ProductosService } from 'src/app/services/productos.service';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { Productos, Usuario } from 'src/models';
import { skServices } from 'src/app/services/sk.services.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import { FormMsgComponent } from '../shared/modal/form-msg/form-msg.component';
import { UiKitController } from 'src/app/services/uikitcontroller.service';
import { OfertaService } from 'src/app/services/oferta.service';
import { GetSetAtributosService } from 'src/app/services/get-set-atributos.service';
import { RouterService } from 'src/app/services/router.service';
import { environment } from 'src/environments/environment';
import SwiperCore, {
  Autoplay,
  SwiperOptions,
  EffectFade,
  Scrollbar,
} from 'swiper';
import { FormNivelComponent } from '../shared/modal/form-nivel/form-nivel.component';
SwiperCore.use([Autoplay, EffectFade, Scrollbar]);

@Component({
  selector: 'app-producto-usuario',
  templateUrl: './producto-usuario.component.html',
  styleUrls: ['./producto-usuario.component.scss'],
})
export class ProductoUsuarioComponent implements OnInit {

  data: any;
  id: any;
  tipo_usuario:any = localStorage.getItem('usuario_tipo');
  forma:FormGroup;
  rows: any= [];
  rows_galeria: any= [];
  rows_perfil: any= [];
  rows_prod: any;
  rows_prod_0: any;
  dlr: any;
  dlr_data_id: any;
  dlr_data: any = {
    nom_usuario:'',
    categoria:''
  };
  propietarioDelProducto = false
  cliente: Usuario = {
    tipo:'',
    tipoUsuario:'',
    userId: '',
    email: '',
    displayName: '',
    nombre: '',
    apellido: '',
    genero: '',
    fecha_nacimiento: '',
    edad: '',
    descripcion: '',
    photoURL: '',
    telefono: '',
    direccion: {
      pais: '',
      dir_str: '',
      location: {
        lat: '',
        long: '',
      },
    },
    productos:[]

  };
  producto: Productos = {
    src: '',
    alt: '',
    Id: '',
    user_id: '',
    user_name: '',
    title: '',
    cant_producto: 0,
    price: '',
    descripcion: '',
  }
  productoRight: Productos = {
    src: '',
    alt: '',
    Id: '',
    user_id: '',
    user_name: '',
    title: '',
    cant_producto: 0,
    price: '',
    descripcion: '',
  };
  producto_usuario:any = [];

  ProductoListadoTop: any;
  Carrito2 = this.carritoService.miTarjeta$;
  objeto:any;

  Id: string;
  cliente_: any ;
  operador: any;
  img: any;
  altura: any;
  ojos: any;
  piel: any;
  cuerpo: any;
  pelo: any;
  sexo: any;
  usuario: any;
  des_deposito: any;
  productos: any[] = [];
  fecha:any = new Date().getFullYear();
  	xfecha = this.fecha;
	userData: any;
	productos_mmbr: any = [];
	pruebaBack: any;
	category: any = localStorage.getItem('usuario_categoria');
  edad: any;
  localidad: any;
  pais: any;
  fumar: any;
  fecha_naci: any;
  comida: any;
  hijo: any;
  ape: any;
  descrip: any;
  lg:any = localStorage.getItem('lg');
  vid: any = [];
  vid_perfil: any = [];
  config: SwiperOptions = {
    keyboard: true,
    slidesPerView: 1,
    pagination:{ clickable: true },
    navigation:true,
    spaceBetween: 50,
    scrollbar: { draggable: true },
  };
  constructor(
    private carritoService: CarritoService,
    private route: ActivatedRoute,
    private firebaseauthService: AuthGuardServicesService,
    private productosControl: ProductosService,
    private usuariosControl: UsuariosService,
		private modalController: ModalController,
		private ofertaSV: OfertaService,
		private ChatSv: FirechatService,
		private UiKit: UiKitController,
    private skservice: skServices,
    private util: UiKitController,
    private fb:FormBuilder,
    private router: Router,
    private afs: AngularFirestore,
		public afAuth: AngularFireAuth,
    private getSet: GetSetAtributosService,
    private route_app: RouterService,
    private chat: FirechatService,
  ) {
    this.forma = this.fb.group({
			cant_producto: ['1']
		  });
  }

 ngOnInit() {
  this.data = this.getSet.producto;
  this.cliente_ = this.getSet.producto;
  this.des_deposito = this.data.des_deposito;
    console.log(this.data);
      if (!this.data) {
        this.route_app.rutas_app('home');
        return;
      }
      /* this.getdepDlr();
      this.getProductosTop();
      this.getUserData(); */
      
  }

  ionViewDidEnter(){
    this.data = this.getSet.producto;
    this.cliente_ = this.getSet.producto;
    this.des_deposito = this.data.des_deposito;
    console.log('DATA',this.data);
    if (!this.data) {
      this.route_app.rutas_app('home');
      return;
    }

    this.getdepDlr();
   this.getProductosTop();
   this.getUserData();
   this.listarGaleria(this.data);
   this.getVidGaleria(this.data);
   this.getVidGaleriaPerfil(this.data);
   this.listarGaleriaPerfil(this.data);
  }
  back() {
    history.back();
  }

 async getdepDlr(){
    try {
      const response =  await this.skservice.getDepositoProdhttp(this.data).toPromise();
      console.log(response);
      const response_ =  await this.skservice.getImgDLRttp(response.dlr[0]).toPromise();
      console.log(response_);
      this.dlr_data = response.dlr[0];
      this.dlr_data_id = response.dlr[0];
    } catch (error) {
      console.log(error);
      await this.util.ToastWarning(error.error.message);
    }
  }

 async AgregarAlCarrito() {
    /* this.carritoService.AgregarProducto(this.producto); */
    try {
      await this.util.loading();
      if (!isNaN(this.forma.value.cant_producto)) {
        if (parseInt(this.forma.value.cant_producto) > 0) {
          var xvalor = {
            cant_producto: this.forma.value.cant_producto,
            cod_producto: this.data.cod_producto,
            des_producto: this.data.des_producto,
            precio_producto: this.data.um_producto_operador,
            um_producto: this.data.um_producto,
            cod_deposito: this.data.cod_deposito,
            cod_img: this.data.cod_img,
            cod_cliente: localStorage.getItem('cod_usuario'),
            cod_vendedor: this.data.cod_prdr,
            cat_producto: this.data.cat_producto,
            tipo: this.data.tipo_producto_cat,
            estatus: this.data.estatus,
            total_precio_pedido: parseFloat(this.forma.value.cant_producto) * parseFloat(this.data.um_producto_operador),
            total_precio_producto: parseFloat(this.forma.value.cant_producto) * parseFloat(this.data.um_producto_operador),
          }
          
          const response =  await this.skservice.getPedidoCarritohttp(xvalor).toPromise();
          console.log(response);
          this.afs
                  .collection('pedidos_usuarios')
                  .doc(localStorage.getItem('cod_usuario'))
                  .collection('pedido')
                  .doc(response.pedido[0].cod_pedido_carrito)
                  .set({
                    estatus:'A',
                    fecha: firebase.firestore.FieldValue.serverTimestamp(),
                    
                  });
        for (const i in response.pedido) {
          this.afs
                  .collection('pedidos_usuarios')
                  .doc(localStorage.getItem('cod_usuario'))
                  .collection('pedido')
                  .doc(response.pedido[i].cod_pedido_carrito)
                  .collection('productos')
                  .doc(response.pedido[i].cod_producto)
                  .set({
                    des_producto: response.pedido[i].des_producto,
                    fecha: firebase.firestore.FieldValue.serverTimestamp(),
                    
                  });
        }
          
          this.util.ToastSuccess(response.message);
          this.route_app.rutas_app('carrito');
          
        }else{
          
        this.util.ToastWarning('Quantity must be greater than 0');
        }
      }else{
        
        this.util.ToastWarning('Only numbers');
      }
    } catch (error) {
      
      console.log(error);
      this.util.ToastWarning(error.error.message);
    }
  }

	async openOfertar(){
		try {
      console.log(this.data);
      await this.util.loading();
      if (this.tipo_usuario.toLowerCase() === 'vstnt') {
        await this.util.ToastWarning('You must sign up for a membership');
        return;
      }
      if (!isNaN(this.forma.value.cant_producto)) {
        if (parseInt(this.forma.value.cant_producto) > 0) {
          
			const modal = await this.modalController.create({
				component: FormMsgComponent,
				cssClass: 'form-precio',
        backdropDismiss: false,
				componentProps: {
					data_prod:this.data
				},
			});
      
			await modal.present();
			const { data } = await modal.onDidDismiss()
			console.log(data)
			if(data !== '' && data !== null && data !== undefined){
        console.log(this.data);
				this.util.loading();
				const oferta = await this.ofertaSV.crearOferta(this.data, data.monto);
        var xobjeto = {
          cod_oferta:oferta.ofertaId,
          cod_usuario:localStorage.getItem('cod_usuario'),
          cod_prdr:this.data.cod_usuario,
          cod_producto:this.data.cod_producto,
          des_producto:this.data.des_producto,
          um_producto_oferta:oferta.precio,
          cod_deposito:this.data.cod_deposito,
          cod_img:this.data.cod_img,
          cant_producto:this.data.cant_producto,
          um_producto_operador:this.data.um_producto_operador,
          cat_producto:this.data.cat_producto,
          tipo_producto_cat:this.data.tipo_producto_cat,

        };
        const response = await this.skservice.getOfertaProductohttp(xobjeto).toPromise();
        console.log(oferta);
        var xvalor = {
          cod_prdr: this.data.cod_prdr,
          cod_deposito: this.data.cod_deposito,
        }
        const response_ = await this.skservice.getPrdrIdcloudhttp(xvalor).toPromise();
        if (response_) {
         this.cliente.userId =  response_.dlr[0].id_cloud;
         this.cliente.displayName = response_.dlr[0].des_usuario;
         this.cliente.apellido = this.data.cod_prdr;
         this.cliente.photoURL = response_.dlr[0].cod_img;
         this.cliente.tipo = this.data.tipo;
         this.cliente.tipoUsuario = this.data.tipo;
         await this.ChatSv.sendMessageOferta(this.cliente, data.mensaje,'offline', oferta)
        }else{
          await this.util.ToastWarning('This seller does not have a chat available');
        }
        
       /*  this.util.ToastSuccess(response.message);
        this.router.navigate(['/chat']); */
			}
        }else{
          
        this.util.ToastWarning('Quantity must be greater than 0');
        }
      }else{
        
        this.util.ToastWarning('Only numbers');
      }
      
		} catch (error) {
      
			this.UiKit.ToastError(error.error.message);
			console.log(error)
		}
	}
  async modalUsuarioProdcutos(xvalor:any){
    try {
      console.log('xvalor', xvalor);
      await this.util.loading();
      var xval = {
        cod_producto:xvalor.cod_producto,
        cod_deposito:xvalor.cod_deposito
      }
      const xobjeto = await this.skservice.getProductoActivoHomehttp(xval).toPromise();
      console.log(xobjeto);
      if (xobjeto.resul[0].estatus === 'A') {
        if (localStorage.getItem('usuario_tipo').toLowerCase() !== 'dlr') {
          if (localStorage.getItem('usuario_tipo').toLowerCase() === 'mmbr') {
            if (localStorage.getItem('usuario_categoria').toLowerCase() === 'platinum') {
              xvalor.cod_usuario = xvalor.cod_prdr;
              this.data = xvalor;
              
            }
            if (localStorage.getItem('usuario_categoria').toLowerCase() === 'gold') {
              if (xobjeto.resul[0].cat_producto.toLowerCase() === 'gold' ||
               xobjeto.resul[0].cat_producto.toLowerCase() === 'silver' ||
               xobjeto.resul[0].cat_producto.toLowerCase() === 'basic') {
                xvalor.cod_usuario = xvalor.cod_prdr;
                this.data = xvalor;
              }else{
                
                const modal = await this.modalController.create({
                  component: FormNivelComponent,
                  cssClass: 'form-usuario-producto',
                  componentProps: { data:xvalor},
                  backdropDismiss: false
                });
                await modal.present();
                const { data } = await modal.onWillDismiss();
                if (data !== undefined && data !== null && data !== '') {
                  console.log(data);
                  await this.router.navigate(['proveedor',data.cod_usuario+'//'+data.cod_deposito]);
                }
                return modal;
              }
            }
            if (localStorage.getItem('usuario_categoria').toLowerCase() === 'silver') {
              if (xobjeto.resul[0].cat_producto.toLowerCase() === 'silver' ||
                  xobjeto.resul[0].cat_producto.toLowerCase() === 'basic') {
                    xvalor.cod_usuario = xvalor.cod_prdr;
                this.data = xvalor;
              }else{
                
                const modal = await this.modalController.create({
                  component: FormNivelComponent,
                  cssClass: 'form-usuario-producto',
                  componentProps: { data:xvalor},
                  backdropDismiss: false
                });
                await modal.present();
                const { data } = await modal.onWillDismiss();
                if (data !== undefined && data !== null && data !== '') {
                  console.log(data);
                  
                  await this.router.navigate(['proveedor',data.cod_usuario]);
                }
                return modal;
              }
            }
          }else{
            if (xobjeto.resul[0].cat_producto.toLowerCase() === 'basic') {
              this.data = xvalor;
            }else{
              
          await this.util.ToastWarning('You must acquire a  membership To buy this product');
          this.router.navigate(['/membresia_visitante']);
            }
          }
        }else{
          
          await this.util.ToastWarning('You must acquire a  membership To buy this product');
        }
      }else{
        
        await this.util.ToastWarning('This product is not currently available');
        
      }
      
    } catch (error) {
      
      console.log(error);
        await this.util.ToastWarning(error.error.message);
    }
    
  }
  async getProductosTop() {
    try {
      this.rows = [];
      this.producto_usuario = [];
      const response =  await this.skservice.getDepositoProdhttp(this.data).toPromise();
     for (const i in response.deposito) {
      for (const k in response.img) {
        if (response.deposito[i].cod_producto.toUpperCase() === response.img[k].cod_producto.toUpperCase()) {
          response.deposito[i].cod_img = response.img[k].cod_img;
          this.producto_usuario.push(response.deposito[i]);
        }
      }
     }
     for (const i in this.producto_usuario) {
      if (this.producto_usuario[i].um_producto_operador &&
         this.producto_usuario[i].cat_producto &&
         this.producto_usuario[i].tipo_producto_cat &&
          this.producto_usuario[i].estatus === 'A') {
            if (localStorage.getItem('usuario_categoria').toLowerCase() === 'platinum') {
              this.rows.push(this.producto_usuario[i]);
            }
            if (localStorage.getItem('usuario_categoria').toLowerCase() === 'gold') {
              if (this.producto_usuario[i].cat_producto.toLowerCase() === 'gold' ||
                  this.producto_usuario[i].cat_producto.toLowerCase() === 'silver' ||
                  this.producto_usuario[i].cat_producto.toLowerCase() === 'basic') {
                    this.rows.push(this.producto_usuario[i]);
              }
            }
            if (localStorage.getItem('usuario_categoria').toLowerCase() === 'silver') {
              if (this.producto_usuario[i].cat_producto.toLowerCase() === 'silver' ||
                  this.producto_usuario[i].cat_producto.toLowerCase() === 'basic') {
                    this.rows.push(this.producto_usuario[i]);
              }
            }
            if (localStorage.getItem('usuario_categoria').toLowerCase() === 'basic') {
              if (this.producto_usuario[i].cat_producto.toLowerCase() === 'basic') {
                    this.rows.push(this.producto_usuario[i]);
              }
            }
            if (localStorage.getItem('usuario_categoria').toLowerCase() === 'vstnt') {
              if (this.producto_usuario[i].cat_producto.toLowerCase() === 'basic') {
                    this.rows.push(this.producto_usuario[i]);
              }
            }

           
      }
   }
   this.rows.forEach(element => {
    element.cod_img = environment.path_prod + element.cod_img;
    
  });
     const array = [];

     for (let i = 0; i < 6; i++) {
       const item = this.rows[Math.floor(Math.random() * this.rows.length)];
       array.push(item);
     }
     this.rows_prod = array;
     this.rows_prod_0 = array[0];
    } catch (error) {
      console.log(error);
      await this.util.ToastWarning(error.error.message);
    }
  }
  async getShowDlr() {
    try {
      await this.util.loading();
      console.log(this.dlr_data_id);
      this.modalController.dismiss(this.dlr_data_id);
      
    } catch (error) {
      
      console.log(error);
      await this.util.ToastWarning(error.error.message);
    }
  }
  async dismissModal() {
    this.modalController.dismiss();
    
  }
  async incremento() {
    let cant_prod = document.querySelector("#cant_prod") as HTMLIonInputElement;
    cant_prod.value = parseInt(this.forma.value.cant_producto) + 1;
  }
  async decremento() {
    if (parseInt(this.forma.value.cant_producto) > 0) {
      let cant_prod = document.querySelector("#cant_prod") as HTMLIonInputElement;
    cant_prod.value = parseInt(this.forma.value.cant_producto) - 1;
    }
  }

  async enviarMensaje(){
    try {
      this.util.loading();
      
      var xvalor = {
        cod_prdr: this.cliente_.cod_prdr,
        cod_deposito: this.cliente_.cod_deposito
      }
      const response = await this.skservice.getPrdrIdcloudhttp(xvalor).toPromise();
      console.log(response);
      if (response) {
       
       /* response.prdr[0].cod_img = response.dlr[0].cod_img;
       response.prdr[0].des_usuario = response.dlr[0].des_usuario;
       response.prdr[0].id_cloud = response.dlr[0].id_cloud; */
        this.chat.agregarMensajeDesdePerfil(response.dlr[0],response.prdr[0] );
      }else{
        await this.util.ToastWarning('This seller does not have a chat available');
      }
    } catch (error) {
      console.log(error);
    }
  }

  async getUserData(){
    try {
      await this.util.loading();
          let xobjeto = {
            cod_usuario:this.data.cod_deposito,
            cod_deposito:this.data.cod_deposito
          }
         const response =  await this.skservice.getUsuarioParametrottp(xobjeto).toPromise();
         console.log(response);
        
         if (response.usuario[0].cod_img === null ||
             response.usuario[0].cod_img === undefined ||
             response.usuario[0].cod_img === '') {
              response.usuario[0].cod_img = '../../../assets/img/404.jpg';
         }
         if (response.usuario[0].categoria === 'PRDR' ) {
           response.usuario[0].categoria = 'Seller';
        }
        if (response.usuario[0].categoria === 'DLR' ) {
          response.usuario[0].categoria = 'Seller';
       }
       this.operador = response.usuario[0];
       this.img = environment.path_prod + response.usuario[0].cod_img;
       this.ojos = response.usuario[0].ojos_usuario;
       this.pelo = response.usuario[0].pelo_usuario;
       this.piel = response.usuario[0].color_usuario;
       this.cuerpo = response.usuario[0].body_data;
       this.sexo = response.usuario[0].sex_usuario;
       this.altura = response.usuario[0].altura_usuario;
       this.usuario = response.usuario[0].des_usuario;
       this.ape = response.usuario[0].ape_usuario;

       this.edad = response.usuario[0].edad_usuario;
       this.localidad = response.usuario[0].dir_usuario;
       this.pais = response.usuario[0].pais_usuario;
       this.fumar = response.usuario[0].fumar_usuario;
       this.fecha_naci = response.usuario[0].fecha_usuario;
       this.comida = response.usuario[0].comida_usuario;
       this.hijo = response.usuario[0].nino_usuario;
       this.descrip = response.usuario[0].descrip_usuario;
         /*  this.cliente = await this.usuariosControl.buscarUsuarioByID(this.Id);
          this.productos = await this.productosControl.filtrarProductosByIDUsuario(this.Id) */
          
        
      /* if (localStorage.getItem('usuario_tipo') === 'PRDR') {
        this.route.params.subscribe(async (params: Params) => {
          console.log(params);
          let xobjeto = {
            cod_usuario:params.cod_usuario,
            cod_deposito:params.cod_usuario
          }
         const response =  await this.skservice.getUsuarioParametrottp(xobjeto).toPromise();
         const response_ =  await this.skservice.getDepositoProdDlrBasichttp(response.usuario[0]).toPromise();
         console.log(response_);
         this.productos = [];
         this.productos_mmbr = [];
       for (const i in response_.deposito) {
        if (response_.deposito[i].um_producto && response_.deposito[i].cat_producto) {
          for (const k in response_.img) {
            if (response_.deposito[i].cod_producto.toUpperCase() === response_.img[k].cod_producto.toUpperCase()) {
              response_.deposito[i].cod_img = response_.img[k].cod_img;
              response_.deposito[i].precio_producto = response_.deposito[i].um_producto;
              this.productos.push(response_.deposito[i]);
            }
          }
         }
       }
       this.productos_mmbr = [...this.productos];
         console.log(response);
         response.usuario[0].categoria = 'Seller';
         this.cliente = response.usuario[0];
         this.operador = response.usuario[0];
         this.img = response.usuario[0].cod_img;
        
          
        });
       
      } */
    } catch (error) {
      console.log(error);
      
      await this.util.ToastWarning(error.error.message);
    }
  }

  async getDeseosUsuario() {
    /* this.carritoService.AgregarProducto(this.producto); */
    try {
      console.log(this.data);
      await this.util.cargando();
 
          var xvalor = {
            cant_producto: this.data.cant_producto,
            cod_producto: this.data.cod_producto,
            des_producto: this.data.des_producto,
            precio_producto: this.data.um_producto_operador,
            um_producto: this.data.um_producto,
            cod_deposito: this.data.cod_deposito,
            cod_img: this.data.cod_img,
            cod_cliente: localStorage.getItem('cod_usuario'),
            cod_vendedor: this.data.cod_prdr,
            cat_producto: this.data.cat_producto,
            tipo: this.data.tipo_producto_cat,
          }
          
          const response =  await this.skservice.getDeseosUsuariohttp(xvalor).toPromise();
          console.log(response);
          this.afs
                  .collection('deseos_usuarios')
                  .doc(localStorage.getItem('cod_usuario'))
                  .collection('deseo')
                  .doc(xvalor.cod_producto)
                  .set({
                    estatus:'A',
                    fecha: firebase.firestore.FieldValue.serverTimestamp(),
                    
                  });
                  this,this.des_deposito = 'GG';
                  await this.util.closeLoading();
          this.util.ToastSuccess(response.message);
          /* this.route_app.rutas_app('deseos_usuario'); */
          
      
    } catch (error) {
      
      console.log(error);
      this.util.ToastWarning(error.error.message);
    }
  }
  async getQuitarDeseo(){
    try {
      var xval = {
        cod_producto: this.data.cod_producto,
        cod_deposito: this.data.cod_deposito,
        cod_usuario: localStorage.getItem('cod_usuario'),
      }
      await this.util.cargando();
      const response = await this.skservice.getQuitarDeseohttp(xval).toPromise();
      this.afs
                .collection('deseos_usuarios')
                .doc(localStorage.getItem('cod_usuario'))
                .collection('deseo')
                .doc(xval.cod_producto)
                .set({
                  estatus:'I',
                  fecha: firebase.firestore.FieldValue.serverTimestamp(),
                  
                });
                this.des_deposito = '';
        await this.util.closeLoading();
        await this.util.ToastSuccess(response.message);
    } catch (error) {
      await this.util.closeLoading();
      console.log(error);
      await this.util.ToastWarning(error.error.message);
    }
  }
  async listarGaleria(xvalor:any){
    try {
      this.util.loading();
      console.log(xvalor);
      this.rows = [];
      var data = {
        cod_deposito:xvalor.cod_deposito,
        cod_usuario:xvalor.cod_deposito,
        cod_producto:xvalor.cod_producto
      };
     const response =  await this.skservice.getGaleriahttp(data).toPromise();
     
     response.img.forEach(element => {
      element.cod_img = environment.path_prod + element.cod_img;
      
     });
     this.rows_galeria = [...response.img];
    } catch (error) {
      console.log(error);
    }
  }
  async getVidGaleria(xvalor:any){
    try {
      this.util.loading();
      console.log(xvalor);
      this.vid = [];
      var data = {
        cod_deposito:xvalor.cod_deposito,
        cod_usuario:xvalor.cod_deposito,
        cod_producto:xvalor.cod_producto
      };
     const response =  await this.skservice.getVidGaleriahttp(data).toPromise();
    console.log(response);
     this.vid = [...response.vid];
    } catch (error) {
      console.log(error);
    }
  }
  async getVidGaleriaPerfil(xvalor:any){
    try {
      this.util.loading();
      console.log(xvalor);
      this.vid_perfil = [];
      var data = {
        cod_deposito:xvalor.cod_deposito,
        cod_usuario:xvalor.cod_deposito,
        cod_producto:xvalor.cod_producto
      };
     const response =  await this.skservice.getVidGaleriaPerfilhttp(data).toPromise();
    console.log(response);
     this.vid_perfil = [...response.vid];
    } catch (error) {
      console.log(error);
    }
  }
  async listarGaleriaPerfil(xvalor:any){
    try {
      console.log ('*************************');
      this.util.loading();
      console.log('XVALOR', xvalor);
      this.rows = [];
      var data = {
        cod_usuario:xvalor.cod_deposito,
      };
     const response =  await this.skservice.getGaleriaPerfilhttp(data).toPromise();
    console.log(response);
     this.rows_perfil = [...response.img];
    } catch (error) {
     
      console.log(error);
    }
  }

}
