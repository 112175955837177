import { Component, OnInit, Input, AfterContentInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingController, ModalController } from '@ionic/angular';
import { AuthGuardServicesService } from 'src/app/services/auth-guard-services.service';
import { Usuario } from 'src/models';
import * as moment from 'moment';
import iziToast from 'izitoast';
import { ModalCropImagenComponent } from '../modal-crop-imagen/modal-crop-imagen.component';
import { StoreService } from 'src/app/services/store.service';
import { UiKitController } from 'src/app/services/uikitcontroller.service';
import { skServices } from 'src/app/services/sk.services.service';

@Component({
  selector: 'app-modal-form-user',
  templateUrl: './modal-form-user.component.html',
  styleUrls: ['./modal-form-user.component.scss'],
})
export class ModalFormUserComponent implements OnInit {
  @Input() Usuario: any;
  @Input() Actualizar: boolean;

  forma: FormGroup;
  countries: any = [];
  img:any = localStorage.getItem('img');
  img_count:any = [];
  img_prod:any = '';
  usuario:any = localStorage.getItem('usuario_tipo'); 
  constructor(
    public modalController: ModalController,
    private loadindControl: LoadingController,
    private fb: FormBuilder,
    private util: UiKitController,
    public userControl: AuthGuardServicesService,
    private store: StoreService,
    private skservice: skServices,
  ) {
    fetch('https://restcountries.com/v3.1/all')
      .then((res) => res.json())
      .then((response) => {
        this.countries = response.sort((a, b) =>
          a.name.common.localeCompare(b.name.common)
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }

  ngOnInit() {
    this.forma = this.fb.group({
      des_usuario: ['', Validators.required],
      nom_usuario: ['', Validators.required],
      ape_usuario: ['', Validators.required],
      sex_usuario: ['', Validators.required],
      fecha_usuario: ['', Validators.required],
      edad_usuario: ['', Validators.required],
      bank_data: [''],
      dni_usuario: [''],
      descrip_usuario: ['',[Validators.required, Validators.minLength(6)]],
      cod_img: '',
      pais_usuario: ['', Validators.required],
    });
  }

  async dismissModal() {
    await this.util.loading();
    this.modalController.dismiss();
  }

  async fechaCumpleanos(event: any) {
    try {
      await this.util.loading();
    const fecha = moment(event.detail.value, 'YYYY-MM-DD');
    const edad = moment().year() - fecha.year();
    this.forma.patchValue({edad_usuario: edad});
    } catch (error) {
      console.log(error);
    }
  }

  async guardarData() {
    const loading = await this.loadindControl.create({
      message: 'Wait a minute...',
    });

    try {
      await loading.present();

      const URL = await this.store.CargarImagenBase64(this.forma.value.photoURL, 'userPhoto')

      this.forma.patchValue({
        photoURL: URL
      })
      console.log(URL)

      await this.userControl.actualizarUsuario(this.forma.value);

      await loading.dismiss();

      this.dismissModal()

      iziToast.success({ message: 'Update Success' });

    } catch (error) {
      await loading.dismiss();

      console.log(error);

      iziToast.error({ message: 'Ups.. Error' });
    }
  }
  async imagenPerfil(objeto:any) {
    try {
      await this.util.loading();
      var img = objeto.target.files[0]
      const response =  await this.store.CargarImagenPerfil(img);
      var data = {
        cod_usuario: localStorage.getItem('cod_usuario'),
        cod_img: response,
      }
      const response_ = await this.skservice.imgPerfilhttp(data).toPromise();
      await this.util.ToastSuccess(response_.message);
      localStorage.setItem('cod_img', response_.img[0].cod_img);
      this.img = localStorage.getItem('cod_img');
    } catch (error) {
      console.log(error);
      await this.util.ToastWarning(error.error.message);
    }
  }

  async getimgprod(objeto:any) {
    try {
      await this.util.loading();
      this.img_prod = objeto.target.files[0];
      this.img_count.push(this.img_prod);
      await this.util.closeLoading();
    } catch (error) {
      await this.util.closeLoading();
      console.log(error);
      await this.util.ToastWarning(error.error.message);
    }
  }

  async imagenProd() {
    try {
      console.log(this.forma.value);
      if (this.img_prod !== '' && this.img_prod.name.length <= 10 &&  this.img_prod.name.length >= 1) {
        console.log(this.img_prod );
        await this.util.loading();
      const response =  await this.store.CargarImagenPerfil(this.img_prod);
      const fecha_usuario = moment(this.forma.value.fecha_usuario).format('DD/MM/YYYY');
      var data = {
        cod_usuario: localStorage.getItem('cod_usuario'),
        des_usuario: this.forma.value.des_usuario,
        cod_img: response,
        ape_usuario: this.forma.value.ape_usuario,
        descrip_usuario: this.forma.value.descrip_usuario,
        edad_usuario: this.forma.value.edad_usuario,
        fecha_usuario: fecha_usuario,
        nom_usuario: this.forma.value.nom_usuario,
        pais_usuario: this.forma.value.pais_usuario,
        sex_usuario: this.forma.value.sex_usuario,
        bank_data: this.forma.value.bank_data,
        dni_usuario: this.forma.value.dni_usuario,
      }
    this.modalController.dismiss(data);
    await this.util.closeLoading();
    this.img_prod = '';
    this.forma.reset();
      
      }else{
        await this.util.loading();
        if (this.img_prod === '') {
          await this.util.closeLoading();
          await this.util.ToastWarning('You must upload an image to continue');
        }
        if (this.img_prod.name.length > 10) {
          await this.util.closeLoading();
          await this.util.ToastWarning('Image name must be less than 10 characters');
        }
        if (this.img_prod.name.length < 1) {
          await this.util.closeLoading();
          await this.util.ToastWarning('You can not upload unnamed images');
        }
      }
      
    } catch (error) {
      this.img_prod = '';
      console.log(error);
      await this.util.ToastWarning(error.error.message);
    }
  }
}
