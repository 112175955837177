import * as _ from 'lodash';
//NG-OFFLINE
import { Component, OnDestroy, OnInit } from '@angular/core';

import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, fromEvent, merge, empty, Subscription } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { mapTo } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { ref, Storage, uploadBytes } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { AuthGuardServicesService } from './auth-guard-services.service';
import { UiKitController } from './uikitcontroller.service';
import { ModalController } from '@ionic/angular';
import { skServices } from './sk.services.service';

@Injectable({
  providedIn: 'root'
})
export class RouterService {

  constructor(
    private router: Router,
		private servicio: AuthGuardServicesService,
		private util: UiKitController,
		public modalController: ModalController,
		private skservice: skServices
  ) { }

async  rutas_app(xvalor:any){
    try {
      
      this.router.navigate([xvalor]);
      
      
    } catch (error) {
      console.log(error);
    }
  }
async  rutas_app_param(xvalor:any, param:any){
    try {
      await this.router.navigate([xvalor,param]);
      
    } catch (error) {
      console.log(error);
    }
  }
}
