import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { AuthGuardServicesService } from 'src/app/services/auth-guard-services.service';
import { GetSetAtributosService } from 'src/app/services/get-set-atributos.service';
import { RouterService } from 'src/app/services/router.service';
import { skServices } from 'src/app/services/sk.services.service';
import { StoreService } from 'src/app/services/store.service';
import { UiKitController } from 'src/app/services/uikitcontroller.service';
import { FormProductoPrecioComponent } from '../shared/modal/form-producto-precio/form-producto-precio.component';
import { FormProductoVendedorComponent } from '../shared/modal/form-producto-vendedor/form-producto-vendedor.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-deposito-operador-vendedor',
  templateUrl: './deposito-operador-vendedor.component.html',
  styleUrls: ['./deposito-operador-vendedor.component.scss'],
})
export class DepositoOperadorVendedorComponent implements OnInit {
  vendor:any = [];
  category: any = localStorage.getItem('usuario_categoria');
  currentPage = 1;
  pageOfItems: Array<any>;
  producto_general:any;
  constructor(
    private userControl: AuthGuardServicesService,
    private skservice: skServices,
    private util: UiKitController,
    private router: Router,
    private getSet: GetSetAtributosService,
    private firebaseauthService: AuthGuardServicesService,
    private firestorangeService: StoreService,
    private modalController: ModalController,
    private routerServices: RouterService,
  ) {  }

  ngOnInit() {
  }

  ionViewDidEnter() {
    this.listarDepControlOperador('1');
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

  async listarDepControlOperador(msg:any){
    try {
      var xvalor = {
        cod_prdr:localStorage.getItem('cod_usuario')
      }
      await this.util.loading();
     const response =  await this.skservice.getOperadorVendedorhttp(xvalor).toPromise();
     console.log('response.productos',response.productos);
     console.log('response.vendor', response.vendor);

     response.productos.forEach(prod => {
      prod.cod_img = environment.path_prod + prod.cod_img;
     });

     response.vendor.forEach(prod => {
      prod.cod_img = environment.path_prod + prod.cod_img;
     });
     
     this.producto_general = [...response.productos];
     this.vendor = [...response.vendor];
     if (msg !== null) {
      this.util.ToastSuccess('Store loaded successfull');
    }
    } catch (error) {
      console.log(error);
      
      await this.util.ToastWarning(error.error.message);
    }
  }
  async modalProductoPrecio(xvalor:any){
		try {
        this.getSet.data_vendedor_value = xvalor;
        this.routerServices.rutas_app('operador_almacen_vendedor');

		  /* await this.util.loading();
		  const modal = await this.modalController.create({
			component: FormProductoPrecioComponent,
			cssClass: 'form-pedidos',
			componentProps: { data:xvalor},
			backdropDismiss: false
		  });
		  
		  await modal.present();
		  const { data } = await modal.onWillDismiss();
		  this.listarDepControlOperador();
		  return modal; */
		} catch (error) {
		  console.log(error);
			await this.util.ToastWarning(error.error.message);
		}
		
	  }
  async getProductoGeneral(){
		try {
		  await this.util.cargando();
		  const modal = await this.modalController.create({
			component: FormProductoVendedorComponent,
			cssClass: 'form-pedidos',
			componentProps: { data:this.producto_general},
			backdropDismiss: false
		  });
		  await this.util.closeLoading();
		  await modal.present();
		  const { data } = await modal.onWillDismiss();
		  return modal;
		} catch (error) {
		  console.log(error);
      await this.util.closeLoading();
			await this.util.ToastWarning(error.error.message);
		}
		
	  }
    async perfilSeller(xvalor:any){
      try {
        await this.util.loading();
        this.router.navigate(['proveedor',xvalor.cod_usuario]);
        
      } catch (error) {
        console.log(error);
        await this.util.ToastWarning(error.error.message);
      }
      
      }
}
