import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertController, LoadingController, MenuController, ModalController } from '@ionic/angular';
import { AuthGuardServicesService } from 'src/app/services/auth-guard-services.service';
import { CarritoService } from 'src/app/services/carrito.service';
import { skServices } from 'src/app/services/sk.services.service';
import { StatusController } from 'src/app/services/statuscontroller.service';
import { StoreService } from 'src/app/services/store.service';
import { UiKitController } from 'src/app/services/uikitcontroller.service';
import { FormOperacionComponent } from '../form-operacion/form-operacion.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-form-producto-vendedor',
  templateUrl: './form-producto-vendedor.component.html',
  styleUrls: ['./form-producto-vendedor.component.scss'],
})
export class FormProductoVendedorComponent implements OnInit {
  @Input() data: any;
  lg:any = localStorage.getItem('lg');
  data_filtro: any;
  rows: any;
  constructor(
    public modalController: ModalController,
    private loadindControl: LoadingController,
    private fb: FormBuilder,
    private util: UiKitController,
    public userControl: AuthGuardServicesService,
    private store: StoreService,
    private skservice: skServices,
    public menucontroler: MenuController,
		public firestoreService: StoreService,
		public carritoService: CarritoService,
		public firebaseauthService: AuthGuardServicesService,
		public loadingController: LoadingController,
		public router: Router,
    private statusControl: StatusController,
    private alertController: AlertController,
  ) { }

  ngOnInit() {
    console.log('Data en componente ', this.data);
/*     this.data.forEach(prod => {
      prod.cod_img = environment.path_prod + prod.cod_img;
      
    }); */
    this.data_filtro = this.data;
    this.rows = this.data;
    console.log(this.rows);
  }
  async modalProductoPrecio(xvalor:any){
    try {
      var xval = {
        cod_producto:xvalor.cod_producto,
        cod_deposito:xvalor.cod_deposito
      }
      const objeto = await this.skservice.getProductoActivoHomehttp(xval).toPromise();
      console.log(objeto);
      if (parseInt(objeto.resul) <= 0) {
        await this.util.ToastWarning('This product cannot be updated because it is in the purchase process');
        /* await  this.listarProductoOperador(null); */
      }else{
        await this.util.cargando();
        const modal = await this.modalController.create({
        component: FormOperacionComponent,
        cssClass: 'form-operacion',
        componentProps: { data:xvalor},
        backdropDismiss: false
        });
        await this.util.closeLoading();
        await modal.present();
        const { data } = await modal.onWillDismiss();
        if (data !== '' && data !== null && data !== undefined) {
          await this.util.cargando();
          if (data.operacion) {
            const response = await this.skservice.getRechazarProductoModeradorhttp(data).toPromise();
                  console.log(response);
                  await this.util.closeLoading();
                  await this.util.ToastSuccess('Product successfully removed, seller was notified by email');
                 /*  await this.listarProductoOperador(null); */
            }else{
              console.log(data);
          var xobjeto = {
            cod_producto:xvalor.cod_producto,
            des_producto:xvalor.des_producto,
            cod_deposito: xvalor.cod_deposito,
            cat_producto: data.cat_producto,
            tipo: data.tipo,
            cod_prdr:localStorage.getItem('cod_usuario'),
            um_producto_operador: data.monto,
            des_comentario:data.mensaje,
            estatus:xvalor.estatus
          };
          console.log(xobjeto);
          
          const response = await this.skservice.getProductoPrecioOperadorhttp(xobjeto).toPromise();
          console.log(response);

          for (const i in this.data_filtro) {
            if (this.data_filtro[i].cod_producto === response.resul[0].cod_producto) {
              this.data_filtro[i].um_producto_operador = response.resul[0].um_producto_operador;
            }
          }
          for (const i in this.rows) {
            if (this.rows[i].cod_producto === response.resul[0].cod_producto) {
              this.rows[i].um_producto_operador = response.resul[0].um_producto_operador;
            }
          }
          await this.util.closeLoading();
          await this.util.ToastSuccess(response.message);
          
          /* await this.listarProductoOperador(null); */
            }
          
        }else{
          /* await this.listarProductoOperador(null); */
        }
      }
    } catch (error) {
      console.log(error);
      await this.util.ToastWarning(error.error.message);
    }
    }
  async getInactivarProducto(xobjeto:any){
    try {
      await this.util.cargando();
      console.log(xobjeto);
      xobjeto.operacion = 'inactivar';
      xobjeto.estatus_actual = xobjeto.estatus;
      if (this.lg.toLowerCase() === 'en') {
        xobjeto.comentarios = 'Product removed, contact admin';
      }
      if (this.lg.toLowerCase() === 'al') {
        xobjeto.comentarios = 'Produkt entfernt, mit dem Administrator kommunizieren';
      }
      
      const response = await this.skservice.getRechazarProductoModeradorhttp(xobjeto).toPromise();
                console.log(response);
                for (const i in this.rows) {
                  if (this.rows[i].cod_producto === xobjeto.cod_producto) {
                   delete this.rows[i];
                  }
                }
                const query = '';
                this.data_filtro = this.rows.filter(d => 
                  d.um_producto.toLowerCase().indexOf(query) > -1  ||
                  d.nom_usuario.toLowerCase().indexOf(query) > -1  ||
                  d.des_producto.toLowerCase().indexOf(query) > -1);
                await this.util.closeLoading();
                if (this.lg.toLowerCase() === 'en') {
                  await this.util.ToastSuccess('Product successfully removed, seller was notified by email');
                }
                if (this.lg.toLowerCase() === 'al') {
                  await this.util.ToastSuccess('Produkt erfolgreich entfernt, Verkäufer wurde per E-Mail benachrichtigt');
                }
                /* await this.listarProductoOperador(null); */
    } catch (error) {
      await this.util.closeLoading();
      console.log(error);
    }
  }
  async handleChange(event, termino:any) {
    await this.util.cargando();
   
    if (termino === 'productos') {
      const query = event.target.value.toLowerCase();
      console.log(query);
      this.data_filtro = this.rows.filter(d => 
        d.um_producto.toLowerCase().indexOf(query) > -1  ||
        d.nom_usuario.toLowerCase().indexOf(query) > -1  ||
        d.des_producto.toLowerCase().indexOf(query) > -1);
      
      console.log(this.data_filtro.length);
      await this.util.closeLoading();
      if (this.data_filtro.length <= 0) {
        await this.util.ToastWarning('Without results');
      }else{
        await this.util.ToastSuccess(this.data_filtro.length+' Resultados encontrados');
      }
    }
 
    if (termino === '' || termino === undefined || termino === null) {
    await this.util.closeLoading();
    if (this.data_filtro.length <= 0) {
      await this.util.ToastWarning('Without results');
    }else{
      await this.util.ToastSuccess(this.data_filtro.length+' Results found');
    }
    }
  }
  async dismissModal() {
    await this.util.cargando();
      this.modalController.dismiss();
    await this.util.closeLoading();
  }
}
