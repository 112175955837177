import { Component, OnInit, Input } from '@angular/core';
import { infoUser } from 'src/models';

@Component({
  selector: 'app-notificacion-card',
  templateUrl: './notificacion-card.component.html',
  styleUrls: ['./notificacion-card.component.scss'],
})
export class NotificacionCardComponent implements OnInit {

  @Input() notificacion: any

  infoUser: infoUser = {
    userId: '',
    displayName: '',
    photoURL: '',
  }

  constructor() { }

  ngOnInit() {
    this.infoUser = this.notificacion.infoUser
  }

}
