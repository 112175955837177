import { UiKitController } from '../../../../services/uikitcontroller.service';
import { ModalController } from '@ionic/angular';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-form-msg',
  templateUrl: './form-msg.component.html',
  styleUrls: ['./form-msg.component.scss'],
})
export class FormMsgComponent implements OnInit {

  @Input() data_prod: any;
	forma:FormGroup;
	data = {
		monto: '',
		mensaje: ''
	}
	lg:any = localStorage.getItem('lg');
  constructor(
		private modalController: ModalController,
		private fb:FormBuilder,
		private uiKit: UiKitController,
		private util: UiKitController,
	) { 
		this.forma = this.fb.group({
			monto: ['', [Validators.required]],
			mensaje: ['', [Validators.required, Validators.maxLength(20), Validators.minLength(10)]]
		  });
	}

  ngOnInit() {
	console.log('msg-component',this.data_prod);
  }

	async modalrespuesta(){
		if(isNaN(this.forma.value.monto)){
			await this.uiKit.ToastWarning('You must enter only numbers')
			return
		}
		if(parseFloat(this.forma.value.monto.replace(/,/g,'')) <= 0){
			await this.uiKit.ToastWarning('The amount must be greater than 0')
			return
		}
		if(parseFloat(this.forma.value.monto.replace(/,/g,'')) < parseFloat(this.data_prod.um_producto.replace(/,/g,''))/ 2){
			await this.uiKit.ToastWarning('Amount must be more than half product price')
			return
		}
		await this.modalController.dismiss(this.forma.value);
	}

	async dismissModal() {
		await this.util.loading();
		this.modalController.dismiss(null);
		await this.util.closeLoading();
	  }
}
