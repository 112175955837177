import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController, ModalController } from '@ionic/angular';
import { AuthGuardServicesService } from 'src/app/services/auth-guard-services.service';
import { CarritoService } from 'src/app/services/carrito.service';
import { ProductosService } from 'src/app/services/productos.service';
import { skServices } from 'src/app/services/sk.services.service';
import { StoreService } from 'src/app/services/store.service';
import { UiKitController } from 'src/app/services/uikitcontroller.service';
import { ModalProductosComponent } from '../modal-productos/modal-productos.component';
import { FormProductoUsuarioComponent } from '../shared/modal/form-producto-usuario/form-producto-usuario.component';

@Component({
  selector: 'app-producto',
  templateUrl: './producto.component.html',
  styleUrls: ['./producto.component.scss'],
})
export class ProductoComponent implements OnInit {
  @Input() producto: any;

  constructor(
    private router: Router,
    public userControl: AuthGuardServicesService,
    public menucontroler: MenuController,
    public firebaseauthService: AuthGuardServicesService,
    public firestorangeService: StoreService,
    public modalController: ModalController,
    private util: UiKitController,
    public carritoService: CarritoService,
    public productosControl: ProductosService,
    private skservice: skServices,
  ) {}

  ngOnInit() {
    console.log(this.producto);
  }
  goTo(xvalor:any) {
    try {
      console.log(xvalor);
    if(!localStorage.getItem('userId')){
      return this.userControl.login()
    }
    this.router.navigate(['/producto',xvalor.cod_deposito]);
    } catch (error) {
      console.log(error);
    }
  }
  async modalUsuarioProdcutos(xvalor:any){
    try {
      await this.util.loading();
      const modal = await this.modalController.create({
        component: FormProductoUsuarioComponent,
        cssClass: 'form-usuario-producto',
        componentProps: { data:xvalor},
        backdropDismiss: false
      });
      await modal.present();
      const { data } = await modal.onWillDismiss();
      if (data !== undefined && data !== null && data !== '') {
        console.log(data);
        this.router.navigate(['proveedor',data.cod_usuario]);
      }
      return modal;
    } catch (error) {
      console.log(error);
        await this.util.ToastWarning(error.error.message);
    }
    
  }
}
