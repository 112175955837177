import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import { tap, map, switchMap, first } from 'rxjs/operators';
import { of } from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Location } from '@angular/common';
import { statusConection } from 'src/models';

@Injectable({
  providedIn: 'root',
})
export class StatusController {

  dataStatus: statusConection = { status:'online', timestamp:0 };
  location2: string;

  constructor(
    private afAuth: AngularFireAuth,
    public db: AngularFireDatabase,
    public database: AngularFirestore,
    private location: Location
  ) {
    this.updateOnUser().subscribe();
    this.updateOnDisconnect().subscribe();
    this.updateOnAway();
  }

  get timestamp() {
    return firebase.database.ServerValue.TIMESTAMP;
  }

  getStatusUser(uid: string) {
    return this.db.object(`status/${uid}`).valueChanges();
  }

  getPresence(uid: string) {
    return this.db.object(`status/${uid}`).valueChanges();
  }
  getUser() {
    return this.afAuth.authState.pipe(first()).toPromise();
  }

  async setPresence(status: string) {
    const user = await this.getUser();
    if (user) {
      this.dataStatus = { status, timestamp: new Date().getTime() }
      return this.db
        .object(`status/${user.uid}`)
        .update({ status, timestamp: this.timestamp });
    }
  }

  updateOnUser() {
    const connection = this.db
      .object('.info/connected')
      .valueChanges()
      .pipe(map((connected) => (connected ? 'online' : 'offline')));

    return this.afAuth.authState.pipe(
      switchMap((user) => (user ? connection : of('offline'))),
      tap((status) => this.setPresence(status))
    );
  }
  updateOnAway() {
    /* document.onvisibilitychange = (e) => {
      if (document.visibilityState === 'hidden') {
        this.setPresence('away');
      } else {
        this.setPresence('online');
      }
    }; */
    document.onvisibilitychange = (e) => {
      if (document.visibilityState === 'hidden') {
        this.setPresence('offline');
      } else {
        this.setPresence('online');
      }
    };
    this.location.onUrlChange(url => {
      if(url.includes('chat')) {
        this.location2 = url
        this.setPresence('online');
      } else {
        if(this.dataStatus.status === 'online'){
          this.setPresence('away');
        }
      }
    })//output popstate
  }
  updateOnDisconnect() {
    return this.afAuth.authState.pipe(
      tap((user) => {
        if (user) {
          this.db.object(`status/${user.uid}`).query.ref.onDisconnect().update({
            status: 'offline',
            timestamp: this.timestamp,
          });
        }
      })
    );
  }
  async signOut() {
    await this.setPresence('offline');
    await this.afAuth.signOut();
  }
}
