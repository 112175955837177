import { ProductosService } from 'src/app/services/productos.service';
import { Usuario } from 'src/models';
import { AuthGuardServicesService } from 'src/app/services/auth-guard-services.service';
import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { skServices } from 'src/app/services/sk.services.service';
import { UiKitController } from 'src/app/services/uikitcontroller.service';
import { Router } from '@angular/router';
import { MenuController, ModalController } from '@ionic/angular';
import { StoreService } from 'src/app/services/store.service';
import { CarritoService } from 'src/app/services/carrito.service';
import { FormProductoUsuarioComponent } from 'src/app/componentes/shared/modal/form-producto-usuario/form-producto-usuario.component';
import { FormNivelComponent } from 'src/app/componentes/shared/modal/form-nivel/form-nivel.component';
import { GetSetAtributosService } from 'src/app/services/get-set-atributos.service';
import { RouterService } from 'src/app/services/router.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-producto-tipo-usuario',
  templateUrl: './producto-tipo-usuario.component.html',
  styleUrls: ['./producto-tipo-usuario.component.scss'],
})
export class ProductoTipoUsuarioComponent implements OnInit {
  data: any;
  fecha:any = new Date().getFullYear();
  xfecha = this.fecha;
userData: any;
productos: any = [];
productos_filtro: any = [];
dep: any = [];
productos_mmbr: any = [];
pruebaBack: any;
category: any = localStorage.getItem('usuario_categoria');
tipo_usuario:any = localStorage.getItem('usuario_tipo');
currentPage = 1;
pageOfItems: Array<any>;

slides: any[] = new Array(3).fill({id: -1, src: '', title: '', subtitle: ''});
lg:any = localStorage.getItem('lg');
  constructor(
    private getSet: GetSetAtributosService,
    private userControl: AuthGuardServicesService,
		private productosService: ProductosService,
    private skservice: skServices,
    private util: UiKitController,
    private router: Router,
    private route_app: RouterService,
    public menucontroler: MenuController,
    public firebaseauthService: AuthGuardServicesService,
    public firestorangeService: StoreService,
    public modalController: ModalController,
    public carritoService: CarritoService,
    public productosControl: ProductosService,
    private afs: AngularFirestore,
    private firestoreService: StoreService
    ) { }

  ngOnInit() {
    this.data = this.getSet.parametro;
    if (this.data === undefined) {
      this.route_app.rutas_app('home');
    }
    console.log(this.data);
  }

  ionViewDidEnter() {
    this.data = this.getSet.parametro;
    if (this.data === undefined) {
      this.route_app.rutas_app('home');
    }
    console.log('ionViewDidEnter');
    if (localStorage.getItem('usuario_tipo').toLowerCase() === 'mmbr' ) {
      this.listarProductos();
    }
    if (localStorage.getItem('usuario_tipo').toLowerCase() === 'vstnt' ) {
      this.listarProductos();
    }
 
		if(localStorage.getItem('userId')){
      const tipo = localStorage.getItem('tipo');
    }else{
      /* await this.userControl.usuarioVisitante(); */
    }
		/* this.userData = this.userControl.Usuario; */
  }

  async listarProductos(){
    try {
      this.productos = [];
      await this.util.loading();
      let xvalor = {
        cod_usuario:localStorage.getItem('cod_usuario')
      }
     const response =  await this.skservice.getProdhttp(xvalor).toPromise();
     
     console.log('lista productos', response);

     response.producto.forEach(prod => {
      prod.cod_img = environment.path_prod + prod.cod_img;
     });
     
     for (const i in response.producto) {
        if (response.producto[i].um_producto_operador &&
           response.producto[i].cat_producto &&
           response.producto[i].tipo_producto_cat &&
            response.producto[i].estatus === 'A' &&
            response.producto[i].tipo_producto_cat === this.data.tipo_producto_cat) {
         this.productos.push(response.producto[i]);
        }
     }
    
     this.productos = [...this.productos];
     if (this.productos.length  <= 0) {
      
      await this.util.ToastWarning('At the moment there are no products available in this category');
      return;
    }
    for (const i in this.productos) {
          if (localStorage.getItem('usuario_categoria').toLowerCase() === 'platinum') {
            this.productos_filtro.push(this.productos[i]);
          }
          if (localStorage.getItem('usuario_categoria').toLowerCase() === 'gold') {
            if (this.productos[i].cat_producto.toLowerCase() === 'gold' ||
                this.productos[i].cat_producto.toLowerCase() === 'silver' ||
                this.productos[i].cat_producto.toLowerCase() === 'basic') {
                  this.productos_filtro.push(this.productos[i]);
            }
          }
          if (localStorage.getItem('usuario_categoria').toLowerCase() === 'silver') {
            if (this.productos[i].cat_producto.toLowerCase() === 'silver' ||
                this.productos[i].cat_producto.toLowerCase() === 'basic') {
                  this.productos_filtro.push(this.productos[i]);
            }
          }
          if (localStorage.getItem('usuario_categoria').toLowerCase() === 'basic') {
            if (this.productos[i].cat_producto.toLowerCase() === 'basic') {
                  this.productos_filtro.push(this.productos[i]);
            }
          }
          if (localStorage.getItem('usuario_categoria').toLowerCase() === 'vstnt') {
            if (this.productos[i].cat_producto.toLowerCase() === 'basic') {
                  this.productos_filtro.push(this.productos[i]);
            }
          }
          if (this.productos_filtro.length  <= 0) {
      
            await this.util.ToastWarning('There are no products available in your category');
            return;
          }

   }
     await this.util.ToastSuccess(response.message);
    } catch (error) {
      this.data = [...this.productos];
      console.log(error);
      await this.util.ToastWarning(error.error.message);
    }
  }

 async handleChange(event) {
    await this.util.loading();
    const query = event.target.value.toLowerCase();
    this.data = this.productos.filter(d => 
      d.tipo_producto_cat.toLowerCase().indexOf(query) > -1 || 
      d.cat_producto.toLowerCase().indexOf(query) > -1  ||
      d.um_producto_operador.toLowerCase().indexOf(query) > -1  ||
      d.des_producto.toLowerCase().indexOf(query) > -1);
    
    console.log(this.data.length);
    if (this.data.length <= 0) {
      await this.util.ToastWarning('Without results');
    }else{
      await this.util.ToastSuccess(this.data.length+' Results Found');
    }
  }

  async AgregarAlCarrito(xval:any) {
    /* this.carritoService.AgregarProducto(this.producto); */
    try {
      await this.util.loading();
      console.log(xval);
          var xvalor = {
            cant_producto: '1',
            cod_producto: xval.cod_producto,
            des_producto: xval.des_producto,
            precio_producto: xval.um_producto_operador,
            um_producto: xval.um_producto,
            cod_deposito: xval.cod_deposito,
            cod_img: xval.cod_img,
            cod_cliente: localStorage.getItem('cod_usuario'),
            cod_vendedor: xval.cod_prdr,
            cat_producto: xval.cat_producto,
            tipo: xval.tipo_producto_cat,
            estatus: xval.estatus,
            total_precio_pedido:  parseFloat(xval.um_producto_operador),
            total_precio_producto: parseFloat(xval.um_producto_operador),
          }
          
          const response =  await this.skservice.getPedidoCarritohttp(xvalor).toPromise();
          console.log(response);
          this.afs
                  .collection('pedidos_usuarios')
                  .doc(localStorage.getItem('cod_usuario'))
                  .collection('pedido')
                  .doc(response.pedido[0].cod_pedido_carrito)
                  .set({
                    estatus:'A',
                    fecha: firebase.firestore.FieldValue.serverTimestamp(),
                    
                  });
        for (const i in response.pedido) {
          this.afs
                  .collection('pedidos_usuarios')
                  .doc(localStorage.getItem('cod_usuario'))
                  .collection('pedido')
                  .doc(response.pedido[i].cod_pedido_carrito)
                  .collection('productos')
                  .doc(response.pedido[i].cod_producto)
                  .set({
                    des_producto: response.pedido[i].des_producto,
                    fecha: firebase.firestore.FieldValue.serverTimestamp(),
                    
                  });
        }
          
          await this.util.ToastSuccess(response.message);
          this.listarProductos();
    } catch (error) {
      
      console.log(error);
      this.util.ToastWarning(error.error.message);
    }
  }
  async getPerfilSeller(xvalor:any) {
    try {
      await this.util.loading();
        this.router.navigate(['/proveedor',xvalor.cod_deposito]);
    } catch (error) {
      console.log(error);
      this.util.ToastError(error.error.message);
    }
    }
}
