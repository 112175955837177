import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ModalController } from '@ionic/angular';
import { Productos } from 'src/models';
import { ModalProductosComponent } from '../componentes/modal-productos/modal-productos.component';

@Injectable({
  providedIn: 'root',
})
export class ProductosService {
  Productos: Productos[] = [];

  constructor(
    private database: AngularFirestore,
    private modalController: ModalController,
    private afs: AngularFirestore
  ) {
    this.Productos = [
      {
        src: '../../assets/img/products/electronic/1.jpg',
        alt: 'Image 1',
        Id: 'adsfds4564asdf531212',
        user_id: 'sfdgafdsgag1564621',
        user_name: 'Pablo Jose',
        title: 'Global Office',
        cant_producto: 5,
        price: '15.00',
        descripcion:
          'sdhabgdhjagdjhkghskdgjkhdgjhsdgajhkgdfasdfasdfasdfasdfasdfasfasdfadfsdhjasgdjhagsdjh',
      },
      {
        src: '../../assets/img/products/electronic/2.jpg',
        alt: 'Image 1',
        Id: '45325345',
        user_id: '2a1sd321ad',
        user_name: 'Pablo Jose',
        title: 'Global Office',
        cant_producto: 5,
        price: '15.00',
        descripcion:
          'sdhabgdhjagdjhkghskdgjkhdgjhsdgajhkgdfasdfasdfasdfasdfasdfasfasdfadfsdhjasgdjhagsdjh',
      },
      {
        src: '../../assets/img/products/electronic/3.jpg',
        alt: 'Image 1',
        Id: 'dafsadfasdfsfd',
        user_id: 'sfdgafdsgag1564621',
        user_name: 'Pablo Jose',
        title: 'Global Office',
        cant_producto: 5,
        price: '15.00',
        descripcion:
          'sdhabgdhjagdjhkghskdgjkhdgjhsdgajhkgdfasdfasdfasdfasdfasdfasfasdfadfsdhjasgdjhagsdjh',
      },
      {
        src: '../../assets/img/products/electronic/4.jpg',
        alt: 'Image 1',
        Id: '5se57eyestrye',
        user_id: '2a1sd321ad',
        user_name: 'Pablo Jose',
        title: 'Global Office',
        cant_producto: 5,
        price: '15.00',
        descripcion:
          'sdhabgdhjagdjhkghskdgjkhdgjhsdgajhkgdfasdfasdfasdfasdfasdfasfasdfadfsdhjasgdjhagsdjh',
      },
      {
        src: '../../assets/img/products/electronic/5.jpg',
        alt: 'Image 1',
        Id: 's5eydshsdfydry',
        user_id: 'sfdgafdsgag1564621',
        user_name: 'Pablo Jose',
        title: 'Global Office',
        cant_producto: 5,
        price: '15.00',
        descripcion:
          'sdhabgdhjagdjhkghskdgjkhdgjhsdgajhkgdfasdfasdfasdfasdfasdfasfasdfadfsdhjasgdjhagsdjh',
      },
      {
        src: '../../assets/img/products/electronic/6.jpg',
        alt: 'Image 1',
        Id: 's6ufghusdr67ds',
        user_id: '2a1sd321ad',
        user_name: 'Pablo Jose',
        title: 'Global Office',
        cant_producto: 5,
        price: '15.00',
        descripcion:
          'sdhabgdhjagdjhkghskdgjkhdgjhsdgajhkgdfasdfasdfasdfasdfasdfasfasdfadfsdhjasgdjhagsdjh',
      },
    ];
  }

  async modalProducto(producto?: Productos) {
    const modal = await this.modalController.create({
      component: ModalProductosComponent,
      cssClass: 'my-custom-class',
      componentProps: {
        producto: producto || false,
      },
    });

    await modal.present();
  }
  getProductosStatic() {
    return new Promise<Productos[]>((resolve) => {
      resolve(this.Productos);
    });
  }

  getProductos() {
    return new Promise<Productos[]>((resolve) => {
      resolve(
        this.afs
          .collection('Productos')
          .get()
          .toPromise()
          .then(
            (res) =>
              res.docs.map((ele) => ele.data() as Productos) as Productos[]
          )
      );
    });
  }

  async buscarProducto(id: string) {
    return new Promise<Productos>((resolve) => {
      resolve(
        this.afs
          .collection('Productos')
          .doc(id)
          .get()
          .toPromise()
          .then((res) => res.data() as Productos)
      );
    });
  }
  getProductosFirebase() {
    return this.database.collection('/Productos').valueChanges();
  }

  CrearProductos(data: any, path: any, Id: string) {
    const collection = this.database.collection(path);
    return collection.doc(Id).set(data);
  }

  EliminarProductos(path: any, id: string) {
    const collection = this.database.collection(path);
    return collection.doc(id).delete();
  }

  ActualizarProductos(data: any, path: any, id: string) {
    const collection = this.database.collection(path);
    return collection.doc(id).update(data);
  }

  ConsultarProducto(path: any, id: string) {
    const collection = this.database.collection(path);
    return collection.doc(id).valueChanges();
  }
  filtrarProductosByIDUsuario(id: string) {
    return new Promise<Productos[]>((resolve) => {
      resolve(
        this.afs
          .collection('Productos', (ref) => ref.where('user_id', '==', id))
          .get()
          .toPromise()
          .then(
            (res) =>
              res.docs.map((ele) => ele.data() as Productos) as Productos[]
          )
      );
    });
  }
}
