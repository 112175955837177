import { Component, OnInit, Inject, Input } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { LoadingController, ModalController } from '@ionic/angular';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { Auth, createUserWithEmailAndPassword, getAuth, updateProfile } from 'firebase/auth';
import { skServices } from 'src/app/services/sk.services.service';
import { UiKitController } from 'src/app/services/uikitcontroller.service';
import { FormMsgComponent } from '../form-msg/form-msg.component';
import { FormRegistroProveedorComponent } from '../form-registro-proveedor/form-registro-proveedor.component';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';

@Component({
  selector: 'app-form-login-proveedor',
  templateUrl: './form-login-proveedor.component.html',
  styleUrls: ['./form-login-proveedor.component.scss'],
})
export class FormLoginProveedorComponent implements OnInit {

  @Input() data: string
  emailRegex:any = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  telRegex:any = /\d{4}-\d{6}\d/;
  Regex:any = /^[J,V]{1}\d{9}/;
  hide:any = true;
nombre:any;
forma:FormGroup;
dialogSpin:any;
accion:any;
unidad:any;
lg:any = localStorage.getItem('lg');
listas:any = [];
tipo_cliente = [];
deposito_pt = [];
almacen:any = [];
almacen_cdn:any = [];
almacen_dstrbdr:any = [];
almacen_pvp:any = [];
tipo_um_und = [];
tipo_um_und_consig = [];
asociar:any = 'N/A';
actualizar:any = false;

  constructor(
    public fb:FormBuilder,
    private skservice: skServices,
    private loadindControl: LoadingController,
    private util: UiKitController,
    public modalController: ModalController,
    public auth: AngularFireAuth,
    ) {
      
      this.forma = this.fb.group({
        correo: ['', [Validators.required, Validators.email]],
        pass: ['', [Validators.required]],
        tipo: 'DLR',
      });
    }

  ngOnInit(): void {
    console.log(this.data);
  }

 async modalrespuesta(){
    try {
    await this.util.loading();
    this.modalController.dismiss(this.forma.value);
    this.forma.reset();
    } catch (error) {
      console.log(error);
    }
  }
  async registro_miembros(xvalor: any) {
    try {
      const auth: Auth = getAuth();
    await this.util.loading();
    const modal = await this.modalController.create({
      component: FormRegistroProveedorComponent,
      cssClass: 'my-custom-class',
      componentProps: { data:xvalor},
      backdropDismiss: false
    });
    await modal.present();
    const { data } = await modal.onWillDismiss()
    if (data !== undefined && data !== null && data !== '') {
     const data_cloud =  await createUserWithEmailAndPassword(auth,data.correo, data.pass);
     console.log(data_cloud.user.uid);
     data.id_cloud = data_cloud.user.uid;
      const response = await this.skservice.crearusuariohttp(data).toPromise();
      await updateProfile(auth.currentUser, { displayName: data.nom_usuario });
      //await (await this.auth.currentUser).sendEmailVerification();
      await this.util.ToastSuccess(response.message);
    }
    return modal;
    } catch (error) {
      console.log(error);
      await this.util.ToastError(error.error.message);
    }
  }
  async dismissModal() {
    await this.util.loading();
    this.modalController.dismiss();
  }

}
