import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuController, ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { AuthGuardServicesService } from 'src/app/services/auth-guard-services.service';
import { PdfService } from 'src/app/services/pdf.service';
import { skServices } from 'src/app/services/sk.services.service';
import { StoreService } from 'src/app/services/store.service';
import { UiKitController } from 'src/app/services/uikitcontroller.service';
import { FormProductoPrecioComponent } from '../shared/modal/form-producto-precio/form-producto-precio.component';


@Component({
  selector: 'app-vendedor-lista',
  templateUrl: './vendedor-lista.component.html',
  styleUrls: ['./vendedor-lista.component.scss'],
})
export class VendedorListaComponent implements OnInit {

  PedidoEnviado: Subscription;
  PedidoEntrgado: Subscription;
  pedido: any= [];
  Pedidos2: any[] = [];
  id: any;
  fecha:any = new Date().getFullYear();
  xfecha = this.fecha;
  category: any = localStorage.getItem('usuario_categoria');

 
  currentPage = 1;
  pageOfItems: Array<any>;
  constructor(
    private menucontroler: MenuController,
    private firestoreService: StoreService,
    private firebaseauthService: AuthGuardServicesService,
    private route: ActivatedRoute,
    private util: UiKitController,
    public modalController: ModalController,
    private skservice: skServices,
    private router: Router,
    private pdf: PdfService,
  ) {  }

  ngOnInit() {
   
  }
  ionViewDidEnter() {
    this.listarPedidosClientes(null);
   }
   onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

async listarPedidosClientes(xval:any){
  try {
    this.pedido = [];
    await this.util.loading();
    var xvalor = {
      cod_usuario:localStorage.getItem('cod_usuario')
    }
    var bandera = false;
    const response = await this.skservice.getPedidoClienteOperadorhttp(xvalor).toPromise();
    console.log(response);
    for (const i in response.pedido) {
      if (response.pedido[i].estatus.toUpperCase() === 'P') {
        bandera = true;
        break;
      }
    }
    this.pedido = [...response.pedido];
    if (bandera) {
      await this.util.ToastSuccess('You have an pending product');
    }
    if (xval !== null) {
      await this.util.ToastSuccess('Update successfull');
    }
    
  } catch (error) {
    
    console.log(error);
   await this.util.ToastWarning(error.error.message);
  }
}

  /* async modalPedidosCarrito(xvalor:any){
		try {
		  await this.util.loading();
		  const modal = await this.modalController.create({
			component: FormPedidosComponent,
			cssClass: 'form-pedidos',
			componentProps: { data:xvalor},
			backdropDismiss: false
		  });
		  
		  await modal.present();
		  const { data } = await modal.onWillDismiss();
		  this.listarPedidosClientes();
		  return modal;
		} catch (error) {
		  console.log(error);
			await this.util.ToastWarning(error.error.message);
		}
		
	  } */
    async tienda_app(url: any) {
      try {
        await this.util.loading();
        this.router.navigate([url]);
        
      } catch (error) {
        console.log(error);
      }
    }
    async checkout(xvalor: any) {
      try {
        console.log(xvalor);
        xvalor.cod_prdr = localStorage.getItem('cod_usuario');
        await this.util.loading();
        const response = await this.skservice.getCheckoutProductohttp(xvalor).toPromise();
        await this.util.ToastSuccess(response.message);
        
        this.listarPedidosClientes(null);

      } catch (error) {
        console.log(error);
      }
    }
    async checkout_all() {
      try {
        await this.util.ToastWarning('Function in development');
      } catch (error) {
        console.log(error);
      }
    }

}
