import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AlertController } from '@ionic/angular';
import { Productos } from 'src/models';
import { AuthGuardServicesService } from './auth-guard-services.service';
import { BehaviorSubject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  path = '/Productos'
  private Productos = new BehaviorSubject<any[]>([]);
  private Productos$ = this.Productos.asObservable();
  private misProductos: Productos[] = []
  private productoSusbcribe: Subscription;

  constructor(
    public database: AngularFirestore,
    private alertController: AlertController,
    private authController: AuthGuardServicesService
  ) {
  }

  getProductosUsuario() {
    return this.Productos$;
  }

  async getProductosUsuarioFirebase(id: string) {
    this.productoSusbcribe = this.database.collection(this.path,ref => ref.where('user_id', '==', id)).valueChanges().subscribe(
      res  => {
        this.misProductos = res as Productos[]
        this.Productos.next(res)
      }
    )
    return this.productoSusbcribe
   /*  const docSnap = (await this.authController.db.collection(this.path).where('user_id', '==', id).get()).docs.map(
      ele => ele.data()
    ) as Productos[]
    this.misProductos = docSnap
    this.Productos.next(docSnap) */
  }
  Unsubscribe(){
    this.productoSusbcribe.unsubscribe()
  }
  ActualizarProductosUsuario(data: Productos) {
    const elProd = this.misProductos.map<Productos>((elem: Productos): Productos =>{
      if(elem.Id === data.Id){
        elem = data
      }
      return elem
    })
    this.misProductos = elProd
    this.Productos.next(elProd)
    this.database.collection(this.path).doc(data.Id).update(data);
    return
  }

  ConsultarProductoUsuario(id: string) {
    return this.database.collection(this.path).doc(id).valueChanges();
  }

  CrearProductosUsuario(data: Productos) {
    this.misProductos.unshift(data)
    this.Productos.next(this.misProductos)
    this.database.collection('Productos').doc(data.Id).set(data);
    return;
  }

  async EliminarProductosUsuario(data: Productos) {
    const alert = await this.alertController.create({
      header: 'Seguro que quieres eliminar ese producto?',
      mode: 'ios',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => null,
        },
        {
          text: 'OK',
          role: 'confirm',
          handler: async () => {
             await this.EliminarProducto(data)
            const filtrado = this.misProductos.filter(elem => elem.Id !== data.Id);
            this.misProductos = filtrado
            this.Productos.next(filtrado)
          },
        },
      ],
    });

    await alert.present();

    return this.Productos;
  }

  async EliminarProducto(data: Productos) {
    await this.authController.db.collection(this.path).doc(data.Id).delete();
    return;
  }

}
