import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UiKitController } from 'src/app/services/uikitcontroller.service';
import { AlertController, LoadingController, MenuController, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-buyer-profile',
  templateUrl: './buyer-profile.component.html',
  styleUrls: ['./buyer-profile.component.scss'],
})
export class BuyerProfileComponent implements OnInit {
  @Input() data: any;
  lg:any = localStorage.getItem('lg');

  constructor(
    public modalController: ModalController,
    private util: UiKitController,
    public router: Router,
  ) { }

  ngOnInit() {
    console.log ('Data = ', this.data);
  }

  async dismissModal() {
    await this.util.cargando();
      this.modalController.dismiss();
    await this.util.closeLoading();
  }

}
