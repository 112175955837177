import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LoadingController, ModalController, ToastController } from '@ionic/angular';
import { AuthGuardServicesService } from 'src/app/services/auth-guard-services.service';
import { skServices } from 'src/app/services/sk.services.service';
import { StoreService } from 'src/app/services/store.service';
import { UiKitController } from 'src/app/services/uikitcontroller.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import Swiper from "swiper/types/swiper-class";
import SwiperCore, {
  Autoplay,
  SwiperOptions,
  EffectFade,
  Scrollbar,
  Navigation,
  Pagination,
  A11y,
  Virtual,
  Zoom,
  Thumbs,
  Controller
} from 'swiper';
SwiperCore.use([
  Autoplay, 
  EffectFade, 
  Scrollbar,
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Thumbs,
  Controller
]);

@Component({
  selector: 'app-form-video-muestra',
  templateUrl: './form-video-muestra.component.html',
  styleUrls: ['./form-video-muestra.component.scss'],
})
export class FormVideoMuestraComponent implements OnInit {
  @Input() data: any;
  video:any;
  rows:any = [];
  img_prod: any;
  nombre_img: any;
  img_count:any = [];
  vid:any = [];
  nombre_video:any;
  config: SwiperOptions = {
    keyboard: true,
    slidesPerView: 1,
    pagination:{ clickable: true },
    navigation:true,
    spaceBetween: 50,
    scrollbar: { draggable: true },
  };
  forma: FormGroup;
  lg:any = localStorage.getItem('lg');
  constructor(
    public modalController: ModalController,
    public userProductControl: UserService,
    public firestoreService: StoreService,
    public loadingController: LoadingController,
    public toastController: ToastController,
    public fb: FormBuilder,
    public userControl: AuthGuardServicesService,
    private loadindControl: LoadingController,
    private util: UiKitController,
    private store: StoreService,
    private skservice: skServices,
  ) {
    this.forma = this.fb.group({
      img: [''],
      alt:''
    })
   }
  ngOnInit() 
  {
    this.getVidGaleria(this.data);
  }

  async getVidGaleria(xvalor:any){
    try {
      this.util.loading();
      console.log(xvalor);
      this.vid = [];
      var data = {
        cod_usuario:localStorage.getItem('cod_usuario'),
        cod_producto:xvalor.cod_producto,
      };
     const response =  await this.skservice.getVidGaleriahttp(data).toPromise();
    console.log(response);
     this.vid = [...response.vid];
    } catch (error) {
      /* await this.util.ToastWarning(error.error.message); */
      console.log(error);
    }
  }

  async getVidprod(objeto:any) {
    console.log ('aqui es la cosa');
    try {
      await this.util.loading();
      this.nombre_img = objeto.target.files[0];
      this.nombre_video = this.nombre_img.name;
      this.img_prod = objeto.target.files[0];
      this.img_count.push(this.img_prod);
      
    } catch (error) {
      
      console.log(error);
      await this.util.ToastWarning(error.error.message);
    }
  }

  async vidProdGaleria( ) {
    try {
      await this.util.loading();
      if (this.vid.length === 2) {
        await this.util.ToastWarning('You can only upload 2 video for now');
        return;
      }
      
      if (this.img_prod !== '' && this.nombre_img.name.length <= parseInt(environment.nombre_img) &&  this.nombre_img.name.length >= 1 ) {
        console.log(this.img_prod );
                const response =  await this.store.CargarVideoPerfil(this.img_prod);
                console.log(response);
                var data = {
                  cod_usuario: localStorage.getItem('cod_usuario'),
                  cod_producto: this.data.cod_producto,
                  cod_deposito: this.data.cod_deposito,
                  cod_vid: response,
                }
                console.log(data);
                const response_ = await this.skservice.getImgProductoVidGaleriahttp(data).toPromise();
                await this.util.ToastSuccess(response_.message);
              this.getVidGaleria(data);
      
      }else{
        
        if (this.nombre_img === '') {
          await this.util.ToastWarning('You must upload an Video to continue');
        }
        if (this.nombre_img.name.length > parseInt(environment.nombre_img)) {
          await this.util.ToastWarning('Video name must be less than '+parseInt(environment.nombre_img)+' characters');
        }
        if (this.nombre_img.name.length < 1) {
          
          await this.util.ToastWarning('You can not upload unnamed video');
        }
        
      }
      
    } catch (error) {
      console.log(error);
      await this.util.ToastWarning(error.error.message);
    }
  }
  async quitarVidProdGaleria( xvalor:any) {
    try {
      await this.util.loading();
        console.log(xvalor );
                const response =  await this.store.QuitarVideoPerfil(xvalor.cod_vid);
                console.log(response);
                var data = {
                  cod_vid: xvalor.cod_vid
                }
                const response_ = await this.skservice.getQuitarGaleriaVidhttp(data).toPromise();
                console.log(response_);
                await this.util.ToastSuccess(response_.message[0].message);
              this.getVidGaleria(this.data);
    } catch (error) {
      console.log(error);
      await this.util.ToastWarning(error.error.message);
    }
  }
  async dismissModal() {
    this.modalController.dismiss();
  }

}
