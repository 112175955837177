import { UiKitController } from '../../../../services/uikitcontroller.service';
import { ModalController } from '@ionic/angular';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-form-operacion',
  templateUrl: './form-operacion.component.html',
  styleUrls: ['./form-operacion.component.scss'],
})
export class FormOperacionComponent implements OnInit {

  @Input() data: any;
	forma:FormGroup;
	forma2:FormGroup;
	aprobar:any = false;
	rechazar:any = false;
  constructor(
		private modalController: ModalController,
		private fb:FormBuilder,
		private uiKit: UiKitController,
		private util: UiKitController,
	) { 
		this.forma = this.fb.group({
			monto: ['', [Validators.required]],
			cat_producto: ['',[Validators.required]],
			tipo: ['',[Validators.required]],
			mensaje: ['', [Validators.required, Validators.maxLength(20)]]
		  });
		this.forma2 = this.fb.group({
			mensaje: ['', [Validators.required, Validators.maxLength(100), Validators.minLength(10)]]
		  });
	}

  ngOnInit() {
	console.log(this.data);
	if (this.data.um_producto_operador) {
		this.aprobar = true;
	}
	this.forma.patchValue({
			monto : this.data.um_producto_operador,
			cat_producto : this.data.cat_producto,
			tipo : this.data.tipo_producto_cat,
	});
	
  }

	async modalrespuesta(){
		let inputprod1= document.querySelector("#inputprod1") as HTMLSelectElement;
		let selectprod1= document.querySelector("#selectprod1") as HTMLSelectElement;
		let selectprod2= document.querySelector("#selectprod2") as HTMLSelectElement;
		if (!this.data.cat_producto) {
			if(this.forma.value.cat_producto === ''){
				await this.uiKit.ToastWarning('You must select a category')
				return
			}
		}else{
			this.forma.value.cat_producto = this.data.cat_producto;
		}
		
		if(isNaN(this.forma.value.monto)){
			await this.uiKit.ToastWarning('You must enter only numbers')
			return
		}
		if(parseFloat(this.forma.value.monto.replace(/,/g,'')) <= 0){
			await this.uiKit.ToastWarning('The amount must be greater than 0')
			return
		}
		if(parseFloat(this.forma.value.monto.replace(/,/g,'')) < parseFloat(this.data.um_producto.replace(/,/g,''))){
			await this.uiKit.ToastWarning('Amount must be more than product price')
			return
		}
    await this.util.loading();
	this.forma.patchValue({
		monto : inputprod1.value,
		cat_producto : selectprod1.value,
		tipo : selectprod2.value,
});
	console.log(this.forma.value);
		await this.modalController.dismiss(this.forma.value);
	}
	async getRechazarProductoModerador(){
		try {
			await this.util.cargando();
			var xvalor = {
				operacion:'rechazar',
				cod_deposito:this.data.cod_deposito,
				cod_producto:this.data.cod_producto,
				des_producto:this.data.des_producto,
				cod_img:this.data.cod_img,
				estatus_actual:this.data.estatus,
				comentarios: this.forma2.value.mensaje
			};
				await this.modalController.dismiss(xvalor);
			await this.util.closeLoading();
		} catch (error) {
			console.log(error);
		}
	}

	async dismissModal() {
		await this.util.cargando();
			this.modalController.dismiss(null);
		await this.util.closeLoading();
	  }
	async aprobarProducto(xvalor:any) {
		try {
			await this.util.cargando();
			console.log(xvalor);
			if (xvalor.toLowerCase() === 'aprobado') {
				this.aprobar = true;
				await this.util.closeLoading();
			}
			if (xvalor.toLowerCase() === 'rechazado') {
				this.rechazar = true;
				await this.util.closeLoading();
			}
		} catch (error) {
			console.log(error);
		}
	  }
}
