import { Component, OnInit } from '@angular/core';
import { CarritoService } from 'src/app/services/carrito.service';
import { Subscription } from 'rxjs'
import { Router } from '@angular/router';

@Component({
  selector: 'app-carrito',
  templateUrl: './carrito.component.html',
  styleUrls: ['./carrito.component.scss'],
})
export class CarritoComponent implements OnInit {
  handler: any;
  listarPedido: any;
  carritoSuscriber: Subscription;
  Uid = '';
  totalPagar: any;
  Total: any = 0;
  Cantidad: number;
  Direccion: any;
  prueba: any;
  cliente: any = {
    Nombre: '',
    Direccion: null,
    Celular: '',
    Uid: '',
    ubicacion: null,
    foto: '',
    token: '',
  };
  isOpen = false

  constructor(
    public carritoService: CarritoService,
    private router: Router,
  ) {
    this.InicializarCarrito();
    this.CargarPedido();
    this.carritoService.isOpen.subscribe(res => {
      this.isOpen = res
    })
  }

  ngOnInit() {}

  isAnonimo(){
    const tipo = localStorage.getItem('tipo')
    return tipo !== 'Anonimo'
  }

  InicializarCarrito() {
    this.listarPedido = {
      Id: '',
      Cliente: null,
      Productos: [],
      PrecioTotal: null,
      Direccion: null,
      Telefono: null,
      Estado: 'Enviado',
      fecha: new Date(),
      Valoracion: null,
    };
  }
  
  CargarPedido() {
    this.carritoSuscriber = this.carritoService
      .ListarCarrito()
      .subscribe((res) => {
        this.listarPedido = res;
        this.getTotal()
        this.getCantidad()
      });
  }
  getTotal() {
    this.Total = 0;
    this.listarPedido.Productos.forEach((result) => {;
      this.Total = Number(result.Producto.price) * result.Cantidad + Number(this.Total);
    });

    this.Total = parseFloat(this.Total).toFixed(2);
  }

  getCantidad() {
    this.Cantidad = 0;
    this.listarPedido.Productos.forEach((result) => {
      this.Cantidad = result.Cantidad + this.Cantidad;
    });
  }
  closeMenu(){
    this.carritoService.isOpen.next(false)
  }
  checkout(){
    this.router.navigate(['/carrito'])
    /* if(this.isAnonimo()){
      this.router.navigate(['/carrito'])
    }else{
      this.router.navigate(['/subscription'])
    } */
  }

}
