import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UiKitController } from 'src/app/services/uikitcontroller.service';

@Component({
  selector: 'app-form-cookies',
  templateUrl: './form-cookies.component.html',
  styleUrls: ['./form-cookies.component.scss'],
})
export class FormCookiesComponent implements OnInit {
  lg:any = localStorage.getItem('lg');
  constructor(
    private util: UiKitController,
    public modalController: ModalController,
  ) { }

  ngOnInit() {}
  async dismissModal() {
    await this.util.cargando();
    this.modalController.dismiss();
    await this.util.closeLoading(); 
  }
  async aceptar_ck() {
    await this.util.cargando();
    this.modalController.dismiss('1');
    await this.util.closeLoading(); 
  }
}
